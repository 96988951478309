import useAppSetting from "contexts/AppSettingsContext";
import { useMemo } from "react";

export type RouteScoreOptions = ReturnType<typeof useRouteScoreOptions>;
export const useRouteScoreOptions = () => {
  const { value: favorableWaveHeightMax } = useAppSetting(
    "favorableWaveHeightMax"
  );
  const { value: favorableWindSpeedMax } = useAppSetting(
    "favorableWindSpeedMax"
  );
  const { value: adverseWaveHeightMin } = useAppSetting("adverseWaveHeightMin");
  const { value: adverseWaveHeightMax } = useAppSetting("adverseWaveHeightMax");
  const { value: adverseWindSpeedMin } = useAppSetting("adverseWindSpeedMin");
  const { value: adverseWindSpeedMax } = useAppSetting("adverseWindSpeedMax");
  const { value: favorableCurrentMin } = useAppSetting("favorableCurrentMin");
  const { value: favorableCurrentMax } = useAppSetting("favorableCurrentMax");
  const { value: adverseCurrentMin } = useAppSetting("adverseCurrentMin");
  const { value: adverseCurrentMax } = useAppSetting("adverseCurrentMax");
  const { value: useAbsoluteLinearCurrentScores } = useAppSetting(
    "useAbsoluteLinearCurrentScores"
  );
  const { value: absoluteCurrentScoreThreshold } = useAppSetting(
    "absoluteCurrentScoreThreshold"
  );
  const { value: minRatioForReportingLargerRouteScore } = useAppSetting(
    "minRatioForReportingLargerRouteScore"
  );
  const { value: maxRatioForReportingSmallerRouteScore } = useAppSetting(
    "maxRatioForReportingSmallerRouteScore"
  );
  const { value: trivialComparisonBasisThreshold } = useAppSetting(
    "trivialComparisonBasisThreshold"
  );
  const { value: shorterDistanceReportingMinimumNM } = useAppSetting(
    "shorterDistanceReportingMinimumNM"
  );
  const { value: earlierArrivalReportingMinimumHr } = useAppSetting(
    "earlierArrivalReportingMinimumHr"
  );
  const { value: lowerConsumptionReportingMinimumMT } = useAppSetting(
    "lowerConsumptionReportingMinimumMT"
  );

  return useMemo(
    () => ({
      favorableWaveHeightMax,
      favorableWindSpeedMax,
      adverseWaveHeightMin,
      adverseWaveHeightMax,
      adverseWindSpeedMin,
      adverseWindSpeedMax,
      favorableCurrentMin,
      favorableCurrentMax,
      adverseCurrentMin,
      adverseCurrentMax,
      useAbsoluteLinearCurrentScores,
      absoluteCurrentScoreThreshold,
      minRatioForReportingLargerRouteScore,
      maxRatioForReportingSmallerRouteScore,
      trivialComparisonBasisThreshold,
      shorterDistanceReportingMinimumNM,
      earlierArrivalReportingMinimumHr,
      lowerConsumptionReportingMinimumMT,
    }),
    [
      favorableWaveHeightMax,
      favorableWindSpeedMax,
      adverseWaveHeightMin,
      adverseWaveHeightMax,
      adverseWindSpeedMin,
      adverseWindSpeedMax,
      favorableCurrentMin,
      favorableCurrentMax,
      adverseCurrentMin,
      adverseCurrentMax,
      useAbsoluteLinearCurrentScores,
      absoluteCurrentScoreThreshold,
      minRatioForReportingLargerRouteScore,
      maxRatioForReportingSmallerRouteScore,
      trivialComparisonBasisThreshold,
      shorterDistanceReportingMinimumNM,
      earlierArrivalReportingMinimumHr,
      lowerConsumptionReportingMinimumMT,
    ]
  );
};
