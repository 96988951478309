import parser, { X2jOptionsOptional } from "fast-xml-parser";
import { Route, Waypoint, ScheduleElement } from "shared-types/RouteTypes";
import { v4 as uuid } from "uuid";
import { normalizePointLongitude } from "helpers/geometry";
import distance from "@turf/distance";

export const kmlStringToRtzRoute = (kmlString: string): Route => {
  const options: X2jOptionsOptional = {
    ignoreAttributes: false,
    attributeNamePrefix: "",
    parseAttributeValue: true,
    // XML tags can have namespaces like this: <kml:name>AEFJR_INGPR.kml</kml:name>
    // Here the `kml:` is specifying the namespace. We remove that namespace so our
    // tag names are normalized, making interpreting and parsing the file easier.
    ignoreNameSpace: true,
  };
  const jsonRoute = parser.parse(kmlString, options);
  let marks = jsonRoute.kml?.Document?.Placemark || [];
  if (!marks) {
    throw Error("Could not decode KML route file.");
  }

  // irritatingly, fast-kml-parser unwraps single-element lists
  if (!Array.isArray(marks)) {
    marks = [marks];
  }

  const lineStrings = marks.filter((mark: any) => mark.LineString);

  if (lineStrings.length === 0) {
    throw Error("No routes found in KML file.");
  }

  if (lineStrings.length > 1) {
    // only Sofar users do this, so no Sentry error
    console.error("Warning: only using the first linestring in the file");
  }

  const coords = lineStrings[0].LineString.coordinates
    .trim()
    .split(/\s+/)
    .map((coordString: string) => {
      const floatCoords = coordString.split(",").slice(0, 2).map(parseFloat);
      return [
        // ensure we're in the [-180,180] range
        floatCoords[0] > 180 ? floatCoords[0] - 360 : floatCoords[0],
        floatCoords[1],
      ];
    });

  let index = 0;
  const waypoints: Waypoint[] = coords.map((coord: Array<Number>) => {
    const position = normalizePointLongitude({
      lon: Number(coord[0]),
      lat: Number(coord[1]),
    });

    const result: Waypoint = {
      id: index++,
      name: `Waypoint ${index}`,
      position,
      leg: {
        // this type is somewhat arbitrary
        geometryType: "Orthodrome",
      },
    };

    return result;
  });

  let currentTime: Date | undefined = undefined;
  let prevWaypoint: Waypoint | undefined = undefined;
  // arbitrary dummy speeds to make fake etas/etds
  const speedKts: number = 10;
  const speedMps: number = speedKts / 1.944;
  const scheduleElements: ScheduleElement[] = waypoints.map(
    (waypoint: Waypoint) => {
      if (!currentTime) {
        currentTime = new Date();
      } else {
        const distanceTraveled: number = distance(
          [prevWaypoint?.position.lon || 0, prevWaypoint?.position.lat || 0],
          [waypoint.position.lon, waypoint.position.lat],
          { units: "meters" }
        );
        const timeTakenMs = (distanceTraveled / speedMps) * 1000;
        currentTime = new Date(currentTime.getTime() + timeTakenMs);
      }

      let etaEtdSpeed = {};
      if (!prevWaypoint) {
        // this is the first one, so needs an etd
        etaEtdSpeed = { etd: currentTime.toISOString() };
      } else {
        // all others need an eta
        etaEtdSpeed = { eta: currentTime.toISOString(), speed: speedKts };
      }

      prevWaypoint = waypoint;

      return {
        waypointId: waypoint.id,
        ...etaEtdSpeed,
      };
    }
  );

  const routeName = jsonRoute.kml?.Document?.name || "Imported KML";

  return {
    version: "1.0",
    extensions: {
      readonly: false,
      uuid: uuid(), // TODO should this be fresh every time the same route is imported? Should this route be compared to existing routes before getting a uuid?
      canCreateChannels: true,
    },
    routeInfo: {
      routeName: routeName.trim(), // Route names could start with a space that isn't filtered from the regex
    },
    waypoints: {
      waypoints,
      defaultWaypoint: { id: Math.max(...waypoints.map((w) => w.id)) + 1 },
    },
    schedules: { schedules: [{ id: 0, manual: { scheduleElements } }] },
  };
};
