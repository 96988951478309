import { Route as RouteToolbox } from "@sofarocean/route-toolbox";
import { RouteLocation } from "@sofarocean/route-toolbox/build/Route";
import {
  RoutingControlsType,
  VoyageDto,
} from "@sofarocean/wayfinder-typescript-client";
import { RouteEditorConfiguration } from "contexts/RouteStoreContext/state-types";
import {
  calculateRouteDistanceNauticalMiles,
  convertRouteToToolboxRtzJson,
  convertToolboxRtzJsonToRoute,
  getRoutingGuidanceWaypoints,
} from "helpers/routes";
import { isNumber, metersPerSecondToKnots } from "helpers/units";
import { cloneDeep } from "lodash";
import { DateTime } from "luxon";
import { Route } from "shared-types/RouteTypes";
import { match, P } from "ts-pattern";
import { v4 as uuid } from "uuid";

export type MultiSelectWaypointsType = {
  isMultiSelected: boolean;
  selectedWaypointIDs: number[];
  multiSelectWaypoint: (waypointID: number) => void;
  setModifierKeyIsActive: (state: boolean) => void;
  clearMultiSelectedWaypoints: () => void;
};

export function computeAverageSpeedForArrivalTimeKt(
  route: Route,
  arrivalMoment: DateTime
): number | undefined {
  const scheduleElements =
    route.schedules?.schedules?.[0]?.manual?.scheduleElements;
  const etd = scheduleElements?.[0]?.etd;
  const durationHours =
    etd && arrivalMoment?.diff(DateTime.fromISO(etd)).as("hours");
  const distanceNm = calculateRouteDistanceNauticalMiles(route);

  return isNumber(durationHours) ? distanceNm / durationHours : undefined;
}

/**
 * Compute the average speed used by the editor when adjusting the schedule after changing the route.
 * @param voyage
 * @param route
 * @returns
 */
export function getRouteEditorConfiguration(
  voyage: VoyageDto,
  route: Route
): RouteEditorConfiguration {
  const rtaMoment = voyage.arrivalWindows?.length
    ? DateTime.fromISO(
        voyage.arrivalWindows[0].startTimestamp ||
          voyage.arrivalWindows[0].endTimestamp!
      )
    : undefined;
  const hasRta = Boolean(voyage.arrivalWindows?.length);

  // Compute the route duration using the eta of last waypoint
  const scheduleElements =
    route.schedules?.schedules?.[0]?.manual?.scheduleElements;
  const etaDateTime =
    scheduleElements &&
    DateTime.fromISO(scheduleElements[scheduleElements.length - 1]?.eta!);

  const computedSpeedKt =
    etaDateTime && computeAverageSpeedForArrivalTimeKt(route, etaDateTime);

  const voyageSpeedParameter = match(voyage.routingControls)
    .with(
      { __type: RoutingControlsType.RoutingControlsConstraints },
      (routingControls) =>
        isNumber(routingControls.averageSpeedMps) && {
          type: "constraint",
          speedKt: metersPerSecondToKnots(routingControls.averageSpeedMps),
        }
    )
    .with(
      { __type: RoutingControlsType.RoutingControlsInstructions },
      (routingControls) =>
        isNumber(routingControls.instructedSpeedMps) && {
          type: "instruction",
          speedKt: metersPerSecondToKnots(routingControls.instructedSpeedMps),
        }
    )
    .with(
      { __type: RoutingControlsType.RoutingControlsIntentions },
      (routingControls) =>
        isNumber(routingControls.intendedSpeedMps) && {
          type: "intention",
          speedKt: metersPerSecondToKnots(routingControls.intendedSpeedMps),
        }
    )
    .exhaustive() || { type: "computed", speedKt: computedSpeedKt };

  const sidebarHelperText = hasRta
    ? `Edited Route respects the current voyage RTA constraint of ${etaDateTime
        ?.toUTC()
        ?.toFormat(
          "dd MMM HH'Z'"
        )}, estimating an average speed of ${computedSpeedKt?.toFixed(1)} kt`
    : `Edited Route ETA and duration are estimated using the current route's ${match(
        voyageSpeedParameter
      )
        .with(
          { type: "constraint", speedKt: P.number },
          ({ speedKt }) => `average speed constraint of ${speedKt.toFixed(1)}kt`
        )
        .with(
          { type: "instruction", speedKt: P.number },
          ({ speedKt }) => `instructed speed of ${speedKt.toFixed(1)}kt`
        )
        .with(
          { type: "intention", speedKt: P.number },
          ({ speedKt }) => `intended speed of ${speedKt.toFixed(1)}kt`
        )
        .otherwise(
          //TODO do we want to handle undefined speed differently? it's not likely to happen,
          // and it seems like we would need to guarantee etd to avoid it
          ({ speedKt }) => `average speed of ${speedKt?.toFixed(1)}kt`
        )}`;

  return {
    manualScheduleUpdateStrategy: hasRta
      ? "maintain-rta"
      : "maintain-average-speed",
    editorAverageSpeedKts: hasRta ? undefined : voyageSpeedParameter.speedKt,
    editorRta: rtaMoment,
    sidebarHelperText:
      isNumber(voyage.averageSpeed) || computedSpeedKt
        ? sidebarHelperText
        : undefined,
  };
}

export type RouteEditVersion = {
  route: Route;
  speedUpdatedWaypointIDs: number[];
  metadata?: Record<string, any>;
};

export function buildRouteEditVersion(
  draftRoute: Route | undefined,
  speedUpdatedWaypointIDs: number[],
  routeMetadata?: Record<string, any>
): RouteEditVersion | undefined {
  const currentRouteVersion = cloneDeep(draftRoute);
  if (currentRouteVersion) {
    return {
      route: currentRouteVersion,
      speedUpdatedWaypointIDs,
      metadata: routeMetadata,
    };
  }
}

export function eliminateMidDriftWaypoints(route: Route): Route {
  const routeToolbox = RouteToolbox.fromRtzJson(
    convertRouteToToolboxRtzJson(route)
  );

  const drift = routeToolbox.describeDrift();
  if (!drift) {
    return route;
  }

  const isMidDrift = (l: RouteLocation) => {
    return (
      l.timestamp < drift.endLocation.timestamp &&
      l.timestamp > drift.startLocation.timestamp
    );
  };

  // if we ever want editing to preserve accurate consumption and economic data
  // this function will need to add the mid drift figures to the drift-end point

  // remove mid drift locations
  routeToolbox.locations = routeToolbox.locations.filter((l) => !isMidDrift(l));
  return convertToolboxRtzJsonToRoute(routeToolbox.toRtzJson());
}

export function deleteAdjacentLegSimulatorData(
  updatedRoute: Route,
  waypointIndex: number
) {
  const waypointId = updatedRoute.waypoints.waypoints[waypointIndex].id;
  const manualScheduleElements =
    updatedRoute.schedules?.schedules?.[0]?.manual?.scheduleElements;
  const manualScheduleElementIndex = manualScheduleElements?.findIndex(
    (s) => s.waypointId === waypointId
  );
  const simulatorScheduleElements =
    updatedRoute.schedules?.schedules?.[0]?.calculated?.scheduleElements;
  if (
    simulatorScheduleElements &&
    isNumber(manualScheduleElementIndex) &&
    manualScheduleElements
  ) {
    const prevManualScheduleElement =
      manualScheduleElementIndex - 1 >= 0
        ? manualScheduleElements[manualScheduleElementIndex - 1]
        : undefined;
    const prevSimulatorScheduleElementIndex = isNumber(
      prevManualScheduleElement?.waypointId
    )
      ? simulatorScheduleElements?.findIndex(
          (s) => s.waypointId === prevManualScheduleElement?.waypointId
        )
      : undefined;
    const nextManualScheduleElement =
      manualScheduleElementIndex + 1 < manualScheduleElements.length
        ? manualScheduleElements[manualScheduleElementIndex + 1]
        : undefined;
    const nextSimulatorScheduleElementIndex = isNumber(
      nextManualScheduleElement?.waypointId
    )
      ? simulatorScheduleElements?.findIndex(
          (s) => s.waypointId === nextManualScheduleElement?.waypointId
        )
      : undefined;
    const scheduleStartIndex =
      prevSimulatorScheduleElementIndex &&
      prevSimulatorScheduleElementIndex >= 0
        ? prevSimulatorScheduleElementIndex
        : 0;
    const scheduleEndIndex =
      nextSimulatorScheduleElementIndex && nextSimulatorScheduleElementIndex > 0
        ? nextSimulatorScheduleElementIndex
        : simulatorScheduleElements.length - 1;
    if (scheduleEndIndex !== scheduleStartIndex) {
      for (let i = scheduleStartIndex; i <= scheduleEndIndex; i++) {
        // remove all data from schedule element except for waypointID, speed, and times
        simulatorScheduleElements[i] = {
          waypointId: simulatorScheduleElements[i].waypointId,
          eta: simulatorScheduleElements[i].eta,
          etd: simulatorScheduleElements[i].etd,
          speed: simulatorScheduleElements[i].speed,
          extensions: {},
        };
      }
    }
  }
}

export function cloneRouteForEditing({
  route,
}: {
  route: Route;
}): { route: Route; routeUuid: string } {
  let newRoute = cloneDeep(route);
  const newUuid = uuid();
  if (newRoute.extensions) {
    newRoute.extensions.uuid = newUuid;
    newRoute.extensions.canCreateChannels = false;
  }

  // remove polaris extensions
  if (newRoute.routeInfo.extensions) {
    delete newRoute.routeInfo.extensions;
  }

  // if we have only waypoints on this route and no schedules, we can return early
  if (!newRoute.schedules?.schedules?.[0]) {
    return { route: newRoute, routeUuid: newUuid };
  }

  // editing operations break if there are mid-drift waypoints
  newRoute = eliminateMidDriftWaypoints(newRoute);
  // do not preserve the waypoints polaris adds for engine speed changes
  newRoute.waypoints.waypoints = getRoutingGuidanceWaypoints(newRoute, {
    includeMultipleDriftPoints: true,
  });

  // remove calculated schedule
  if (newRoute.schedules?.schedules?.[0]?.calculated) {
    delete newRoute.schedules?.schedules?.[0]?.calculated;
  }
  if (newRoute.schedules?.schedules?.[0]?.manual?.scheduleElements) {
    newRoute.schedules = {
      schedules: [
        {
          ...newRoute.schedules?.schedules?.[0],
          manual: {
            scheduleElements: newRoute.schedules.schedules[0].manual?.scheduleElements
              .filter((
                se // remove schedule elements non-guidance waypoints
              ) =>
                Boolean(
                  newRoute.waypoints.waypoints.find(
                    (w) => w.id === se.waypointId
                  )
                )
              )
              .map((se) => ({
                // remove polaris fields
                waypointId: se.waypointId,
                eta: se.eta,
                etd: se.etd,
                speed: se.speed,
                extensions: {
                  drifting: se.extensions?.drifting,
                },
              })),
          },
        },
      ],
    };
  }

  return { route: newRoute, routeUuid: newUuid };
}
