import { TimestampedRouteDataDto } from "@sofarocean/wayfinder-typescript-client";
import {
  CalculatedScheduleElement,
  CalculatedScheduleElementExtensions,
} from "./shared-types/RouteTypes";
import { GradientArray } from "./components/weather/GLWeatherMapLayer/WeatherRenderer/render-color-utils";
import { UserMetadata } from "./contexts/AuthenticationContext";

export const stormGlassAPIBase = // update this in the service worker too
  process.env.REACT_APP_STORM_GLASS_BASE_URL ||
  "https://api.sofarocean.com/marine-weather/v1/";
// This value is pulled outside of the `config` object so it can be used in arrow functions within
// the config. If it wasn't defined outside of the `config` object, then we could still access it
// with `this.spotterSdkToken` but all of the config functions that need it would need to be
// rewritten as with `function()`.
// This spotter token can be reused between the staging and prod environments.
const spotterSdkToken = "6dc6327f3931b8763c6a4f4341824a";

const emailCommsBase = "https://api.sofarocean.com/email-comms";

export const productionWayfinderBaseUrl =
  "https://api.sofarocean.com/wayfinder";
export const stagingWayfinderBaseUrl =
  "https://cloudfront-api-staging.sofarocean.net/wayfinder";
export const devWayfinderBaseUrl =
  "https://api-dev.sofarocean-test.com/wayfinder";
export const localWayfinderBaseUrl = "http://localhost:8079";

export const useLocalSentry =
  process.env.REACT_APP_ENABLE_LOCAL_SENTRY === "true";

export const fuelCarbonMultiplier = 3.1144;

export const weatherBannerDisplayThresholdInMs = 500;

const wayfinderProductionHostnameRegExp = /^wayfinder\.sofarocean(-test)?\.com$/;

// All chronicler envs point to production data but we'd prefer keeping this
// as the prod URL so we're using the most stable chronicler environment.
export const chroniclerRendererBaseUrl =
  "https://chronicler-renderer-prod.netlify.app";

export const chroniclerToken =
  process.env.REACT_APP_CHRONICLER_TOKEN || "dummy-token";
export const chroniclerBaseUrl =
  process.env.REACT_APP_CHRONICLER_BASE_URL ||
  "https://chronicler-prod.herokuapp.com";

export const isWayfinderProductionHostname = () =>
  !!window.location.hostname.match(wayfinderProductionHostnameRegExp);

const config = {
  appSettingsDatabaseName: "tell-tale", // do not change this. needs to be the same from build to build
  mapLayersDatabaseName: "map-layers", // do not change this. needs to be the same from build to build
  mapboxStyleUrl: "mapbox://styles/jonsen/ckcgprwic1bxz1is2tvd560pg",
  mapboxPublicAccessToken:
    "pk.eyJ1Ijoiam9uc2VuIiwiYSI6IkR6UU9oMDQifQ.dymRIgqv-UV6oz0-HCFx1w",

  frontAppTeammatesEndpoint: `${emailCommsBase}/teammates`,
  frontAppDraftsEndpoint: `${emailCommsBase}/drafts`, // channel Id is configs in AWS Gateway

  frontAppBaseUrl: "https://app.frontapp.com",

  auth0: {
    production: {
      // For now we add these here. In the future, we keep them out of the repo
      clientId: "XVjGKxiimEAQd6a4uCwXuB0Fms5HkCBS",
      domain: "auth.sofarocean.com",
      audience: "sofar-api-prod",
    },
    staging: {
      // For now we add these here. In the future, we keep them out of the repo
      clientId: "NqpkoRivkXlI1UwHH6GHVnhxVqLrQNJv",
      domain: "sofarocean-staging.us.auth0.com",
      audience: "sofar-api-staging",
    },
    sofarOrganizationIds: {
      production: "org_6dGzw7S8IMK2W4Cp",
      staging: "org_MiveA1aML0fOhp2v",
    },
  },

  datadog: {
    applicationId: "e9ec348d-06e2-4ce0-8e9f-4e3e141a9826",
    clientToken: "pubcfdd9dfeb4c65583ba22c3aacf6af332",
  },

  // Nautical charts
  cmap: {
    clientId: "ab5e8af318a6473ea20bc8c8f0fd8d20",
    clientSecret: "1e70a4a11c8d49b784e632e2676dbfe9",
    // update this in the service worker as well
    baseUrl: "https://api.sofarocean.com/c-map-nautical-charts",
  },

  stormGlassWeatherCubesEndpoint: (
    modelID: string,
    variableID: string,
    initTime: string,
    quadkey: string,
    type: "forecast" | "hindcast" = "forecast",
    version: string = "V0",
    resolution: string = "low"
  ) =>
    `${stormGlassAPIBase}models/${modelID}/${type}/weather-cube/` +
    `${variableID}/${initTime}/${quadkey}?version=${version}&resolution=${resolution}&token=${spotterSdkToken}&speak=friend`,

  stormGlassWeatherCubesBatchEndpoint: (
    modelID: string,
    variableID: string,
    initTime: string,
    domain: "global" | "partial",
    quadkeys: string[] = [],
    type: "forecast" | "hindcast" = "forecast",
    version: string = "V0",
    resolution: string = "low"
  ) =>
    `${stormGlassAPIBase}models/${modelID}/${type}/weather-cubes/` +
    `${variableID}/${initTime}/${domain}?version=${version}&resolution=${resolution}&quadkeys=${quadkeys.join(
      ","
    )}&token=${spotterSdkToken}&speak=friend`,

  stormGlassInitTimesEndpoint: (
    modelID: string,
    variableIDs: string[],
    queryTime: Date
  ) =>
    `${stormGlassAPIBase}models/` +
    `${modelID}/init-times?dataFormat=weather-cube&availableAt=${queryTime.toISOString()}&` +
    `variableIDs=${variableIDs.join(
      ","
    )}&dataType=forecast&token=${spotterSdkToken}&speak=friend`,

  spotterSdkToken,
  spotterApiBase:
    process.env.REACT_APP_WAVEFLEET_BASE_URL || "https://api.sofarocean.com",
  get spotterLatestDataEndpoint() {
    return `${this.spotterApiBase}/oceans-api/latest-data?includeWindData=true&includeSharedDevices=true`;
  },
  staleSpotterDataAgeMs: 1000 * 60 * 60 * 2, // any spotter data older than an hour is not shown
  aisHistoryAgeHours: 3 * 24, // show ais data up to 3 days
  fleetViewAisWarningAgeDays: 3, // show a warning in inspector when ais is old
  seaTrialVoyageLocalStorageKey: "sea-trial-voyage",
  stormGlassTropicalStormForeCastAPI:
    stormGlassAPIBase + "tropical-storms/forecast",

  // Right now this is the polaris URL we should be using for all envs, because there isn't
  // really a concept of all the different environments for the polaris API yet.
  polarisApiBase:
    process.env.REACT_APP_POLARIS_BASE_URL ||
    "https://api.sofarocean.com/ship-routing",
  /** This distance sets the buffer around the route line for which weather will
   *  automatically be downloaded.
   */
  weatherBufferDistanceKm: 2000,

  /** Determine how frequently the service worker should check for new app
   *  versions of the app. The app will always check for updates immediately
   *  after the initial page load— this handles the case where a
   *  long-running tab is left open
   */
  serviceWorkerUpdateFrequencySeconds: 60,

  // Poll for new AIS data every 10 minutes
  aisPollIntervalMs: 60 * 10 * 1000,

  // Poll for new noon reports every 10 minutes
  noonPollIntervalMs: 60 * 60 * 1000,

  routePollIntervalMs: 60 * 1000,

  multilegVoyagesPollIntervalMs: 60 * 1000,

  currentUserPollIntervalMs: 60 * 1000,

  spotterPollIntervalMs: 10 * 60 * 1000,

  userMetadataRetryInterval: 15 * 1000,

  mutationRetries: 3,

  tropicalStormsPollIntervalMs: 10 * 60 * 1000,

  simulatorStepSizeMs: 60 * 60 * 1000,

  dailyRoutingEmailWaypointTimeWindowDurationMs: 48 * 60 * 60 * 1000,

  nearFutureSafetyWarningsTimeWindowDurationMs: 48 * 60 * 60 * 1000,

  aisMaxAgeForComputingTimezoneHours: 3,

  guidanceSendOutTimeHours: 9,

  bannerAutoDismissDelayMs: 7000,

  arrivedUxBufferTimeDays: 10,

  weatherAlongRouteQuantity: "combinedWaves" as WeatherQuantities,

  weatherMapColorGradient: [
    ["#B3D9FB", 0],
    ["#669AED", 20],
    ["#73DDC9", 35],
    ["#ead86a", 55],
    ["#F77C79", 80],
    ["#E25685", 90],
    ["#7a100e", 100],
  ] as GradientArray,

  // The values for visibility are opposite of all other weather values. Lower values mean bad visibility and
  // higher values mean good visibility so the colors are in reverse order. In addition, the visibility values
  // should not be linearly distributed along this gradient so the percentages have been adjusted to look
  // better on the map.
  visibilityWeatherMapColorGradient: [
    ["#7a100e", 0],
    ["#E25685", 1], // 0.28NM
    ["#F77C79", 3], // 0.84NM
    ["#ead86a", 7], // 1.96NM
    ["#73DDC9", 10], // 2.8NM
    ["#669AED", 25], // 7NM
    ["#B3D9FB", 100], // 28NM
  ] as GradientArray,

  visibilityScale: ["0", "0.5", "2", "5", "28+"] as const,
  precipitationScale: [
    "Light", // < 2.5 mm/hr
    "Moderate", // < 7.5 mm/hr
    "Heavy", // >= 7.5 mm/hr
  ] as const,

  weatherVariables: {
    combinedWaves: {
      modelID: "SofarECMWFHResOperationalWaveModel",
      magnitudeVariableID: "significantWaveHeight",
      directionVariableID: "meanDirection",
      type: "magnitude-direction",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 10, mapLayer: 10 },
      magnitudeUnits: "m",
      displayUnits: "m",
      displayName: "Significant Wave Height",
      scheduleElementKey: {
        magnitude: {
          extensions: "significantWaveHeight",
        },
        direction: {
          extensions: "meanDirection",
        },
      },
      displayPrecision: 1,
      safetySettings: {
        key: "waveHeightLimit",
        type: "max",
      },
    },
    seas: {
      modelID: "SofarECMWFHResOperationalWaveModel",
      magnitudeVariableID: "significantWaveHeightWindWaves",
      directionVariableID: "meanDirectionWindWaves",
      type: "magnitude-direction",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 10, mapLayer: 10 },
      magnitudeUnits: "m",
      displayUnits: "m",
      displayName: "Seas",
      scheduleElementKey: {
        magnitude: {
          extensions: "seasHeight",
        },
        direction: {
          extensions: "seasDirection",
        },
      },
      displayPrecision: 1,
      safetySettings: {
        key: "waveHeightLimit",
        type: "max",
      },
    },
    swell: {
      modelID: "SofarECMWFHResOperationalWaveModel",
      magnitudeVariableID: "significantWaveHeightFirstSwell",
      directionVariableID: "meanDirectionFirstSwell",
      type: "magnitude-direction",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 12, mapLayer: 12 },
      magnitudeUnits: "m",
      displayUnits: "m",
      displayName: "Swell",
      scheduleElementKey: {
        magnitude: {
          extensions: "swellHeight",
        },
        direction: {
          extensions: "swellDirection",
        },
      },
      displayPrecision: 1,
    },
    swellPeriod: {
      modelID: "SofarECMWFHResOperationalWaveModel",
      variableID: "peakPeriodFirstSwell",
      type: "scalar",
      magnitudeMin: { plot: 0, mapLayer: 5 },
      magnitudeMax: { plot: 28, mapLayer: 30 },
      magnitudeUnits: "s",
      displayUnits: "s",
      displayName: "Swell Period",
      scheduleElementKey: {
        magnitude: { extensions: "swellPeriod" },
      },
      displayPrecision: 1,
    },
    seasPeriod: {
      modelID: "SofarECMWFHResOperationalWaveModel",
      variableID: "peakPeriodWindWaves",
      type: "scalar",
      magnitudeMin: { plot: 2, mapLayer: 2 },
      magnitudeMax: { plot: 12, mapLayer: 12 },
      magnitudeUnits: "s",
      displayUnits: "s",
      displayName: "Seas Period",
      scheduleElementKey: {
        magnitude: { extensions: "seasPeriod" },
      },
      displayPrecision: 1,
    },
    peakWaveFrequency: {
      modelID: "SofarECMWFHResOperationalWaveModel",
      variableID: "peakFrequency",
      type: "scalar",
      magnitudeMin: { plot: 0, mapLayer: 1 },
      magnitudeMax: { plot: 0.05, mapLayer: 30 },
      magnitudeUnits: "Hz",
      displayUnits: "s",
      displayName: "Peak Wave Period",
      scheduleElementKey: {
        magnitude: { extensions: "peakWaveFrequency" },
      },
      displayPrecision: 1,
    },
    currents: {
      modelID: "MercatorOcean",
      eastingVariableID: "surfaceCurrentVelocityEastward",
      northingVariableID: "surfaceCurrentVelocityNorthward",
      type: "vector-components",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 1.5, mapLayer: 1.5 },
      magnitudeUnits: "m / s",
      displayUnits: "kts",
      displayName: "Currents",
      scheduleElementKey: {
        magnitude: "currentSpeed",
        direction: "currentDirection",
      },
      displayPrecision: 1,
    },
    wind: {
      modelID: "ECMWFHRes",
      eastingVariableID: "windVelocity10MeterEastward",
      northingVariableID: "windVelocity10MeterNorthward",
      type: "vector-components",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 30.8667, mapLayer: 30.8667 },
      magnitudeUnits: "m / s",
      displayUnits: "kts",
      displayName: "Wind Speed",
      scheduleElementKey: {
        magnitude: "windSpeed",
        direction: "windDirection",
      },
      displayPrecision: 1,
      safetySettings: {
        key: "windSpeedLimit",
        type: "max",
      },
    },
    barometricPressure: {
      modelID: "ECMWFHRes",
      variableID: "meanSeaLevelPressure",
      type: "scalar",
      magnitudeMin: { plot: 95000, mapLayer: 80000 },
      magnitudeMax: { plot: 105000, mapLayer: 105000 },
      magnitudeUnits: "Pa",
      displayUnits: "mbar",
      displayName: "Pressure",
      scheduleElementKey: {
        magnitude: { extensions: "barometricPressure" },
      },
      displayPrecision: 0,
    },
    precipitation: {
      modelID: "ECMWFHRes",
      variableID: "precipitationRate",
      type: "scalar",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 0.0025, mapLayer: 0.0025 },
      magnitudeUnits: "kg/(m^2 s)",
      displayUnits: "mm/hr",
      displayName: "Precip",
      scheduleElementKey: {
        magnitude: { extensions: "precipitation" },
      },
      displayPrecision: 1,
    },
    visibility: {
      modelID: "ECMWFHRes",
      variableID: "visibility",
      type: "scalar",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 51_000, mapLayer: 51_000 },
      magnitudeUnits: "m",
      displayUnits: "NM",
      displayName: "Visibility",
      scheduleElementKey: {
        magnitude: { extensions: "visibility" },
      },
      displayPrecision: 1,
    },
    windGust: {
      modelID: "ECMWFHRes",
      variableID: "windGustMax6Hour10Meter",
      type: "scalar",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 30.8667, mapLayer: 30.8667 },
      magnitudeUnits: "m / s",
      displayUnits: "kts",
      displayName: "Wind Gust",
      scheduleElementKey: {
        magnitude: "windGust",
      },
      displayPrecision: 1,
      safetySettings: {
        key: "windSpeedLimit",
        type: "max",
      },
    },
  } as WeatherVariableConfiguration,

  routeVariables: {
    power: {
      type: "scalar",
      magnitudeMin: { plot: 0, mapLayer: 0.4 },
      magnitudeMax: { plot: 1, mapLayer: 0.8 },
      magnitudeUnits: "% MCR as fraction",
      displayUnits: "% MCR",
      displayName: "Power",
      scheduleElementKey: {
        magnitude: {
          extensions: "percentPowerMCR",
        },
      },
      displayPrecision: 1,
    },
    speed: {
      type: "scalar",
      magnitudeMin: { plot: 10, mapLayer: 10 },
      magnitudeMax: { plot: 25, mapLayer: 25 },
      magnitudeUnits: "kts",
      displayUnits: "kts",
      displayName: "Speed Over Ground",
      scheduleElementKey: {
        magnitude: "speed",
      },
      displayPrecision: 1,
    },
    rpm: {
      type: "scalar",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 100, mapLayer: 100 },
      magnitudeUnits: "rpm",
      displayUnits: "rpm",
      displayName: "RPM",
      scheduleElementKey: {
        magnitude: "rpm",
      },
      displayPrecision: 1,
    },
    pitch: {
      type: "scalar",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 2, mapLayer: 2 },
      magnitudeUnits: "",
      displayUnits: "",
      displayName: "Pitch",
      scheduleElementKey: {
        magnitude: "pitch",
      },
      displayPrecision: 1,
    },
    currentFactor: {
      type: "scalar",
      magnitudeMin: { plot: -1.2, mapLayer: -1.5 },
      magnitudeMax: { plot: 1.2, mapLayer: 1.5 },
      magnitudeUnits: "kts",
      displayUnits: "kts",
      displayName: "Current Factor",
      scheduleElementKey: {
        magnitude: { extensions: "currentFactor" },
      },
      displayPrecision: 1,
    },
    rollAngle: {
      type: "scalar",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 20, mapLayer: 20 },
      magnitudeUnits: "deg",
      displayUnits: "deg",
      displayName: "Roll angle",
      scheduleElementKey: {
        magnitude: { extensions: "rollAngle" },
      },
      displayPrecision: 1,
      safetySettings: {
        key: "rollAngleThreshold",
        type: "max",
      },
    },
    pitchAngle: {
      type: "scalar",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 8, mapLayer: 8 },
      magnitudeUnits: "deg",
      displayUnits: "deg",
      displayName: "Pitch angle",
      scheduleElementKey: {
        magnitude: { extensions: "pitchAngle" },
      },
      displayPrecision: 1,
      safetySettings: {
        key: "pitchAngleThreshold",
        type: "max",
      },
    },
  } as RouteVariableConfiguration,

  timestampedRouteVariables: {
    economicCostDollars: {
      type: "scalar",
      magnitudeMin: { plot: 500, mapLayer: 0 },
      magnitudeMax: { plot: 3000, mapLayer: 3000 },
      magnitudeUnits: "$/h",
      displayUnits: "$/h",
      displayName: "Economic Cost",
      displayPrecision: 0,
    },
    speedOverGroundMps: {
      type: "scalar",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 15.5, mapLayer: 25 },
      magnitudeUnits: "m / s",
      displayUnits: "kts",
      displayName: "Speed Over Ground",
      displayPrecision: 0,
      safetySettings: {
        key: "minimumSpeedThreshold",
        type: "min",
      },
    },
    safetyViolationFactor: {
      type: "scalar",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 1, mapLayer: 1 },
      magnitudeUnits: "",
      displayUnits: "",
      displayName: "Safety Violation Factor",
      displayPrecision: 1,
    },
    fuelViolationFactor: {
      type: "scalar",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 1, mapLayer: 1 },
      magnitudeUnits: "",
      displayUnits: "",
      displayName: "Fuel Violation Factor",
      displayPrecision: 1,
    },
    percentMcr: {
      type: "scalar",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 1, mapLayer: 1 },
      magnitudeUnits: "% MCR as fraction",
      displayUnits: "% MCR",
      displayName: "Hourly Power",
      displayPrecision: 1,
    },
    rpmViolationFactor: {
      type: "scalar",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 20, mapLayer: 20 },
      magnitudeUnits: "",
      displayUnits: "",
      displayName: "Rpm Violation Factor",
      displayPrecision: 1,
    },
    speedViolationFactor: {
      type: "scalar",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 20, mapLayer: 20 },
      magnitudeUnits: "",
      displayUnits: "",
      displayName: "Speed Violation Factor",
      displayPrecision: 1,
    },
  } as TimestampedRouteVariableConfiguration,

  analytics: {
    trackingID: "UA-176920792-1",
    debug: false,
    mixpanelProjectTokens: {
      prod: "d9fa0dc744a76e346294b0fd2548adf9",
      staging: "692d4d338f7c1dd2b81ec19149d0f9a6",
      dev: "c651fcb31fef1484811c3eab96a6a135",
    },
    mixpanelApiHost: "https://api.sofarocean.com/client-analytics",
  } as AnalyticsConfiguration,

  defaultUserMetadata: {
    vesselUuid: null,
    accountType: "single" as const,
    roles: [],
  } as UserMetadata,

  /** The identifier used for this project's error reporting on sentry.io */
  sentryDSN:
    "https://3cc843f39ba047f8baf35a22ebeb46b4@o504711.ingest.sentry.io/5600986",
  skipWeatherUpdates: process.env.REACT_APP_SKIP_WEATHER_UPDATES
    ? JSON.parse(process.env.REACT_APP_SKIP_WEATHER_UPDATES)
    : false,
};
export type WeatherVariableUnit =
  | "m / s"
  | "kts"
  | "s"
  | "m"
  | "Pa"
  | "mbar"
  | "Hz"
  | "NM"
  | "kg/(m^2 s)"
  | "mm/hr";
export type RouteVariableUnit =
  | "% MCR as fraction"
  | "% MCR"
  | "kts"
  | "rpm"
  | "deg"
  | ""
  | "$/h"
  | "m / s";

export type WeatherQuantities =
  | "seas"
  | "swell"
  | "combinedWaves"
  | "swellPeriod"
  | "seasPeriod"
  | "peakWaveFrequency"
  | "currents"
  | "wind"
  | "barometricPressure"
  | "precipitation"
  | "visibility"
  | "windGust";
export type WeatherVariableConfiguration = Record<
  WeatherQuantities,
  CompositeWeatherVariableDefinition
>;

export type RouteQuantities =
  | "power"
  | "speed"
  | "rpm"
  | "pitch"
  | "currentFactor"
  | "rollAngle"
  | "pitchAngle";
export type RouteVariableConfiguration = Record<
  RouteQuantities,
  RouteVariableDefinition
>;

export type TimestampedRouteQuantities = keyof Omit<
  TimestampedRouteDataDto,
  "speedOverGroundMs"
>;
export type TimestampedRouteVariableConfiguration = Record<
  TimestampedRouteQuantities,
  RouteVariableDefinition
>;

export type ScheduleElementKey =
  | { extensions: keyof CalculatedScheduleElementExtensions }
  | keyof CalculatedScheduleElement;

type BaseVariableDefinition<T> = {
  /** Minimum expected value to use when visualizing data */
  magnitudeMin: { plot: number; mapLayer: number };
  /** Maximum expected value to use when visualizing data */
  magnitudeMax: { plot: number; mapLayer: number };
  magnitudeUnits: T;
  /** Units to display values in on screen **/
  displayUnits: T;
  displayName: string;
  /** Indicate where in the route's schedule element this value belongs */
  scheduleElementKey?: {
    magnitude: ScheduleElementKey;
    direction?: ScheduleElementKey;
  };
  /** Maximum number of significant digits after the decimal point to display */
  displayPrecision: number;
  safetySettings?: {
    key: string;
    type: "min" | "max";
  };
};

export type RouteVariableDefinition = BaseVariableDefinition<RouteVariableUnit>;

export type WeatherVariableDefinition = BaseVariableDefinition<WeatherVariableUnit> & {
  modelID: string;
};

export type MagnitudeDirectionCompositeDefinition = {
  type: "magnitude-direction";
  magnitudeVariableID: string;
  directionVariableID: string;
} & WeatherVariableDefinition;
export type VectorComponentsCompositeDefinition = {
  type: "vector-components";
  eastingVariableID: string;
  northingVariableID: string;
} & WeatherVariableDefinition;
export type ScalarCompositeDefinition = {
  type: "scalar";
  variableID: string;
} & WeatherVariableDefinition;
export type CompositeWeatherVariableDefinition =
  | MagnitudeDirectionCompositeDefinition
  | VectorComponentsCompositeDefinition
  | ScalarCompositeDefinition;

export type AnalyticsConfiguration = {
  trackingID: string;
  debug: boolean;
  mixpanelProjectTokens: { dev: string; prod: string; staging: string };
  mixpanelApiHost: string;
};

export default config;
