import { Box, Grid, SvgIcon, Theme } from "@mui/material";
import { ReactComponent as CurrentsIcon } from "bundle-data/images/icon-current.svg";
import { ReactComponent as SeasIcon } from "bundle-data/images/icon-sea.svg";
import { ReactComponent as SwellIcon } from "bundle-data/images/icon-swell.svg";
import { RouteStyle } from "components/WeatherAlongRoutePlot";
import config from "config";
import { degreesToCompassDirection } from "helpers/units";
import { isNil } from "lodash";
import React, { useMemo } from "react";
import { WeatherValuesDict } from "shared-types/WeatherTypes";
import { extendedPalette, typographyStyles } from "styles/theme";
import { match } from "ts-pattern";
import { makeStyles } from "tss-react/mui";
import { windSpeedKtsToBeaufort } from "helpers/weather";
import { WayfinderTypography } from "../../../DLS/WayfinderTypography";
import { INDICATOR_HEIGHT } from "../CombinedWeatherDirectionIndicator";
import { VesselDirectionIndicator } from "../CombinedWeatherDirectionIndicator/VesselDirectionIndicator";
import { WeatherDirectionIndicator } from "../CombinedWeatherDirectionIndicator/WeatherDirectionIndicator";
import { WindDirectionIndicator } from "../CombinedWeatherDirectionIndicator/WindDirectionIndicator";

const useWayfinderTypographyStyles = makeStyles()(typographyStyles);

const useStyles = makeStyles()((theme: Theme) => {
  return {
    legendIcon: {
      marginRight: parseFloat(theme.spacing()) * 2,
      overflow: "visible",
      width: 0.9 * INDICATOR_HEIGHT,
      height: 0.9 * INDICATOR_HEIGHT,
      transform: `translateY(70%)`,
    },
    legendBarbIcon: {
      marginRight: parseFloat(theme.spacing()) * 2.8,
      overflow: "visible",
      width: 14,
      height: 10,
      transform: `translateY(20%) translateX(20%)`,
    },
  };
});

type WeatherPanelReadoutRow = {
  value?: WeatherValuesDict[keyof WeatherValuesDict];
  label: string;
  icon: React.ReactNode | null;
  formattedValue: string;
};

const WeatherDirectionIndicatorIcon: React.FC<{
  icon: React.ReactFragment;
  color: string;
}> = ({ icon, color }: { icon: React.ReactFragment; color: string }) => {
  const { classes: styles } = useStyles();
  return (
    <svg
      className={styles.legendIcon}
      viewBox={`0 0 ${INDICATOR_HEIGHT} ${INDICATOR_HEIGHT}`}
    >
      <WeatherDirectionIndicator icon={icon} color={color} direction={-90} />
    </svg>
  );
};

const WindDirectionIndicatorIcon: React.FC = () => {
  const { classes: styles } = useStyles();
  return (
    <svg
      viewBox={`-1.75, -1, 2, 2`}
      className={styles.legendBarbIcon}
      width={0.9 * INDICATOR_HEIGHT}
      height={0.9 * INDICATOR_HEIGHT}
    >
      <g transform="scale(3)">
        <WindDirectionIndicator
          magnitude={10}
          direction={-90}
          strokeWidth={4}
        />
      </g>
    </svg>
  );
};

const ICONS: Record<keyof WeatherValuesDict, WeatherPanelReadoutRow["icon"]> = {
  combinedWaves: null,
  seas: (
    <WeatherDirectionIndicatorIcon
      icon={<SeasIcon />}
      color={extendedPalette.weatherDirections.seas}
    />
  ),
  swell: (
    <WeatherDirectionIndicatorIcon
      icon={<SwellIcon />}
      color={extendedPalette.weatherDirections.swell}
    />
  ),
  currents: (
    <WeatherDirectionIndicatorIcon
      icon={<CurrentsIcon />}
      color={extendedPalette.weatherDirections.currents}
    />
  ),
  wind: <WindDirectionIndicatorIcon />,
  swellPeriod: null,
  precipitation: null,
  visibility: null,
  barometricPressure: null,
  windGust: null,
};

const WEATHER_VALUES_WITHOUT_DIRECTION: (keyof WeatherValuesDict)[] = [
  "visibility",
  "precipitation",
  "barometricPressure",
];

const MISSING_VALUE = "--";

const getMagnitudeText = (
  weatherValuesInDisplayUnits: Partial<WeatherValuesDict>,
  key: keyof WeatherValuesDict
) => {
  const value = weatherValuesInDisplayUnits[key];
  const { swellPeriod } = weatherValuesInDisplayUnits;
  const magnitudeValue = value?.magnitude;
  const { displayUnits } = config.weatherVariables[key];

  const additionalText =
    key !== "swell"
      ? ""
      : ` @ ${
          swellPeriod &&
          !isNil(swellPeriod.magnitude) &&
          !isNaN(swellPeriod.magnitude)
            ? swellPeriod.magnitude.toFixed(1)
            : MISSING_VALUE
        }${swellPeriod?.units ?? ""}`;

  if (isNil(magnitudeValue) || isNaN(magnitudeValue)) {
    return `${MISSING_VALUE}${displayUnits}${additionalText}`;
  }

  const significantDigits = match(key)
    .with("wind", () => 0)
    .with("visibility", () =>
      magnitudeValue < 1 ? 2 : magnitudeValue < 10 ? 1 : 0
    )
    .with("precipitation", () => 3)
    .otherwise(() => 1);

  return `${magnitudeValue.toFixed(
    significantDigits
  )}${displayUnits}${additionalText}`;
};

export const getFormattedRowData = ({
  weatherValuesInDisplayUnits,
  weatherKeysToDisplay,
}: {
  weatherValuesInDisplayUnits: Partial<WeatherValuesDict>;
  weatherKeysToDisplay: (keyof WeatherValuesDict)[];
}): WeatherPanelReadoutRow[] => {
  return (Object.keys(
    weatherValuesInDisplayUnits
  ) as (keyof WeatherValuesDict)[])
    .filter(
      (key) =>
        key !== "swellPeriod" && // swell period is grouped with swell on one line
        weatherKeysToDisplay.includes(key)
    )
    .sort((key1, key2) => {
      const order1 = weatherValuesInDisplayUnits[key1]?.order;
      const order2 = weatherValuesInDisplayUnits[key2]?.order;
      return !isNil(order1) && !isNil(order2) ? order1 - order2 : 0;
    })
    .map<WeatherPanelReadoutRow>((key) => {
      const value = weatherValuesInDisplayUnits[key as keyof WeatherValuesDict];
      const { displayName } = config.weatherVariables[key];

      const directionValue = value?.direction;
      let displayDirection: string | undefined;
      if (isNil(directionValue) || isNaN(directionValue)) {
        displayDirection = WEATHER_VALUES_WITHOUT_DIRECTION.includes(key)
          ? undefined
          : MISSING_VALUE;
      } else {
        displayDirection =
          key === "currents"
            ? degreesToCompassDirection(directionValue + 180)
            : degreesToCompassDirection(directionValue);
      }
      displayDirection = !isNil(displayDirection)
        ? `, ${displayDirection}`
        : "";

      let magnitudeText = getMagnitudeText(weatherValuesInDisplayUnits, key);
      if (key === "wind") {
        const windSpeedKts = weatherValuesInDisplayUnits[key]?.magnitude;
        const windBeaufortScale = !isNil(windSpeedKts)
          ? windSpeedKtsToBeaufort(windSpeedKts)
          : null;
        if (!isNil(windBeaufortScale)) {
          magnitudeText = `${magnitudeText}, BF ${windBeaufortScale}`;
        }
      }

      const formattedValue = `${magnitudeText}${displayDirection}`;

      return {
        value,
        label:
          key === "combinedWaves"
            ? "Sig. Wave Ht."
            : key === "wind"
            ? "Wind"
            : displayName,
        icon: ICONS[key],
        formattedValue,
      };
    });
};

export const PanelReadoutRow: React.FC<
  WeatherPanelReadoutRow & {
    typeographyVariant?:
      | keyof ReturnType<typeof useWayfinderTypographyStyles>["classes"]
      | "none";
  }
> = ({ label, icon, formattedValue, typeographyVariant }) => (
  <>
    <Grid item xs={6}>
      <WayfinderTypography variant={typeographyVariant} key={`${label}-icon`}>
        {icon} {label}
      </WayfinderTypography>
    </Grid>
    <Grid item xs={6} textAlign={"right"}>
      <WayfinderTypography variant={typeographyVariant} key={`${label}-value`}>
        {formattedValue}
      </WayfinderTypography>
    </Grid>
  </>
);

export const WeatherPanelReadout: React.FC<
  Partial<WeatherValuesDict> & {
    weatherKeysToDisplay: (keyof WeatherValuesDict)[];
    formattedVesselSpeed?: string;
    routeStyle?: RouteStyle;
    gridSpacing?: number;
    typeographyVariant?:
      | keyof ReturnType<typeof useWayfinderTypographyStyles>["classes"]
      | "none";
  }
> = ({
  wind,
  seas,
  swell,
  swellPeriod,
  currents,
  combinedWaves,
  precipitation,
  visibility,
  barometricPressure,
  weatherKeysToDisplay,
  formattedVesselSpeed,
  routeStyle,
  gridSpacing = 1,
  typeographyVariant = "buttonSmall",
}) => {
  const weatherValuesInDisplayUnits = useMemo(
    () => ({
      wind,
      seas,
      swell,
      swellPeriod,
      currents,
      combinedWaves,
      precipitation,
      visibility,
      barometricPressure,
    }),
    [
      wind,
      seas,
      swell,
      swellPeriod,
      currents,
      combinedWaves,
      precipitation,
      visibility,
      barometricPressure,
    ]
  );
  const rowData = useMemo(
    () =>
      getFormattedRowData({
        weatherValuesInDisplayUnits,
        weatherKeysToDisplay,
      }),
    [weatherValuesInDisplayUnits, weatherKeysToDisplay]
  );

  return (
    <Box width={"100%"} height={"100%"}>
      {rowData && (
        <Grid
          container
          spacing={gridSpacing}
          direction={"row"}
          alignItems="center"
        >
          {rowData.map((row) => (
            <PanelReadoutRow
              key={row.label}
              {...row}
              typeographyVariant={typeographyVariant}
            />
          ))}
          {formattedVesselSpeed && (
            <PanelReadoutRow
              label="Vessel SOG"
              key="vessel-sog"
              formattedValue={formattedVesselSpeed}
              typeographyVariant={typeographyVariant}
              icon={
                <SvgIcon
                  sx={{
                    overflow: "visible",
                    width: 0.9 * INDICATOR_HEIGHT,
                    height: 0.9 * INDICATOR_HEIGHT,
                    transform: `translateY(70%) translateX(20%)`,
                    marginRight: "8px",
                  }}
                  viewBox="0 0 0.5 1"
                >
                  <VesselDirectionIndicator
                    heading={90}
                    fill={routeStyle?.vesselColor}
                    stroke={extendedPalette.black}
                  />
                </SvgIcon>
              }
            />
          )}
        </Grid>
      )}
    </Box>
  );
};
