import { FLEET_VIEW_TABS, FleetViewTab } from "contexts/FleetViewContext";
import { uniq } from "lodash";
import { useCallback, useMemo, useRef } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { match } from "ts-pattern";
import {
  BooleanParam,
  StringParam,
  UrlUpdateType,
  useQueryParams,
  withDefault,
} from "use-query-params";
import {
  RouteQuantities,
  TimestampedRouteQuantities,
  WeatherQuantities,
} from "../config";
import {
  RasterWeatherLayer,
  UIContextDefaults,
  VectorWeatherLayer,
  WeatherMapLayers,
} from "../contexts/UIContext";
import { useFleetViewVisibility } from "./visibility-hooks/use-fleet-view-visibility";

export const PRINT_PATH = `/print`;

export type UrlParams = {
  vesselUuid?: string;
  voyageUuid?: string;
  routeUuid?: string;
  mlvUuid?: string;
};

export type WayfinderUrlMapping =
  | {
      type: "voyage";
      voyageUuid?: string;
      vesselUuid?: string | null | undefined;
      routesToCompare?: string[] | undefined;
    }
  | {
      type: "voyage-print";
      vesselUuid?: string;
      voyageUuid?: string;
    }
  | {
      type: "routes";
      voyageUuid?: string;
      vesselUuid?: string | null | undefined;
    }
  | {
      type: "route-detail";
      vesselUuid?: string;
      voyageUuid?: string;
      routeUuid: string;
    }
  | {
      type: "route-edit";
      vesselUuid?: string;
      voyageUuid?: string;
      routeUuid: string;
    }
  | {
      type: "route-explorer";
      vesselUuid?: string;
      voyageUuid?: string;
    }
  | {
      type: "route-explorer-edit";
      vesselUuid?: string;
      voyageUuid?: string;
      routeUuid: string;
    }
  | {
      type: "route-explorer-detail";
      vesselUuid?: string;
      voyageUuid?: string;
      routeUuid: string;
    }
  | {
      type: "fleet";
    }
  | {
      type: "seakeeping";
      vesselUuid?: string;
      voyageUuid?: string;
    }
  | {
      type: "dashboard";
      voyageUuid?: string;
      vesselUuid?: string;
    }
  | {
      type: "area-constraints";
      voyageUuid?: string;
      vesselUuid?: string;
    }
  | {
      type: "optimization";
      voyageUuid?: string;
      vesselUuid?: string;
    }
  | {
      type: "optimization-history";
      voyageUuid?: string;
      vesselUuid?: string;
    }
  | {
      type: "layers";
      voyageUuid?: string;
      vesselUuid?: string;
    }
  | {
      type: "all-voyages";
      voyageUuid?: string;
      vesselUuid?: string;
    }
  | {
      type: "new-voyage";
      vesselUuid: string;
    }
  | {
      type: "edit-voyage";
      voyageUuid?: string;
    }
  | {
      type: "route-import";
      voyageUuid?: string;
    }
  | {
      type: "vessel";
      vesselUuid?: string;
    }
  | {
      type: "vessel-adherence";
      vesselUuid?: string;
    }
  | {
      type: "vessel-performance";
      vesselUuid?: string;
    }
  | {
      type: "vessel-noons";
      vesselUuid?: string;
    }
  | {
      type: "vessel-reports";
      vesselUuid?: string;
    }
  | {
      type: "vessel-voyage-report";
      vesselUuid?: string;
      mlvUuid?: string;
    }
  | {
      type: "settings";
    }
  | {
      type: "settings-global-area-constraints";
    }
  | {
      type: "settings-organization-area-constraints";
    };

export type PlotQuantities =
  | WeatherQuantities
  | RouteQuantities
  | TimestampedRouteQuantities;
type WayfinderUrl = WayfinderUrlMapping["type"];
export type WayfinderUrlParam<T> = ExtractActionParameters<
  WayfinderUrlMapping,
  T
>;

type ExtractActionParameters<A, T> = Omit<Extract<A, { type: T }>, "type">;

export type WayfinderUrlOptions<P extends WayfinderUrl> = {
  params?: WayfinderUrlParam<P>;
  replace?: boolean;
};

export type UseWayfinderUrlResult = {
  voyageUuid?: string;
  vesselUuid?: string;
  mlvUuid?: string;
  routeUuid?: string;
  routeUuidsToCompare: string[];
  routeDetailUUId?: string;
  weatherMapLayers: WeatherMapLayers;
  weatherPlotQuantity: PlotQuantities;
  simulatedHistoricalTime?: Date;
  fleetViewTab?: FleetViewTab;
  rpmAdherenceUuid?: string;

  setWeatherMapLayers: (weatherMapLayers: WeatherMapLayers) => void;
  setWeatherPlotQuantity: (plotQuantity: PlotQuantities) => void;
  setSimulatedHistoricalTime: (simulatedHistoricalTime: string) => void;
  setFleetViewTab: (tab: FleetViewTab) => void;
  setRpmAdherenceUuid: (rpmAdherenceUuid: string | undefined) => void;

  /**
   * Set the URL to a given @see WayfinderUrl.
   */
  setUrl: <P extends WayfinderUrl>(
    path: P,
    options?: WayfinderUrlOptions<P>
  ) => void;

  getUrl: <P extends WayfinderUrl>(
    path: P,
    options?: WayfinderUrlOptions<P>
  ) => string | undefined;

  setRoutesToCompare: (routeUuids: string[]) => void;
};

const weatherMapLayerRasterDefault = UIContextDefaults.weatherMapLayers.raster;
const weatherMapLayerVectorDefault = UIContextDefaults.weatherMapLayers.vector;
const weatherMapLayerPressureDefault =
  UIContextDefaults.weatherMapLayers.showPressure;
const weatherMapLayerShowTropicalStormsDefault =
  UIContextDefaults.weatherMapLayers.showTropicalStorms;
const weatherMapLayerShowNauticalChartsDefault =
  UIContextDefaults.weatherMapLayers.showNauticalCharts;
const weatherMapLayerShowIceLayersDefault =
  UIContextDefaults.weatherMapLayers.showIceLayers;

const plotQuantityDefault: PlotQuantities = "combinedWaves";
const simulatedHistoricalTimeDefault = "";

type WayfinderQuery = {
  routesToCompare?: string;
  plotQuantity?: string;
  simulatedHistoricalTime?: string;
  rpmAdherenceUuid?: string;
};

type WayfinderWeatherQuery = {
  showSpotters?: boolean;
  showPorts?: boolean;
  showPressure?: boolean;
  showTropicalStorms?: boolean;
  showNauticalCharts?: boolean;
  showIceLayers?: boolean;
  raster?: RasterWeatherLayer;
  vector?: VectorWeatherLayer;
};

export const FLEET_VIEW_PATH = `/fleet`;
export const SETTINGS_PATH = `/wayfinder-settings`;
export const GLOBAL_AREA_CONSTRAINT_SETTINGS_PATH = `${SETTINGS_PATH}/global-area-constraints`;
export const ORGANIZATION_AREA_CONSTRAINT_SETTINGS_PATH = `${SETTINGS_PATH}/organization-area-constraints`;

export const VESSEL_DETAILS_BASE_PATH = `/vessel/:vesselUuid`;

// VDP
export const ADHERENCE_PATH = `${VESSEL_DETAILS_BASE_PATH}/adherence`;
export const PERFORMANCE_PATH = `${VESSEL_DETAILS_BASE_PATH}/performance`;
export const NOONS_PATH = `${VESSEL_DETAILS_BASE_PATH}/noons`;
export const REPORTS_PATH = `${VESSEL_DETAILS_BASE_PATH}/reports`;
export const MLV_REPORTS_PATH = `${REPORTS_PATH}/:mlvUuid`;

export const VOYAGE_BASE_PATH = `${VESSEL_DETAILS_BASE_PATH}/voyage/:voyageUuid`;

// The Route Explorer can be access with or without a voyage defined in the URL.
export const ROUTE_EXPLORER_PATH = `${VESSEL_DETAILS_BASE_PATH}/route-explorer`;
export const ROUTE_EXPLORER_VOYAGE_PATH = `${VOYAGE_BASE_PATH}/route-explorer`;
export const ROUTE_EXPLORER_PATHS = [
  ROUTE_EXPLORER_PATH,
  ROUTE_EXPLORER_VOYAGE_PATH,
];

export const ROUTE_EXPLORER_ROUTE_DETAILS_PATH = `${ROUTE_EXPLORER_PATH}/:routeUuid`;
export const ROUTE_EXPLORER_VOYAGE_ROUTE_DETAILS_PATH = `${ROUTE_EXPLORER_VOYAGE_PATH}/:routeUuid`;
export const ROUTE_EXPLORER_ROUTE_DETAILS_PATHS = [
  ROUTE_EXPLORER_ROUTE_DETAILS_PATH,
  ROUTE_EXPLORER_VOYAGE_ROUTE_DETAILS_PATH,
];

export const ROUTE_EXPLORER_EDIT_ROUTE_PATH = `${ROUTE_EXPLORER_ROUTE_DETAILS_PATH}/edit`;
export const ROUTE_EXPLORER_VOYAGE_EDIT_ROUTE_PATH = `${ROUTE_EXPLORER_VOYAGE_ROUTE_DETAILS_PATH}/edit`;
export const ROUTE_EXPLORER_EDIT_ROUTE_PATHS = [
  ROUTE_EXPLORER_EDIT_ROUTE_PATH,
  ROUTE_EXPLORER_VOYAGE_EDIT_ROUTE_PATH,
];

export const ROUTE_EXPLORER_ROUTE_COMPARISON_PATH = `${ROUTE_EXPLORER_VOYAGE_PATH}/compare`;

export const ALL_ROUTE_EXPLORER_SIDEBAR_PATHS = [
  ...ROUTE_EXPLORER_EDIT_ROUTE_PATHS,
  ...ROUTE_EXPLORER_ROUTE_DETAILS_PATHS,
  ...ROUTE_EXPLORER_PATHS,
];

// Voyage screen
export const ALL_VOYAGES_PATH = `${VESSEL_DETAILS_BASE_PATH}/voyages`;
export const CREATE_VOYAGE_PATH = `${VESSEL_DETAILS_BASE_PATH}/new-voyage`;
export const IMPORT_VOYAGE_PATH = `${VOYAGE_BASE_PATH}/import`;
export const EDIT_VOYAGE_PATH = `${VOYAGE_BASE_PATH}/edit`;
export const AREA_CONSTRAINT_PATH = `${VOYAGE_BASE_PATH}/area-constraints`;
export const DASHBOARD_PATH = `${VOYAGE_BASE_PATH}/dashboard`;
export const OPTIMIZATION_PATH = `${VOYAGE_BASE_PATH}/optimization`;
export const OPTIMIZATION_HISTORY_PATH = `${VOYAGE_BASE_PATH}/optimization-history`;
export const SEAKEEPING_PATH = `${VOYAGE_BASE_PATH}/seakeeping`;
export const ALL_ROUTES_PATH = `${VOYAGE_BASE_PATH}/routes`;
export const ROUTE_PATH = `${ALL_ROUTES_PATH}/:routeUuid`;

export const EDIT_ROUTE_PATH = `${ROUTE_PATH}/edit`;

export const OLD_VOYAGE_PATH = `/voyage/:voyageUuid`;
export const OLD_ALL_ROUTES_PATH = `${OLD_VOYAGE_PATH}/routes`;
export const OLD_ROUTE_PATH = `${OLD_VOYAGE_PATH}/routes/:routeUuid`;
export const OLD_ALL_VOYAGES_PATH = `${VOYAGE_BASE_PATH}/all`;
export const OLD_CREATE_VOYAGE_PATH = `${VOYAGE_BASE_PATH}/new-voyage`;

export const SETTINGS_PATHS = [
  SETTINGS_PATH,
  GLOBAL_AREA_CONSTRAINT_SETTINGS_PATH,
  ORGANIZATION_AREA_CONSTRAINT_SETTINGS_PATH,
];
// universally allowed paths that require a voyage screen
export const BASE_VOYAGE_SIDEBAR_PATHS = [
  `${ROUTE_PATH}?`,
  `${EDIT_ROUTE_PATH}/`,
  `${EDIT_VOYAGE_PATH}/`,
  `${IMPORT_VOYAGE_PATH}/`,
  `${VOYAGE_BASE_PATH}/`,
  `${CREATE_VOYAGE_PATH}/`,
  `${ALL_VOYAGES_PATH}/`,
  // DEPRECATED: but we keep them around so we can redirect to them
  `${OLD_ALL_VOYAGES_PATH}/`,
  `${OLD_CREATE_VOYAGE_PATH}/`,
  `${OLD_VOYAGE_PATH}/`,
  `${OLD_VOYAGE_PATH}?`,
  `${OLD_ROUTE_PATH}?`,
];
export const BASE_VOYAGE_SCREEN_PATHS = [
  ...BASE_VOYAGE_SIDEBAR_PATHS,
  `${DASHBOARD_PATH}/`,
  `${SEAKEEPING_PATH}/`,
  `${OPTIMIZATION_PATH}/`,
  `${OPTIMIZATION_HISTORY_PATH}/`,
  `${AREA_CONSTRAINT_PATH}/`,
];
export const VESSEL_DETAILS_PATHS = [
  VESSEL_DETAILS_BASE_PATH,
  ADHERENCE_PATH,
  NOONS_PATH,
  PERFORMANCE_PATH,
  REPORTS_PATH,
  MLV_REPORTS_PATH,
];

export const VOYAGE_PLAN_PATH = `${VESSEL_DETAILS_BASE_PATH}/plan/:voyagePlanUuid`;

export const ROUTES_TO_COMPARE_QUERY_PARAM = "routesToCompare";
export const FLEET_VIEW_TAB_QUERY_PARAM = "fleetViewTab";
export const RPM_ADHERENCE_UUID_QUERY_PARAM = "rpmAdherenceUuid";
export const SIMULATED_HISTORICAL_TIME_QUERY_PARAM = "simulatedHistoricalTime";
export const VOYAGE_PLAN_QUERY_PARAM = "plan";

/**
 * Returns the voyageUuid and vesselUuid from the `/vessel/:vesselUuid/voyage/:voyageUuid`/ URL.
 * Uses useRouteMatch to be independent from the react router.
 *
 * This is the same problem as discussed in https://github.com/wavespotter/tell-tale/pull/204
 */
export const useWayfinderUrlUuids = (): UrlParams => {
  const match = useRouteMatch<UrlParams>([
    ...ROUTE_EXPLORER_EDIT_ROUTE_PATHS,
    ROUTE_EXPLORER_ROUTE_COMPARISON_PATH,
    ...ROUTE_EXPLORER_ROUTE_DETAILS_PATHS,
    ...ROUTE_EXPLORER_PATHS,
    OLD_ROUTE_PATH,
    ROUTE_PATH,
    OLD_VOYAGE_PATH,
    VOYAGE_BASE_PATH,
    VESSEL_DETAILS_BASE_PATH,
    MLV_REPORTS_PATH,
  ]);
  return {
    voyageUuid: match?.params.voyageUuid,
    vesselUuid: match?.params.vesselUuid,
    routeUuid: match?.params.routeUuid,
    mlvUuid: match?.params.mlvUuid,
  };
};

/**
 * Returns the standard Wayfinder URL parameters @see UrlParams and any params
 * that are passed as the generic argument.
 */
const useWayfinderUrl = <
  P extends { [K in keyof P]?: string | undefined } = {}
>(): UseWayfinderUrlResult & P => {
  // FIXME intersecting the return type with the generic in the definition of the component lets you presume this function will return anything you want
  // WARNING: Should you get an error along the lines of: "Cannot read property 'match' of undefined" when developing storybook stories, and you traced this here,
  // then you need to make sure to pass 'DomRouterDecorator' as the last decorator of the story.
  // We use both useParams and useRouteMatch to get the parameters from the URL since the former will fail if we are called from somewhere that is not rendered
  // underneath a React <Route ...> component.
  const params = useParams<P>();
  const {
    vesselUuid: vesselUuidFromParams,
    voyageUuid: voyageUuidFromParams,
    routeUuid: routeUuidFromParams,
    mlvUuid: mlvUuidFromParams,
  } = useParams<UrlParams>();
  const {
    vesselUuid: vesselUuidFromMatch,
    voyageUuid: voyageUuidFromMatch,
    routeUuid: routeUuidFromMatch,
    mlvUuid: mlvUuidFromMatch,
  } = useWayfinderUrlUuids();

  const vesselUuidFromUrl = vesselUuidFromParams || vesselUuidFromMatch;
  const voyageUuidFromUrl =
    voyageUuidFromParams || voyageUuidFromMatch || undefined;
  const routeUuidFromUrl = routeUuidFromParams || routeUuidFromMatch;
  const mlvUuidFromUrl = mlvUuidFromParams || mlvUuidFromMatch;

  const history = useHistory();
  const showFleetView = useFleetViewVisibility();

  const [query, setQuery] = useQueryParams({
    routesToCompare: StringParam,
    plotQuantity: withDefault(StringParam, plotQuantityDefault),
    simulatedHistoricalTime: withDefault(
      StringParam,
      simulatedHistoricalTimeDefault
    ),
    fleetViewTab: StringParam,
    rpmAdherenceUuid: StringParam,
  });
  const queryRef = useRef(query);
  queryRef.current = query;

  const [weatherQuery, setWeatherQuery] = useQueryParams({
    showTropicalStorms: withDefault(
      BooleanParam,
      weatherMapLayerShowTropicalStormsDefault
    ),
    showNauticalCharts: withDefault(
      BooleanParam,
      weatherMapLayerShowNauticalChartsDefault
    ),
    raster: withDefault(StringParam, weatherMapLayerRasterDefault),
    vector: withDefault(StringParam, weatherMapLayerVectorDefault),
    showPressure: withDefault(BooleanParam, weatherMapLayerPressureDefault),
    showIceLayers: withDefault(
      BooleanParam,
      weatherMapLayerShowIceLayersDefault
    ),
  });

  const routeUuidsToCompare = useMemo(
    () =>
      query.routesToCompare
        ? query.routesToCompare.split(",").map((s) => s.trim())
        : [],
    [query.routesToCompare]
  );

  const getUrl = useCallback(
    <T extends WayfinderUrlParam<WayfinderUrl>>(
      path: WayfinderUrl,
      options?: { params?: T; replace?: boolean }
    ) => {
      const voyageParams = options?.params
        ? (options.params as WayfinderUrlParam<"voyage">)
        : undefined;
      const currentVesselUuid = voyageParams?.vesselUuid || vesselUuidFromUrl;
      const currentVoyageUuid = voyageParams?.voyageUuid || voyageUuidFromUrl;
      const vesselRoot = `/vessel/${currentVesselUuid}`;
      const voyageRoot = `/vessel/${currentVesselUuid}/voyage/${currentVoyageUuid}`;
      const url = match(path)
        .with("voyage", () => voyageRoot)
        .with("all-voyages", () => {
          return `${vesselRoot}/voyages`;
        })
        .with("new-voyage", () => {
          return `${vesselRoot}/new-voyage`;
        })
        .with("edit-voyage", () => {
          return `${voyageRoot}/edit`;
        })
        .with("route-import", () => {
          return `${voyageRoot}/import`;
        })
        .with(
          "optimization",
          "optimization-history",
          "dashboard",
          "seakeeping",
          "area-constraints",
          "layers",
          () => {
            const layerParams = options?.params
              ? (options.params as WayfinderUrlParam<"layers">)
              : undefined;
            const vesselUuid = layerParams?.vesselUuid || currentVesselUuid;
            const voyageUuid = layerParams?.voyageUuid || currentVoyageUuid;
            return `/vessel/${vesselUuid}/voyage/${voyageUuid}/${path}`;
          }
        )
        .with("route-explorer", () => {
          const params = options?.params
            ? (options?.params as WayfinderUrlParam<"route-explorer">)
            : undefined;
          const vesselUuid = params?.vesselUuid ?? currentVesselUuid;
          const voyageUuid = params?.voyageUuid ?? voyageUuidFromUrl;
          if (voyageUuid) {
            return `/vessel/${vesselUuid}/voyage/${voyageUuid}/route-explorer`;
          } else {
            return `/vessel/${vesselUuid}/route-explorer`;
          }
        })
        .with("route-explorer-edit", () => {
          const params = options?.params
            ? (options?.params as WayfinderUrlParam<"route-explorer-edit">)
            : undefined;
          const vesselUuid = params?.vesselUuid ?? currentVesselUuid;
          const voyageUuid = params?.voyageUuid ?? voyageUuidFromUrl;
          const routeUuid = params?.routeUuid ?? routeUuidFromUrl;
          if (voyageUuid) {
            return `/vessel/${vesselUuid}/voyage/${voyageUuid}/route-explorer/${routeUuid}/edit`;
          } else {
            return `/vessel/${vesselUuid}/route-explorer/${routeUuid}/edit`;
          }
        })
        .with("route-explorer-detail", () => {
          const params = options?.params
            ? (options.params as WayfinderUrlParam<"route-explorer-detail">)
            : undefined;
          const vesselUuid = params?.vesselUuid ?? currentVesselUuid;
          const voyageUuid = params?.voyageUuid ?? voyageUuidFromUrl;
          const routeUuid = params?.routeUuid ?? routeUuidFromUrl;
          if (voyageUuid) {
            return `/vessel/${vesselUuid}/voyage/${voyageUuid}/route-explorer/${routeUuid}`;
          } else {
            return `/vessel/${vesselUuid}/route-explorer/${routeUuid}`;
          }
        })
        .with("voyage-print", () => {
          const printParams = options?.params
            ? (options.params as WayfinderUrlParam<"voyage-print">)
            : undefined;
          const vesselUuid = printParams?.vesselUuid || currentVesselUuid;
          const voyageUuid = printParams?.voyageUuid || currentVoyageUuid;
          return `/vessel/${vesselUuid}/voyage/${voyageUuid}${PRINT_PATH}`;
        })
        .with("routes", () => {
          const routeParams = options?.params
            ? (options.params as WayfinderUrlParam<"routes">)
            : undefined;
          const vesselUuid = routeParams?.vesselUuid || currentVesselUuid;
          const voyageUuid = routeParams?.voyageUuid || currentVoyageUuid;
          return `/vessel/${vesselUuid}/voyage/${voyageUuid}/routes`;
        })
        .with("route-detail", () => {
          const routeParams = options?.params
            ? (options.params as WayfinderUrlParam<"route-detail">)
            : undefined;
          const vesselUuid = routeParams?.vesselUuid || currentVesselUuid;
          const voyageUuid = routeParams?.voyageUuid || currentVoyageUuid;
          const routeUuid = routeParams?.routeUuid ?? routeUuidFromUrl;
          return `/vessel/${vesselUuid}/voyage/${voyageUuid}/routes/${routeUuid}`;
        })
        .with("route-edit", () => {
          const routeParams = options?.params
            ? (options.params as WayfinderUrlParam<"route-detail">)
            : undefined;
          const vesselUuid = routeParams?.vesselUuid || currentVesselUuid;
          const voyageUuid = routeParams?.voyageUuid || currentVoyageUuid;
          const routeUuid = routeParams?.routeUuid ?? routeUuidFromUrl;
          return `/vessel/${vesselUuid}/voyage/${voyageUuid}/routes/${routeUuid}/edit`;
        })
        .with("vessel", () => {
          const vesselParams = options?.params
            ? (options.params as WayfinderUrlParam<"vessel">)
            : undefined;
          const vesselUuid = vesselParams?.vesselUuid || currentVesselUuid;
          return `/vessel/${vesselUuid}`;
        })
        .with("vessel-adherence", () => {
          const adherenceParams = options?.params
            ? (options.params as WayfinderUrlParam<"vessel-adherence">)
            : undefined;
          const vesselUuid = adherenceParams?.vesselUuid || currentVesselUuid;
          return `/vessel/${vesselUuid}/adherence`;
        })
        .with("vessel-performance", () => {
          const performanceParams = options?.params
            ? (options.params as WayfinderUrlParam<"vessel-performance">)
            : undefined;
          const vesselUuid = performanceParams?.vesselUuid || currentVesselUuid;
          return `/vessel/${vesselUuid}/performance`;
        })
        .with("vessel-noons", () => {
          const noonsParams = options?.params
            ? (options.params as WayfinderUrlParam<"vessel-noons">)
            : undefined;
          const vesselUuid = noonsParams?.vesselUuid || currentVesselUuid;
          return `/vessel/${vesselUuid}/noons`;
        })
        .with("vessel-reports", () => {
          const reportsParams = options?.params
            ? (options.params as WayfinderUrlParam<"vessel-reports">)
            : undefined;
          const vesselUuid = reportsParams?.vesselUuid || currentVesselUuid;
          return `/vessel/${vesselUuid}/reports`;
        })
        .with("vessel-voyage-report", () => {
          const reportParams = options?.params
            ? (options.params as WayfinderUrlParam<"vessel-voyage-report">)
            : undefined;
          const vesselUuid = reportParams?.vesselUuid || currentVesselUuid;
          const mlvUuid = reportParams?.mlvUuid || mlvUuidFromUrl;
          return `/vessel/${vesselUuid}/reports/${mlvUuid}`;
        })
        .with("fleet", () => {
          return showFleetView ? FLEET_VIEW_PATH : "";
        })
        .with("settings-organization-area-constraints", () => {
          return ORGANIZATION_AREA_CONSTRAINT_SETTINGS_PATH;
        })
        .with("settings-global-area-constraints", () => {
          return GLOBAL_AREA_CONSTRAINT_SETTINGS_PATH;
        })
        .with("settings", () => {
          return SETTINGS_PATH;
        })
        .exhaustive();
      return `${url}${window.location.search}`;
    },
    [
      voyageUuidFromUrl,
      vesselUuidFromUrl,
      routeUuidFromUrl,
      mlvUuidFromUrl,
      showFleetView,
    ]
  );

  /**
   * Sets the query object with only values that are not equal to their defaults.
   */
  const setManagedQuery = useCallback(
    (
      newQuery: Partial<WayfinderQuery>,
      updateType: UrlUpdateType = "replaceIn"
    ) => {
      let result = { ...queryRef.current };
      if (newQuery.routesToCompare) {
        result = {
          ...result,
          routesToCompare: newQuery.routesToCompare,
        };
      } else if (newQuery.routesToCompare === "") {
        // There is no routes to compare anymore, delete it.
        delete result.routesToCompare;
      }
      if (newQuery.plotQuantity) {
        result = {
          ...result,
          plotQuantity: newQuery.plotQuantity,
        };
      }
      if (newQuery.simulatedHistoricalTime) {
        result = {
          ...result,
          simulatedHistoricalTime: newQuery.simulatedHistoricalTime,
        };
      }

      if (newQuery.rpmAdherenceUuid) {
        result = {
          ...result,
          rpmAdherenceUuid: newQuery.rpmAdherenceUuid,
        };
      } else {
        delete result.rpmAdherenceUuid;
      }

      // the following lines compare the newly created object against the defaults and only sets the properties
      // that do not equal their defaults.
      setQuery(
        {
          routesToCompare: result.routesToCompare ?? undefined,
          plotQuantity:
            result.plotQuantity !== plotQuantityDefault
              ? result.plotQuantity
              : undefined,
          simulatedHistoricalTime:
            result.simulatedHistoricalTime !== simulatedHistoricalTimeDefault
              ? result.simulatedHistoricalTime
              : undefined,
          rpmAdherenceUuid: result.rpmAdherenceUuid,
        },
        updateType
      );
    },
    [setQuery]
  );

  /**
   * Sets the weather layers query object with only values that are not equal to their defaults.
   */
  const setManagedWeatherQuery = useCallback(
    (
      newQuery: Partial<WayfinderWeatherQuery>,
      updateType: UrlUpdateType = "replaceIn"
    ) => {
      let result = { ...weatherQuery };
      if (
        newQuery.raster ||
        newQuery.vector ||
        newQuery.showSpotters !== undefined ||
        newQuery.showPressure !== undefined ||
        newQuery.showTropicalStorms !== undefined ||
        newQuery.showNauticalCharts !== undefined ||
        newQuery.showIceLayers !== undefined
      ) {
        result = {
          ...result,
          raster: newQuery.raster || weatherMapLayerRasterDefault,
          vector: newQuery.vector || weatherMapLayerVectorDefault,
          showPressure:
            newQuery.showPressure !== undefined
              ? newQuery.showPressure
              : weatherMapLayerPressureDefault,
          showTropicalStorms:
            newQuery.showTropicalStorms !== undefined
              ? newQuery.showTropicalStorms
              : weatherMapLayerShowTropicalStormsDefault,
          showNauticalCharts:
            newQuery.showNauticalCharts !== undefined
              ? newQuery.showNauticalCharts
              : weatherMapLayerShowNauticalChartsDefault,
          showIceLayers:
            newQuery.showIceLayers !== undefined
              ? newQuery.showIceLayers
              : weatherMapLayerShowIceLayersDefault,
        };
      }
      // the following lines compare the newly created object against the defaults and only sets the properties
      // that do not equal their defaults.
      setWeatherQuery(
        {
          raster:
            result.raster !== weatherMapLayerRasterDefault
              ? (result.raster as string)
              : undefined,
          vector:
            result.vector !== weatherMapLayerVectorDefault
              ? (result.vector as string)
              : undefined,
          showPressure:
            result.showPressure !== weatherMapLayerPressureDefault
              ? result.showPressure
              : undefined,
          showTropicalStorms:
            result.showTropicalStorms !==
            weatherMapLayerShowTropicalStormsDefault
              ? result.showTropicalStorms
              : undefined,
          showNauticalCharts:
            result.showNauticalCharts !==
            weatherMapLayerShowNauticalChartsDefault
              ? result.showNauticalCharts
              : undefined,
          showIceLayers:
            result.showIceLayers !== weatherMapLayerShowIceLayersDefault
              ? result.showIceLayers
              : undefined,
        },
        updateType
      );
    },
    [weatherQuery, setWeatherQuery]
  );

  const setRoutesToCompare = useCallback(
    (newRouteUuids: string[]) => {
      if (query.routesToCompare !== newRouteUuids.join(",")) {
        let routesToCompare: string | undefined = undefined;
        if (newRouteUuids.length) {
          routesToCompare = uniq(newRouteUuids).join(",");
        } else {
          // remove the routesToCompare
          routesToCompare = "";
        }
        setManagedQuery({ routesToCompare });
      }
    },
    [query.routesToCompare, setManagedQuery]
  );

  const setSimulatedHistoricalTime = useCallback(
    (simulatedHistoricalTime: string) =>
      setManagedQuery({ simulatedHistoricalTime }),
    [setManagedQuery]
  );

  const setRpmAdherenceUuid = useCallback(
    (rpmAdherenceUuid: string | undefined) =>
      setManagedQuery({ rpmAdherenceUuid }),
    [setManagedQuery]
  );

  const setWeatherMapLayers = useCallback(
    (newMapLayers: WeatherMapLayers) => {
      setManagedWeatherQuery({
        ...newMapLayers,
      });
    },
    [setManagedWeatherQuery]
  );

  const setUrl = useCallback(
    <T extends WayfinderUrlParam<WayfinderUrl>>(
      path: WayfinderUrl,
      options?: { params?: T; replace?: boolean }
    ) => {
      const navigate = options?.replace ? history.replace : history.push;
      const paramsAsVoyageActionParams = options?.params as
        | WayfinderUrlParam<"voyage">
        | undefined;
      // Set the routes to compare _before_ getting the URL, otherwise `getUrl` will overwrite our
      // new params with whatever was there before.
      if (paramsAsVoyageActionParams?.routesToCompare) {
        const routesToCompare = paramsAsVoyageActionParams?.routesToCompare;
        setRoutesToCompare(routesToCompare);
      }

      const url = getUrl(path, options);
      if (url) {
        navigate(url);
      }
    },
    [history.replace, history.push, getUrl, setRoutesToCompare]
  );

  const setWeatherPlotQuantity = useCallback(
    (newQuantity: PlotQuantities) => {
      setQuery({ plotQuantity: newQuantity });
    },
    [setQuery]
  );

  const simulatedHistoricalTime = useMemo(
    () =>
      query.simulatedHistoricalTime !== ""
        ? new Date(query.simulatedHistoricalTime)
        : undefined,
    [query.simulatedHistoricalTime]
  );

  const fleetViewTab = useMemo(() => {
    if (FLEET_VIEW_TABS.find((tab) => tab === query.fleetViewTab)) {
      return query.fleetViewTab as FleetViewTab;
    }
    return undefined;
  }, [query.fleetViewTab]);

  const setFleetViewTab = useCallback(
    (tab: FleetViewTab) => setQuery({ [FLEET_VIEW_TAB_QUERY_PARAM]: tab }),
    [setQuery]
  );

  return useMemo(
    () => ({
      vesselUuid: vesselUuidFromUrl,
      voyageUuid: voyageUuidFromUrl,
      routeUuid: routeUuidFromUrl,
      mlvUuid: mlvUuidFromUrl,
      routeUuidsToCompare,
      ...params,

      weatherMapLayers: {
        raster: weatherQuery.raster as RasterWeatherLayer,
        vector: weatherQuery.vector as VectorWeatherLayer,
        showPressure: weatherQuery.showPressure,
        showTropicalStorms: weatherQuery.showTropicalStorms,
        showNauticalCharts: weatherQuery.showNauticalCharts,
        showIceLayers: weatherQuery.showIceLayers,
      },
      weatherPlotQuantity: query.plotQuantity as PlotQuantities,
      simulatedHistoricalTime,
      getUrl,
      setUrl,
      setRoutesToCompare,
      setWeatherMapLayers,
      setWeatherPlotQuantity,
      setSimulatedHistoricalTime,
      fleetViewTab,
      setFleetViewTab,
      setRpmAdherenceUuid,
      rpmAdherenceUuid: query.rpmAdherenceUuid ?? undefined,
    }),
    [
      vesselUuidFromUrl,
      voyageUuidFromUrl,
      routeUuidFromUrl,
      mlvUuidFromUrl,
      routeUuidsToCompare,
      params,
      weatherQuery.raster,
      weatherQuery.vector,
      weatherQuery.showPressure,
      weatherQuery.showTropicalStorms,
      weatherQuery.showNauticalCharts,
      weatherQuery.showIceLayers,
      query.plotQuantity,
      query.rpmAdherenceUuid,
      simulatedHistoricalTime,
      getUrl,
      setUrl,
      setRoutesToCompare,
      setWeatherMapLayers,
      setWeatherPlotQuantity,
      setSimulatedHistoricalTime,
      fleetViewTab,
      setFleetViewTab,
      setRpmAdherenceUuid,
    ]
  );
};

export { useWayfinderUrl };
