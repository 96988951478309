import {
  Box,
  InputBaseComponentProps,
  InputLabel,
  InputProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTextFieldStyles } from "./use-wayfinder-text-field-styles";

export type WayfinderTextFieldProps = Omit<TextFieldProps, "variant"> & {
  variant: "primary" | "secondary" | "tertiary";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;

  /**
   * Adds delay to calling onChange. Uses local state to keep track
   * of displayed text.
   */
  onChangeDebounce?: number;

  textAlign?: "left" | "center" | "right";
};

const variantMap = new Map<
  "primary" | "secondary" | "tertiary",
  "standard" | "filled" | "outlined"
>([
  ["primary", "standard"],
  ["secondary", "outlined"],
  ["tertiary", "filled"],
]);

const INPUT_LABEL_PROPS = { shrink: true };

export const WayfinderTextField: React.FC<WayfinderTextFieldProps> = ({
  value,
  label,
  variant,
  startIcon,
  endIcon,
  InputProps,
  onChange,
  onChangeDebounce,
  textAlign = "center",
  ...props
}) => {
  const { classes } = useTextFieldStyles();
  const inputBaseProps: InputBaseComponentProps = useMemo(() => {
    if (textAlign === "left") {
      return { style: { textAlign: "left", paddingLeft: "12px" } };
    }
    if (textAlign === "right") {
      return { style: { textAlign: "right" } };
    }
    return { style: { textAlign: "center" } };
  }, [textAlign]);

  const inputProps: InputProps = useMemo(() => {
    const props: InputProps = {
      ...InputProps,
      classes: {
        input: `${classes.fontStyle} ${
          variant === "tertiary" ? classes.filledInputField : ""
        }`,
        ...InputProps?.classes,
      },
    };

    // Only set the endAdornment if it's explicitly passed in, otherwise just let it propogate
    // through the InputProps. This allows our custom text field to work like a TextField for
    // other parts of the MUI component ecosystem, for example, the DateTimePicker, which passes
    // InputProps through to our custom field.
    if (endIcon) {
      props.endAdornment = endIcon;
    }

    return props;
  }, [
    InputProps,
    endIcon,
    classes.fontStyle,
    classes.filledInputField,
    variant,
  ]);

  const [localValue, setLocalValue] = useState(value);

  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const delayOnChange = useCallback(
    (valueChange: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        if (onChange) {
          onChange(valueChange);
        }
      }, onChangeDebounce);

      setLocalValue(valueChange.target.value);
    },
    [onChange, onChangeDebounce]
  );

  return (
    <Box display="flex" alignItems="flex-end">
      {startIcon && (
        <div className={classes.startIconContainer}>{startIcon}</div>
      )}
      <span className={props.fullWidth ? classes.fullWidth : undefined}>
        {variant === "tertiary" && label && (
          <InputLabel className={classes.formLabel} {...props.InputLabelProps}>
            {label}
          </InputLabel>
        )}
        <TextField
          variant={variantMap.get(variant)}
          label={variant === "tertiary" ? null : label}
          InputProps={inputProps}
          InputLabelProps={
            variant === "tertiary" ? undefined : INPUT_LABEL_PROPS
          }
          value={(onChangeDebounce ? localValue : value) || ""}
          onChange={onChangeDebounce ? delayOnChange : onChange}
          inputProps={inputBaseProps}
          {...props}
        />
      </span>
    </Box>
  );
};
