import { rtzRouteToJrcCsvString } from "helpers/importExport/route/jrc/jrcRouteFileFormat";
import { routeToTokimecCsvString } from "helpers/importExport/route/tokimec/tokimecRouteFileFormat";
import { rtzRouteToXmlString } from "helpers/importExport/route/rtz/rtzXmlRouteFileFormat";
import { rtzRouteToFurunoTxtString } from "helpers/importExport/route/furunoSimple/furunoSimpleFileFormat";
import { Route } from "shared-types/RouteTypes";
import { routeToTotemCsvString } from "helpers/importExport/route/totemCsv/totemCsvRouteFileFormat";
import {
  getRoutingGuidanceScheduleElements,
  getRoutingGuidanceWaypoints,
} from "helpers/routes";
import { rtzRouteToNacos1100CsvString } from "helpers/importExport/route/nacos1100/nacos1100RouteFileFormat";
import { RouteExportFormat } from "../../modals/RouteExportForm";

const exportFunctions: Record<
  RouteExportFormat,
  | typeof rtzRouteToJrcCsvString
  | typeof routeToTokimecCsvString
  | typeof rtzRouteToXmlString
  | typeof rtzRouteToFurunoTxtString
  | typeof routeToTotemCsvString
  | typeof rtzRouteToNacos1100CsvString
> = {
  JRC_CSV: rtzRouteToJrcCsvString,
  Tokimec_CSV: routeToTokimecCsvString,
  RTZ_XML: rtzRouteToXmlString,
  Furuno_Simple: rtzRouteToFurunoTxtString,
  Totem_CSV: routeToTotemCsvString,
  Nacos1100_CSV: rtzRouteToNacos1100CsvString,
};
export const getStringFromRoute = ({
  route,
  format,
  options,
}: {
  route: Route;
  format: RouteExportFormat;
  options?: {
    includeCalculatedScheduleInRtzExports?: boolean;
    enableLegacyRtzXmlFormatting?: boolean;
  };
}): string => {
  const waypoints = getRoutingGuidanceWaypoints(route, {
    includeMultipleDriftPoints: true,
  });

  const routeForExport: Route = {
    ...route,
    waypoints: {
      ...route.waypoints,
      waypoints,
    },
    schedules: {
      schedules: route.schedules?.schedules?.map((schedule) => {
        const filteredScheduleElements =
          (schedule.manual?.scheduleElements &&
            getRoutingGuidanceScheduleElements(
              schedule.manual?.scheduleElements,
              { includeMultipleDriftPoints: true }
            )) ??
          [];
        return {
          ...schedule,
          manual: {
            ...schedule.manual,
            scheduleElements: filteredScheduleElements,
          },
        };
      }),
    },
  };
  return format === "RTZ_XML"
    ? exportFunctions[format](
        routeForExport,
        !!options?.enableLegacyRtzXmlFormatting,
        !!options?.includeCalculatedScheduleInRtzExports
      )
    : exportFunctions[format](routeForExport);
};
