import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { v4 as uuidGen } from "uuid";
import _, { isEqual, isNil, round } from "lodash";
import {
  CharterType,
  MultiLegVoyageDto,
  RoutingControlsType,
  VoyageDto,
  VoyageStatusV2,
} from "@sofarocean/wayfinder-typescript-client";

import { RoutingControlsUiState } from "components/routes/Import/use-route-import";
import { getEtaEtdMenuValueFromTimes } from "components/sidebar/voyage-creation/helpers/arrivalWindowHelpers";
import { metersPerSecondToKnots } from "helpers/units";
import { Port } from "shared-types/PortTypes";
import { Route } from "shared-types/RouteTypes";
import { LoadConditionEnum } from "shared-types/LoadConditionTypes";
import { useShowCharterTypeSelector } from "shared-hooks/useShowCharterTypeSelector";
import { PortContext } from "contexts/PortContext";
import { createTypedObjectFromEntries } from "helpers/fromEntries";

export type LegInEditContext = RoutingControlsUiState & {
  voyageUuid: string;
  loadCondition: LoadConditionEnum;
  charterType: CharterType | null;
  etd?: string;
  eta?: string;
  departurePort: Port | null;
  arrivalPort: Port | null;
  overrideDeparturePortEuEtsStatus?: boolean | null;
  overrideDestinationPortEuEtsStatus?: boolean | null;
  fileName: string;
  routeUuid?: string;
  pregeneratedRoute: Route | null;
  importedRoute: Route | null;
  shouldRequestRoute: boolean;
  shouldKeepExistingRoute: boolean;
  status?: VoyageStatusV2;
  departurePointTimezone?: string | null;
  routingControlsType: RoutingControlsType | null;
};

export const defaultVoyageLeg: LegInEditContext = {
  voyageUuid: "",
  loadCondition: LoadConditionEnum.laden,
  charterType: CharterType.VoyageCharter,
  etd: "",
  eta: "",
  rtaStartTime: undefined,
  rtaEndTime: undefined,
  departurePort: null,
  arrivalPort: null,
  overrideDeparturePortEuEtsStatus: null,
  overrideDestinationPortEuEtsStatus: null,
  fileName: "",
  routeUuid: undefined,
  pregeneratedRoute: null,
  importedRoute: null,
  shouldRequestRoute: false,
  shouldKeepExistingRoute: false,
  status: undefined,
  departurePointTimezone: undefined,
  routingControlsType: RoutingControlsType.RoutingControlsConstraints,
};

// FIXME this pattern of comparison may be overcomplicated
export const getLegsWithInfo = (legs: LegInEditContext[]) => {
  return legs.filter(
    (leg) =>
      !_.isEqual(leg, defaultVoyageLeg) &&
      (!_.isNil(leg.routeUuid) || !_.isNil(leg.pregeneratedRoute))
  );
};

export const areLegsEqual = (
  leg1: LegInEditContext,
  leg2: LegInEditContext
) => {
  for (const key of Object.keys(leg1)) {
    const leg1Value = leg1[key];
    const leg2Value = leg2[key];
    // Consider null and undefined the same value
    if (isNil(leg1Value) && isNil(leg2Value)) {
      continue;
    }
    if (!isEqual(leg1Value, leg2Value)) {
      return false;
    }
  }
  return true;
};

export const findPort = (
  portName: string | null | undefined,
  ports: Port[]
) => {
  if (isNil(portName)) {
    return null;
  }
  const port = ports.find((p) => p.displayName === portName);
  return isNil(port) ? { displayName: portName } : port;
};

export function getInitialVoyageFromExisting(
  multiLegVoyageToEdit: MultiLegVoyageDto,
  ports: Port[]
): LegInEditContext[] {
  return (
    multiLegVoyageToEdit.voyageLegs.map(
      ({
        arrivalWindows,
        etd,
        eta,
        activeRoute,
        isDesignatedLaden,
        charterType,
        departurePort,
        departurePortName,
        overrideDeparturePortEuEtsStatus,
        destinationPort,
        destinationPortName,
        overrideDestinationPortEuEtsStatus,
        uuid,
        statusV2,
        averageSpeed,
        instructedSpeed,
        maxDailyFoRate,
        maxDailyFuelRate,
        maxDailyDoGoRate,
        departurePointTimezone,
        routingControls,
        intendedSpeedMps,
        intendedArrivalWindows,
      }) => {
        const arrivalWindowTimes = arrivalWindows ? arrivalWindows[0] : null;
        const intendedArrivalWindowTimes = intendedArrivalWindows
          ? intendedArrivalWindows[0]
          : null;

        const {
          timeOfArrivalEnd,
          timeOfArrivalStart,
          windowMode,
        } = getEtaEtdMenuValueFromTimes(
          arrivalWindowTimes?.startTimestamp,
          arrivalWindowTimes?.endTimestamp
        );

        const {
          timeOfArrivalEnd: intendedTimeOfArrivalEnd,
          timeOfArrivalStart: intendedTimeOfArrivalStart,
          windowMode: intendedWindowMode,
        } = getEtaEtdMenuValueFromTimes(
          intendedArrivalWindowTimes?.startTimestamp,
          intendedArrivalWindowTimes?.endTimestamp
        );

        const leg: LegInEditContext = {
          voyageUuid: uuid,
          arrivalWindowType: windowMode,
          intendedArrivalWindowType: intendedWindowMode,
          rtaEndTime: timeOfArrivalEnd ?? undefined,
          rtaStartTime: timeOfArrivalStart ?? undefined,
          intendedRtaEndTime: intendedTimeOfArrivalEnd ?? undefined,
          intendedRtaStartTime: intendedTimeOfArrivalStart ?? undefined,
          etd: etd ?? undefined,
          eta: eta ?? undefined,
          departurePort: departurePort ?? findPort(departurePortName, ports),
          overrideDeparturePortEuEtsStatus,
          arrivalPort: destinationPort ?? findPort(destinationPortName, ports),
          overrideDestinationPortEuEtsStatus,
          fileName: "",
          routeUuid: activeRoute?.routeUuid ?? undefined,
          loadCondition: isDesignatedLaden
            ? LoadConditionEnum.laden
            : LoadConditionEnum.ballast,
          charterType: charterType,
          averageSpeedKts: averageSpeed // FIXME should we really be rounding this here?
            ? round(metersPerSecondToKnots(averageSpeed), 2)
            : undefined,
          instructedSpeedKts: instructedSpeed // FIXME should we really be rounding this here?
            ? round(metersPerSecondToKnots(instructedSpeed), 2)
            : undefined,
          intendedSpeedKts: intendedSpeedMps // FIXME should we really be rounding this here?
            ? round(metersPerSecondToKnots(intendedSpeedMps), 2)
            : undefined,
          maxDailyFoRate,
          maxDailyFuelRate,
          maxDailyDoGoRate,
          pregeneratedRoute: null,
          importedRoute: null,
          shouldRequestRoute: false,
          shouldKeepExistingRoute: false,
          status: statusV2,
          departurePointTimezone,
          routingControlsType: routingControls.__type,
        };
        return leg;
      }
    ) ?? []
  );
}

export const getRoutingControlsTypeMapsByUuid = (
  legs: VoyageDto[]
): Record<string, Partial<Record<CharterType, RoutingControlsType>>> => {
  const typeMapsByUuid = legs.map(
    (leg) =>
      [
        leg.uuid,
        {
          [leg.charterType]: leg.routingControls.__type,
        },
      ] as const
  );
  return createTypedObjectFromEntries(typeMapsByUuid);
};

export const useLegInEdit = (
  removeRoute: (routeUuid: string) => void,
  routingControlsTypesByCharterType:
    | Record<CharterType, RoutingControlsType>
    | undefined
) => {
  const [voyageUpdated, setVoyageUpdated] = useState(false);
  const [legs, setLegs] = useState<LegInEditContext[]>([]);
  const {
    showCharterTypeSelector,
    defaultCharterType,
  } = useShowCharterTypeSelector();

  const { ports } = useContext(PortContext);

  // a set of overrides per voyage that allows us to preserve the routong controls type on a voyage
  // even if it does not correspond to the defaults on the vessel
  // looks like { [voyageUuid]: { [charterType]: RoutingControlsType }... }
  const routingControlTypeOverrides = useRef<
    Record<string, Partial<Record<CharterType, RoutingControlsType>>>
  >({});
  // it is a ref because we do not want changing routingControlTypeOverrides to cause getRoutingControlsType to change its reference
  // if we let it, then addLeg changes, and then enterEditMode changes, and that causes the
  // initialization effect to fire, and that causes this hook to reset routingControlTypeOverrides (circular dep)

  const getRoutingControlsType = useCallback(
    (charterType: CharterType | null, voyageUuid: string | null) => {
      const mergedDict = {
        ...routingControlsTypesByCharterType,
        ...(voyageUuid && routingControlTypeOverrides.current[voyageUuid]),
      };
      return (charterType && mergedDict?.[charterType]) ?? null;
    },
    [routingControlTypeOverrides, routingControlsTypesByCharterType]
  );

  const addLeg = useCallback(() => {
    setLegs((prev) => {
      const charterType = showCharterTypeSelector
        ? null
        : defaultCharterType ?? defaultVoyageLeg.charterType;
      const newLeg: LegInEditContext[] = [
        ...prev,
        {
          ...defaultVoyageLeg,
          charterType,
          voyageUuid: uuidGen(),
          routingControlsType: getRoutingControlsType(charterType, null),
        },
      ];
      return newLeg;
    });
  }, [showCharterTypeSelector, defaultCharterType, getRoutingControlsType]);

  const removeLeg = useCallback(
    (legUuid: string) => {
      let removedLegUuid: string | undefined;
      const newLegs: LegInEditContext[] = [];
      legs.forEach((leg) => {
        if (leg.voyageUuid !== legUuid) newLegs.push(leg);
        else removedLegUuid = leg.routeUuid;
      });
      setLegs(newLegs);

      if (removedLegUuid) {
        removeRoute(removedLegUuid);
      }
      setVoyageUpdated(true);
    },
    [legs, removeRoute]
  );

  const getLeg = useCallback(
    (legUuid: string) => legs.find((leg) => leg.voyageUuid === legUuid),
    [legs]
  );

  const updateLeg = useCallback(
    (
      legUuid: string,
      changes:
        | Partial<LegInEditContext>
        | ((prev: LegInEditContext) => Partial<LegInEditContext>)
    ) => {
      setLegs((prev) => {
        const newLegs: LegInEditContext[] = [
          ...prev.map((leg) => {
            const newLeg =
              leg.voyageUuid === legUuid
                ? {
                    ...leg,
                    ...(typeof changes === "function" ? changes(leg) : changes),
                  }
                : leg;
            newLeg.routingControlsType = getRoutingControlsType(
              newLeg.charterType,
              leg.voyageUuid
            );
            return newLeg;
          }),
        ];
        return newLegs;
      });
      setVoyageUpdated(true);
    },
    [getRoutingControlsType]
  );

  const resetVoyage = useCallback(() => {
    setLegs([]);
  }, []);

  const initializeVoyageInEditFromExisting = useCallback(
    (multiLegVoyageToEdit: MultiLegVoyageDto) => {
      const initialLegs = getInitialVoyageFromExisting(
        multiLegVoyageToEdit,
        ports
      );
      setLegs(initialLegs);
      routingControlTypeOverrides.current = getRoutingControlsTypeMapsByUuid(
        multiLegVoyageToEdit.voyageLegs
      );
      return initialLegs;
    },
    [ports]
  );

  return useMemo(
    () => ({
      voyageUpdated,
      legs,
      addLeg,
      removeLeg,
      getLeg,
      updateLeg,
      resetVoyage,
      initializeVoyageInEditFromExisting,
      getRoutingControlsType,
    }),
    [
      voyageUpdated,
      legs,
      addLeg,
      removeLeg,
      getLeg,
      updateLeg,
      resetVoyage,
      initializeVoyageInEditFromExisting,
      getRoutingControlsType,
    ]
  );
};
