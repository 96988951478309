import { Route, Waypoint } from "shared-types/RouteTypes";

/**
 * Our simulator adds a ton of waypoints, every hour along a route, which are not referenced from
 * the manual schedule that expresses the user's intent. When we export a route, we only want to include
 * waypoints that are found in the manual schedule.
 */
export const getWaypointsForExport = (
  route: Route,
  includeCalculatedSchedule?: boolean
): Waypoint[] => {
  const scheduleForExport = includeCalculatedSchedule
    ? route.schedules?.schedules?.[0]?.calculated
    : // prefer manual schedule
      route.schedules?.schedules?.[0]?.manual ??
      route.schedules?.schedules?.[0]?.calculated;
  return (
    (scheduleForExport?.scheduleElements as { waypointId: number }[])
      ?.map(
        (se) =>
          // return waypoints in schedule
          route.waypoints.waypoints.find((w) => w.id === se.waypointId)
        // if there is no usable schedule then just return the full list of waypoints
      )
      .filter((w: Waypoint | undefined): w is Waypoint => Boolean(w)) ??
    route.waypoints.waypoints
  );
};
