import React from "react";
import TextLink from "./TextLink";

/**
 * Component to include in messages that renders to a mailto link that a user can click.
 * If you pass children to this element, they will be rendered as the link element.
 * Otherwise the email address is shown.
 *
 * We could even pass a body to the link if we wanted.
 *
 */
const WayfinderSupportEmailLink: React.FC<{
  subject?: string;
  className?: string;
}> = ({ subject, className, children }) => {
  const mailto = `mailto:routing@wayfinder.sofarocean.com${
    subject ? `?subject=${subject}` : ""
  }`;
  return (
    <TextLink className={className} href={mailto} target="_blank">
      {children ? children : "routing@wayfinder.sofarocean.com"}
    </TextLink>
  );
};

export default WayfinderSupportEmailLink;
