import WeatherContext from "contexts/WeatherContext";
import React, { useContext, useMemo } from "react";
import { useEffect, useRef, useState } from "react";
import { useWayfinderUrl } from "shared-hooks/use-wayfinder-url";

export type NowContextType = {
  now: Date;
};

const NowContextDefaults: NowContextType = {
  now: new Date(),
};

export const NowContext = React.createContext<NowContextType>(
  NowContextDefaults
);

export const NowContextProvider: React.FC = ({ children }) => {
  const { simulatedHistoricalTime } = useWayfinderUrl();

  // advance the "NOW" line up to date every minute, unless the
  // `simulatedHistoricalTime` override is set. In that case, assume the
  // simulated time is "NOW"
  const [now, setNow] = useState(simulatedHistoricalTime ?? new Date());
  const nowInterval = useRef<number | null>();
  useEffect(() => {
    if (simulatedHistoricalTime) {
      setNow(simulatedHistoricalTime);
    } else {
      const currentTime = new Date();
      setNow(currentTime);
      nowInterval.current = window.setInterval(
        () => setNow(new Date()),
        60 * 1000
      );
    }
    return () => {
      if (nowInterval.current) window.clearInterval(nowInterval.current);
    };
  }, [nowInterval, setNow, simulatedHistoricalTime]);
  return (
    <NowContext.Provider
      value={useMemo(() => ({ now }), [now])}
      children={children}
    />
  );
};
