import React from "react";
import { Box } from "@mui/material";
import FallbackLoadingScreen from "screens/FallbackLoadingScreen";

export const VoyagePlanRedirectLoadingScreen: React.FC<{}> = () => {
  return (
    <Box position="absolute" top="0" bottom="0" left="0" right="0">
      <FallbackLoadingScreen />
    </Box>
  );
};
