import { v4 as uuid } from "uuid";
import { default as sexagesimal } from "@mapbox/sexagesimal";

import { GM_Point, Route, Waypoint, Waypoints } from "shared-types/RouteTypes";
import {
  normalizePointLongitude,
  POSITION_IMPORT_DECIMAL_PRECISION,
} from "helpers/geometry";
import { padValue } from "helpers/routes";
import { coordToDMS } from "helpers/units";

import { getWaypointsForExport } from "../get-waypoints-for-export";

const formatFurunoPosition = (position: GM_Point) => {
  const lon = coordToDMS(position.lon, "lon");
  const lat = coordToDMS(position.lat, "lat");
  const minuteDigits = 3;
  return {
    lon: `${padValue(lon.whole, 3)} ${padValue(
      (lon.minutes + lon.seconds / 60).toFixed(minuteDigits),
      6
    )} ${lon.dir}`,
    lat: `${padValue(lat.whole, 2)} ${padValue(
      (lat.minutes + lat.seconds / 60).toFixed(minuteDigits),
      6
    )} ${lat.dir}`,
  };
};

/**
 *
 * @param route
 *
 * NAME	LAT	LON
 *	23 12.898 S	043 59.746 W
 *	23 19.193 S	043 54.020 W
 *	23 25.988 S	043 48.001 W
 */
export const rtzRouteToFurunoTxtString = (route: Route) => {
  return (
    `NAME\tLAT\tLON\r\n` +
    getWaypointsForExport(route)
      .map((w) => {
        const { lat, lon } = formatFurunoPosition(w.position);
        return `\t${lat}\t${lon}\r\n`;
      })
      .join("")
  );
};

const parseLatLonToPosition = (lat: string, lon: string) => {
  const latMatch = lat.match(
    /(?<deg>[0-9]{2})\s(?<min>[0-9]{2}.[0-9]{3})\s(?<hem>[S|N])/
  )?.groups ?? { deg: undefined, min: undefined, hem: undefined };
  const lonMatch = lon.match(
    /(?<deg>[0-9]{3})\s(?<min>[0-9]{2}.[0-9]{3})\s(?<hem>[E|W])/
  )?.groups ?? { deg: undefined, min: undefined, hem: undefined };

  const latOut = Number(
    (sexagesimal(`${latMatch.deg}°${latMatch.min}′${latMatch.hem}`) as
      | number
      | null)?.toFixed(POSITION_IMPORT_DECIMAL_PRECISION)
  );
  const lonOut = Number(
    (sexagesimal(`${lonMatch.deg}°${lonMatch.min}′${lonMatch.hem}`) as
      | number
      | null)?.toFixed(POSITION_IMPORT_DECIMAL_PRECISION)
  );
  return normalizePointLongitude({
    lat: latOut,
    lon: lonOut,
  });
};

export const furunoTxtStringToRtzRoute = (furunoString: string): Route => {
  const waypointMatches = Array.from(
    furunoString
      .replace(/^NAME\t+LAT\t+LON\r?\n/, "")
      .matchAll(/(?<name>[^\t]+)?\t+(?<lat>[^\t]+)\t+(?<lon>[^\r\n]+)\r?\n/g)
  );
  const waypoints = waypointMatches
    .map((match, id) => {
      if (match.groups?.lat === undefined || match.groups?.lon === undefined)
        return undefined;
      const { lat, lon } = parseLatLonToPosition(
        match.groups.lat,
        match.groups.lon
      );
      return {
        id,
        position: { lat, lon },
        ...(match.groups?.name ? { name: match.groups.name } : undefined),
        leg: {
          geometryType: "Loxodrome",
        },
      } as Waypoint;
    })
    .filter((w): w is Waypoint => Boolean(w !== undefined));
  return {
    version: "1.0",
    routeInfo: {
      routeName: "",
    },
    extensions: { uuid: uuid(), readonly: false },
    waypoints: {
      waypoints,
      defaultWaypoint: { id: Math.max(...waypoints.map((w) => w.id)) + 1 },
    } as Waypoints,
    schedules: {
      schedules: [],
    },
  };
};
