import {
  CharterType,
  RoutingControlsType,
} from "@sofarocean/wayfinder-typescript-client";
import { CrystalGlobeApiContext } from "contexts/CrystalGlobeApiContext";
import { getRoutingControlsQueryKey } from "helpers/crystalGlobeApi";
import { createTypedObjectFromEntries } from "helpers/fromEntries";
import { useContext, useMemo } from "react";
import { useQuery } from "react-query";

/**
 * `useRoutingControlsTypes()` is used in voyage creation/edit to find out what the correct routing controls are
 * for a given vessel and charter type. The logic is centralized in CG in the VoyageFeatures service
 * to avoid the added complexity of defining the relationships in different parts of the system.
 *
 * We favored separate calls to the endpoint for each charter type so that the DTO sent back does not need
 * to change shape if the inputs change, ie later on it also depends on a sku or something.
 *
 * We avoid changing DTO shape between deploys because we maintain backward compatibility
 *
 * @param vesselUuid
 */
const charterTypes = Object.values(CharterType);
type CharterTypeKey = typeof charterTypes[number];
export const useRoutingControlsTypes = (
  vesselUuid: string | undefined | null
) => {
  const { VesselsApi } = useContext(CrystalGlobeApiContext);
  const { isLoading, data: routingControlsTypesByCharterType } = useQuery<
    Record<CharterTypeKey, RoutingControlsType> | undefined
  >(
    getRoutingControlsQueryKey(vesselUuid),
    async () => {
      if (!vesselUuid) return undefined;
      // we want a dict, so that we can look up the routing controls type using a charter type
      // using createTypedObjectFromEntries makes it type-safe
      const controlsDict = createTypedObjectFromEntries(
        // wait for all routing controls types to come in
        await Promise.all(
          charterTypes.map<Promise<[CharterTypeKey, RoutingControlsType]>>(
            async (charterType: CharterTypeKey) => [
              charterType,
              (
                await VesselsApi.getRoutingControlsType({
                  vesselUuid,
                  charterType,
                })
              ).routingControlsType,
            ]
          )
        )
      );
      return controlsDict;
    },
    { enabled: Boolean(vesselUuid) }
  );
  return useMemo(
    () => ({
      routingControlsTypesByCharterType,
      isLoading,
    }),
    [isLoading, routingControlsTypesByCharterType]
  );
};
