import { Box, Divider } from "@mui/material";
import { WayfinderTypography } from "DLS";
import IndicatorDot from "DLS/IndicatorDot";
import { capitalize } from "lodash";
import React from "react";
import { TooltipProps } from "recharts";
import { ALERT_THRESHOLD_COLOR, TOOLTIP_WIDTH } from "./plot-config";
import { getTimestampFromBucket } from "./plotDataHelpers";
import { SafetySettings, TimestampBucket } from "./use-plot-data";
import { usePlotStyles } from "./use-plot-styles";
import { formatPillTime } from "./use-plot-ui";

const textStyle: React.CSSProperties = { textAlign: "center" };

const TooltipRowItem: React.FC<{
  text: string;
  color: string;
}> = ({ text, color }) => {
  return (
    <Box display="flex" alignItems="center">
      <IndicatorDot color={color} />
      <WayfinderTypography variant="displaySmall" color={color}>
        {text}
      </WayfinderTypography>
    </Box>
  );
};

export const PlotTooltip = ({
  payload,
  label,
  timestampBucketInfo,
  clampMax,
  coordinate,
  showSafetyLimitsFromToggle,
  safetySetting,
}: TooltipProps<any, any> & {
  timestampBucketInfo: {
    timestamps: number[];
    timestampBucket: TimestampBucket;
  };
  clampMax?: number;
  showSafetyLimitsFromToggle?: boolean;
  safetySetting?: SafetySettings;
}) => {
  const tooltipOffset =
    clampMax && coordinate?.x && clampMax - TOOLTIP_WIDTH / 2 < coordinate.x
      ? clampMax - coordinate.x - TOOLTIP_WIDTH
      : -TOOLTIP_WIDTH / 2;

  const { classes } = usePlotStyles({ tooltipOffset });
  if (payload && payload[0]?.name?.includes("missing")) {
    return (
      <div className={classes.noForecastLabel}>
        <WayfinderTypography variant="buttonSmall">
          No forecast
        </WayfinderTypography>
      </div>
    );
  }

  const nearestTimestamp = getTimestampFromBucket(
    timestampBucketInfo.timestamps,
    label
  );

  const weatherData =
    nearestTimestamp && timestampBucketInfo.timestampBucket[nearestTimestamp];

  if (weatherData && Object.keys(weatherData).length) {
    const sortedWeatherData = Object.keys(weatherData)
      ?.map((datum) => weatherData[datum])
      .sort((a, b) => b.value - a.value);

    const unit = sortedWeatherData[0].unit; // should be consistent across all data
    const safetyText = safetySetting
      ? `${capitalize(safetySetting.type)}: ${parseFloat(
          safetySetting.value!.toFixed(2)
        )} ${unit}`
      : undefined;

    return (
      <div className={classes.tooltipContainer}>
        <WayfinderTypography variant="displaySmall" sx={textStyle}>
          {label && formatPillTime(Number(label))}
        </WayfinderTypography>
        <Divider className={classes.tooltipDivider} />
        {Object.entries(sortedWeatherData)?.map(([datum, data]) => (
          <TooltipRowItem
            key={datum}
            text={`${parseFloat(data.value.toFixed(2))} ${unit}`}
            color={data.color}
          />
        ))}
        {showSafetyLimitsFromToggle && safetyText && (
          <>
            <Divider className={classes.tooltipDivider} />
            <WayfinderTypography
              variant="displaySmall"
              color={ALERT_THRESHOLD_COLOR}
            >
              Safety Threshold(s)
            </WayfinderTypography>
            <TooltipRowItem text={safetyText} color={ALERT_THRESHOLD_COLOR} />
          </>
        )}
      </div>
    );
  }
  return null;
};
