import { Box, SxProps } from "@mui/material";
import React from "react";

const IndicatorDot: React.FC<{
  color: string;
  border?: string;
  sx?: SxProps;
}> = ({ color, border, sx }) => (
  <Box
    sx={{
      height: "0.5rem",
      width: "0.5rem",
      borderRadius: "50%",
      marginRight: "0.5rem",
      display: "inline-block",
      backgroundColor: color,
      border,
      ...sx,
    }}
  />
);

export default IndicatorDot;
