import { Box, SxProps } from "@mui/material";
import { VoyageStatusV2 } from "@sofarocean/wayfinder-typescript-client";
import IndicatorDot from "DLS/IndicatorDot";
import React from "react";
import { match } from "ts-pattern";
import { makeStyles } from "tss-react/mui";
import { extendedPalette, typographyStyles } from "../../styles/theme";

export type VoyageStateProps = {
  status: VoyageStatusV2;
  showDot?: boolean;
  extraClass?: string;
  sx?: SxProps;
};

const useStyles = makeStyles()(() => ({
  container: {
    ...typographyStyles.buttonSmall,
    whiteSpace: "nowrap",
  },
}));

export const VoyageState: React.FC<VoyageStateProps> = ({
  status,
  showDot = false,
  extraClass,
  sx,
}) => {
  const { classes: styles } = useStyles();

  const { label, color } = match(status)
    .with(
      VoyageStatusV2.RoutingUnderway,
      VoyageStatusV2.StandbyUnderway,
      () => ({
        label: "In Progress",
        color: extendedPalette.voyageStates.inProgress,
      })
    )
    .with(
      VoyageStatusV2.PendingData,
      VoyageStatusV2.StandbyForDeparture,
      VoyageStatusV2.StandbyForArrival,
      () => ({
        label: "Planning",
        color: extendedPalette.voyageStates.planning,
      })
    )
    .with(VoyageStatusV2.Arrived, VoyageStatusV2.Archived, () => ({
      label: "Completed",
      color: extendedPalette.voyageStates.completed,
    }))
    .exhaustive();

  return (
    <Box
      component="span"
      className={`${styles.container} ${extraClass}`}
      color={color}
      sx={sx}
    >
      {showDot && <IndicatorDot color={color} />}
      {label}
    </Box>
  );
};
