import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { extendedPalette, typographyStyles } from "../../styles/theme";

export const useTextFieldStyles = makeStyles()((theme: Theme) => ({
  startIconContainer: {
    paddingRight: 10,
  },
  formLabel: {
    ...typographyStyles.formLabel,
    color: extendedPalette.neutralAccess,
    marginBottom: 12,
  },
  fullWidth: {
    width: "100%",
  },
  noPaddingTop: {
    paddingTop: 0,
  },
  fontStyle: {
    ...typographyStyles.title,
  },
  filledInputField: {
    border: "none",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 2,
    paddingRight: 2,
    textAlign: "center",
    ...typographyStyles.buttonSmall,
    backgroundColor: extendedPalette.neutralLight,
    height: 24,

    "&:focus-visible": {
      outline: "none",
    },

    "&.disabled": {
      backgroundColor: "transparent",
      color: "black",
      textAlign: "right",
      marginRight: 2,
    },
  },
}));
