import { useCallback, useContext, useMemo } from "react";
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from "react-query";
import { CrystalGlobeApiContext } from "contexts/CrystalGlobeApiContext/index";
import { synchronizeQueryCache } from "helpers/crystalGlobeApi";
import AnalyticsContext, { AnalyticsEvent } from "contexts/Analytics";
import { RoutesMutationResponseDtoData } from "@sofarocean/wayfinder-typescript-client";
import useRoute from "contexts/RouteStoreContext/use-route";
import { retryWayfinderAPIRequestOn500Error } from "../../helpers/fetch/fetch-helpers";

export type RouteNameMutationHookType = {
  updateRouteName: UseMutateAsyncFunction<
    RoutesMutationResponseDtoData | null,
    Error & {
      response?:
        | {
            status?: number | undefined;
          }
        | undefined;
    },
    string,
    unknown
  >;
  resetUpdateRouteError: () => void;
  updateRouteIsError: boolean;
  updateRouteIsLoading: boolean;
};

export function useRouteNameMutation(
  routeUuid: string
): RouteNameMutationHookType {
  const queryClient = useQueryClient();
  const { RoutesApi } = useContext(CrystalGlobeApiContext);
  const { trackAnalyticsEvent } = useContext(AnalyticsContext);

  /**
   * Update the route name
   * @param routeUuid the route uuid
   * @param routeName the new name
   */
  const updateRoute = async (
    routeName: string
  ): Promise<RoutesMutationResponseDtoData | null> => {
    const res = await RoutesApi.updateRoute({
      routeUuid,
      updateRouteDto: {
        routeName,
      },
    });
    synchronizeQueryCache(res.data, queryClient);
    return Promise.resolve(res.data);
  };

  const { metadata: { clearCache } = {} } = useRoute(routeUuid, false);
  const onSuccess = useCallback(
    (data: RoutesMutationResponseDtoData | null, routeName: string) => {
      clearCache?.();
      trackAnalyticsEvent(AnalyticsEvent.RouteNameUpdated, {
        routeName,
        routeUuid,
      });
    },
    [clearCache, routeUuid, trackAnalyticsEvent]
  );

  const {
    mutateAsync: updateRouteName,
    isLoading: updateRouteIsLoading,
    isError: updateRouteIsError,
    reset: resetUpdateRouteError,
  } = useMutation((routeName: string) => updateRoute(routeName), {
    onSuccess: onSuccess,
    retry: retryWayfinderAPIRequestOn500Error,
  });

  return useMemo(
    () => ({
      updateRouteName,
      updateRouteIsLoading,
      updateRouteIsError,
      resetUpdateRouteError,
    }),
    [
      updateRouteName,
      updateRouteIsLoading,
      updateRouteIsError,
      resetUpdateRouteError,
    ]
  );
}
