import {
  Box,
  Chip,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { visuallyHidden } from "@mui/utils";
import { orderBy } from "lodash";
import { DateTime } from "luxon";
import { corePalette, typographyStyles } from "styles/theme";
import IndicatorDot from "DLS/IndicatorDot";
import { WayfinderButton } from "DLS";
import { ReactComponent as MoreActionsIcon } from "bundle-data/images/icon-more-actions.svg";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { WayfinderTextField } from "DLS/wayfinder-text-field/wayfinder-text-field";
import { useRouteNameMutation } from "shared-hooks/data-fetch-hooks/use-route-name-mutation";
import { RouteEditorContext } from "contexts/RouteEditorContext";
import useRoute from "contexts/RouteStoreContext/use-route";
import { getRouteEditorConfiguration } from "contexts/RouteEditorContext/helpers";
import { useCurrentVoyageLeg } from "components/WayfinderApp/CurrentSession/contexts";
import { useCurrentUrl } from "helpers/navigation";
import {
  EDIT_ROUTE_PATH,
  VOYAGE_PLAN_QUERY_PARAM,
} from "shared-hooks/use-wayfinder-url";
import { VoyageMenuStateContext } from "../Voyage/use-voyage-menu-state";

export type RouteRowData = {
  uuid: string;
  routeName: string | null;
  routeColor: string;
  eta: string;
  etd: string;
  formattedTime: string;
  formattedTimeCostDollar: string;
  formattedFuelCostDollar: string;
  formattedTotalCostDollar: string;
  formattedFuelMT: string;
  formattedEmissionsMT: string;
  formattedAvgSpeedKts: string;
};

export type RouteExplorerTableProps = {
  routeList: Array<RouteRowData>;
  onRemoveRoute: (routeUuid: string) => void;
};

const HEAD_CELLS = [
  { id: "eta", label: "ETA" },
  { id: "etd", label: "ETD" },
  { id: "formattedTotalCostDollar", label: "TOTAL COST" },
  { id: "formattedFuelCostDollar", label: "FUEL COST" },
  { id: "formattedTimeCostDollar", label: "TIME COST" },
  { id: "formattedFuelMT", label: "M/E FUEL REQUIRED" },
  { id: "formattedEmissionsMT", label: "M/E CO2 EMISSIONS" },
  { id: "formattedAvgSpeedKts", label: "AVG SOG" },
];

type Order = "asc" | "desc";

const commonTableCellStyle = {
  minWidth: "100px",
  borderRight: `0.025rem solid ${corePalette.gray200}`,
  whiteSpace: "nowrap",
  ...typographyStyles.title,
};

export const RouteExplorerTable = React.memo(
  (props: RouteExplorerTableProps) => {
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderKey, setOrderKey] = React.useState("eta");

    const sortedRouteData = orderBy(props.routeList, orderKey, order);

    const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: any
    ) => {
      const isAsc = orderKey === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderKey(property);
    };

    const currentUrl = useCurrentUrl();

    // the plan is in edit mode if there is no plan param in the url
    // after it is published, that param will be there, and the plan should not be changed
    const isPlanInEditMode = !currentUrl.search().has(VOYAGE_PLAN_QUERY_PARAM);

    return (
      <Table sx={{ border: `0.025rem solid ${corePalette.gray200}` }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ borderRight: `0.025rem solid ${corePalette.gray200}` }}
            />
            {HEAD_CELLS.map((headCell) => (
              <TableCell
                key={headCell.id}
                align="left"
                sortDirection={orderKey === headCell.id ? order : false}
                sx={{
                  ...typographyStyles.title,
                  borderRight: `0.025rem solid ${corePalette.gray200}`,
                }}
              >
                <TableSortLabel
                  active={orderKey === headCell.id}
                  direction={orderKey === headCell.id ? order : "asc"}
                  onClick={(e) => handleRequestSort(e, headCell.id)}
                >
                  {headCell.label}
                  {orderKey === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRouteData?.map((r) => (
            <TableRow key={r.uuid} hover={true}>
              {r.routeName && (
                <RouteNameTableCell
                  routeName={r.routeName}
                  routeColor={r.routeColor}
                  routeUuid={r.uuid}
                  editMode={isPlanInEditMode}
                  onRemoveRoute={props.onRemoveRoute}
                />
              )}
              <TableCell
                sx={{ ...commonTableCellStyle, minWidth: "150px" }}
                align="right"
              >
                {DateTime.fromISO(r.eta).toFormat("LLL dd HH:mm 'Z'")}/
                {r.formattedTime}
              </TableCell>
              <TableCell sx={commonTableCellStyle} align="right">
                {DateTime.fromISO(r.etd).toFormat("LLL dd HH:mm 'Z'")}
              </TableCell>
              <TableCell align="right" sx={commonTableCellStyle}>
                {r.formattedTotalCostDollar}
              </TableCell>
              <TableCell align="right" sx={commonTableCellStyle}>
                {r.formattedFuelCostDollar}
              </TableCell>
              <TableCell align="right" sx={commonTableCellStyle}>
                {r.formattedTimeCostDollar}
              </TableCell>
              <TableCell align="right" sx={commonTableCellStyle}>
                {r.formattedFuelMT}
              </TableCell>
              <TableCell align="right" sx={commonTableCellStyle}>
                {r.formattedEmissionsMT}
              </TableCell>
              <TableCell align="right" sx={commonTableCellStyle}>
                {r.formattedAvgSpeedKts}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
);

const iconStyle: SxProps = {
  width: "16px",
  height: "16px",
  mr: 2,
  verticalAlign: "middle",
};

const RouteNameTableCell = (props: {
  routeColor: string;
  routeName: string;
  routeUuid: string;
  editMode: boolean;
  onRemoveRoute: (routeUuid: string) => void;
}) => {
  const { routeColor, routeUuid, editMode, onRemoveRoute } = props;
  const {
    onClickExportRoutes,
    activeRouteUuid,
    suggestedRouteUuid,
    onSuggestRoute,
    onFollowRoute,
  } = useContext(VoyageMenuStateContext);

  const isActiveRoute = routeUuid === activeRouteUuid;
  const isSuggestedRoute = routeUuid === suggestedRouteUuid;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [routeName, setRouteName] = useState(props.routeName);

  const { updateRouteName, updateRouteIsLoading } = useRouteNameMutation(
    routeUuid
  );

  const menuOpen = Boolean(anchorEl);

  const { editRoute } = useContext(RouteEditorContext);
  const { route } = useRoute(routeUuid);
  const { voyage } = useCurrentVoyageLeg();
  const currentUrl = useCurrentUrl();

  const onClickEditRoute = () => {
    if (route && voyage) {
      const configuration = getRouteEditorConfiguration(voyage, route);
      editRoute(route, configuration);
      currentUrl.withPath(EDIT_ROUTE_PATH).withDetailRouteUuid(routeUuid).go();
    }
  };

  const onClickRemoveRoute = () => {
    onRemoveRoute(routeUuid);
  };

  const onClickDownloadRoute = () => {
    onClickExportRoutes();
    setAnchorEl(null);
  };

  const onClickSuggestRoute = () => {
    onSuggestRoute(routeUuid);
    setAnchorEl(null);
  };

  const onClickFollowRoute = () => {
    onFollowRoute(routeUuid);
  };

  return (
    <TableCell sx={commonTableCellStyle}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        display="flex"
        flexDirection="row"
      >
        <Grid
          item
          textOverflow="ellipsis"
          minWidth="0"
          flex="1"
          overflow="hidden"
          whiteSpace="nowrap"
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          {!isActiveRoute && !isSuggestedRoute ? (
            <IndicatorDot color={routeColor} />
          ) : null}
          {isActiveRoute ? (
            <Chip
              label="Active"
              sx={{
                backgroundColor: routeColor ?? "#000",
                color: "#fff",
                marginRight: "0.5rem",
              }}
            />
          ) : null}
          {isSuggestedRoute && !isActiveRoute ? (
            <Chip
              label="Suggested"
              sx={{
                backgroundColor: routeColor ?? "#000",
                color: "#fff",
                marginRight: "0.5rem",
              }}
            />
          ) : null}

          {editMode ? (
            <WayfinderTextField
              variant="primary"
              value={routeName}
              onChange={(event) => {
                setRouteName(event.target.value);
              }}
              onKeyDown={({ key }) => {
                if (key === "Enter") {
                  updateRouteName(routeName);
                }
              }}
              onBlur={() => {
                setRouteName(props.routeName);
              }}
              disabled={updateRouteIsLoading}
              sx={{ minWidth: "200px" }}
              textAlign="left"
            />
          ) : (
            props.routeName
          )}
        </Grid>
        <Grid item flexShrink="0">
          <WayfinderButton variant="tertiary" onClick={handleClick}>
            <MoreActionsIcon />
          </WayfinderButton>
        </Grid>
      </Grid>
      <Popover
        open={menuOpen}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 5, horizontal: 40 }}
      >
        <Box sx={{ px: 4 }}>
          <List sx={{ color: corePalette.blue500, width: "210px" }}>
            {props.editMode && (
              <ListItemButton data-cy="edit-route" onClick={onClickEditRoute}>
                <ListItemText>
                  <EditIcon sx={iconStyle} />
                  Edit
                </ListItemText>
              </ListItemButton>
            )}
            <ListItemButton
              data-cy="download-route"
              onClick={onClickDownloadRoute}
            >
              <ListItemText>
                <DownloadIcon sx={iconStyle} />
                Download
              </ListItemText>
            </ListItemButton>
            {props.editMode && (
              <ListItemButton
                data-cy="remove-route"
                onClick={onClickRemoveRoute}
              >
                <ListItemText>
                  <DeleteOutlineIcon sx={iconStyle} />
                  Remove
                </ListItemText>
              </ListItemButton>
            )}
          </List>
          {!isActiveRoute ? <Divider /> : null}
          {!isActiveRoute && (
            <WayfinderButton
              sx={{ width: "80px", m: 2 }}
              onClick={onClickFollowRoute}
            >
              Follow
            </WayfinderButton>
          )}
          {!isSuggestedRoute && !isActiveRoute && (
            <WayfinderButton
              sx={{ width: "80px", m: 2 }}
              onClick={onClickSuggestRoute}
            >
              Suggest
            </WayfinderButton>
          )}
        </Box>
      </Popover>
    </TableCell>
  );
};
