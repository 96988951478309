export enum LoadConditionEnum {
  ballast = "ballast",
  laden = "laden",
}

const LOAD_CONDITIONS = ["ballast", "laden"] as const;
export type LoadCondition = typeof LOAD_CONDITIONS[number];

export function isLoadCondition(value: unknown): value is LoadCondition {
  return (
    value === LoadConditionEnum.ballast || value === LoadConditionEnum.laden
  );
}
