import { useContext, useMemo } from "react";
import { isNil } from "lodash";
import { RouteStoreContext } from "contexts/RouteStoreContext";
import { useWayfinderUrl } from "shared-hooks/use-wayfinder-url";

export function useAlignedRouteComparisonStartTime(): {
  alignedRouteComparisonStartTime: Date | null;
} {
  const { routeStartTimes } = useContext(RouteStoreContext);
  const { routeUuidsToCompare } = useWayfinderUrl();
  const alignedRouteComparisonStartTime = useMemo(() => {
    if (routeUuidsToCompare.length < 2) {
      return null;
    }

    // It is possible for there to be routes in the RouteStoreContext that are not included in
    // the routeUuidsToCompare url param, for example from another open tab. For determining
    // an aligned start time for comparison, we only want to include those that are in the url param.
    return routeUuidsToCompare.reduce(
      (startTime: Date | null, routeUuid: string) => {
        const currentStartTime = routeStartTimes[routeUuid];
        if (isNil(startTime)) {
          return currentStartTime;
        }
        return !isNil(currentStartTime) && currentStartTime > startTime
          ? currentStartTime
          : startTime;
      },
      null
    );
  }, [routeUuidsToCompare, routeStartTimes]);

  return useMemo(
    () => ({
      alignedRouteComparisonStartTime,
    }),
    [alignedRouteComparisonStartTime]
  );
}
