import { xor } from "lodash";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import CloseIcon from "@mui/icons-material/Close";
import React, { useCallback, useEffect, useMemo } from "react";
import { useMultiselect } from "shared-hooks/use-multiselect";
import { Route } from "shared-types/RouteTypes";
import { RouteLabel, WayfinderTypography } from "DLS";
import { useRouteStyles } from "styles/use-route-styles";
import useAppSetting from "contexts/AppSettingsContext";
import { extendedPalette } from "styles/theme";
import { RouteSummaryTag } from "../../sidebar/RouteSummary";
import { useModalDialogStyles } from "../use-modal-dialog-styles";

// These should not have spaces in them! They are used in file names.
export type RouteExportFormat =
  | "RTZ_XML"
  | "JRC_CSV"
  | "Tokimec_CSV"
  | "Furuno_Simple"
  | "Totem_CSV"
  | "Nacos1100_CSV";

export type RouteFileOutput = {
  content: string;
  name: string;
  format: RouteExportFormat;
};

export enum EXPORT_FORMAT_LABELS {
  RTZ_XML = "RTZ XML",
  JRC_CSV = "JRC CSV",
  Tokimec_CSV = "Tokimec CSV",
  Furuno_Simple = "Furuno Simple",
  Totem_CSV = "Totem CSV",
  Nacos1100_CSV = "Nacos 1100 CSV",
}
export type RouteExportFormProps = {
  show: boolean;
  close: () => void;
  onSubmit: (routeUuids: string[], formats: RouteExportFormat[]) => void;
  error?: string | undefined;
  routes: Route[];
  getTag?: (routeUuid: string | undefined) => RouteSummaryTag | undefined;
};

const useStyles = makeStyles()((theme: Theme) => ({
  checkbox: {
    color: theme.palette.primary.main,
    "& svg *": {
      fill: theme.palette.primary.main,
    },
    marginLeft: -10,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 5,
  },
  chip: { marginRight: 10 },
  rowText: { color: "black" },
  sectionHeader: { paddingBottom: 24 },
  sectionHeaderTopPadding: { paddingTop: 50, paddingBottom: 24 },
  dialogContent: {
    paddingTop: 20,
    paddingBottom: 30,
  },
}));

const legacyTextStyle = { color: extendedPalette.red };

export const RouteExportForm: React.FC<RouteExportFormProps> = ({
  show,
  close,
  onSubmit,
  error,
  routes,
  getTag,
}) => {
  const { classes } = useStyles();
  const {
    modalDialogClasses,
    modalDialogTitleClasses,
  } = useModalDialogStyles();
  const { value: enableTotemCsvExport } = useAppSetting("enableTotemCsvExport");

  const SUPPORTED_ROUTE_EXPORT_FORMATS: RouteExportFormat[] = [
    "RTZ_XML",
    "JRC_CSV",
    "Tokimec_CSV",
    "Furuno_Simple",
    "Nacos1100_CSV",
  ];
  if (enableTotemCsvExport) {
    SUPPORTED_ROUTE_EXPORT_FORMATS.push("Totem_CSV");
  }

  const routeUuids = routes
    .map((r) => r.extensions?.uuid)
    .filter((uuid): uuid is string => Boolean(uuid));

  const {
    toggleCallbacks: routeToggleCallbacks,
    selectedItems: selectedRoutes,
    setSelectedItems: setSelectedRoutes,
  } = useMultiselect<string>(routeUuids);
  const {
    toggleCallbacks: formatToggleCallbacks,
    selectedItems: selectedFormats,
    setSelectedItems: setSelectedFormats,
  } = useMultiselect<RouteExportFormat>(SUPPORTED_ROUTE_EXPORT_FORMATS);

  useEffect(() => {
    if (routeUuids.length === 1 && routeUuids[0] !== selectedRoutes[0]) {
      setSelectedRoutes(routeUuids);
    }
  }, [routeUuids, selectedRoutes, setSelectedRoutes]);

  const clearForm = useCallback(() => {
    setSelectedFormats([]);
    setSelectedRoutes([]);
  }, [setSelectedFormats, setSelectedRoutes]);

  const onSubmitButtonClick = useCallback(() => {
    onSubmit(selectedRoutes, selectedFormats);
  }, [onSubmit, selectedRoutes, selectedFormats]);

  const onGoBackButtonClick = useCallback(() => {
    close();
  }, [close]);

  useEffect(() => {
    if (!show) {
      clearForm();
    }
  }, [clearForm, show]);

  const routeStyles = useRouteStyles();

  const allSelected = useMemo(
    () => xor(routeUuids, selectedRoutes).length === 0 && routeUuids.length > 0,
    [routeUuids, selectedRoutes]
  );
  const toggleSelectAll = useCallback(() => {
    const allWereSelected = allSelected;
    routeUuids.forEach((uuid) => {
      if (allWereSelected) {
        setSelectedRoutes([]);
      } else {
        setSelectedRoutes(routeUuids);
      }
    });
  }, [allSelected, routeUuids, setSelectedRoutes]);

  const { value: enableLegacyRtzXmlFormatting } = useAppSetting(
    "enableLegacyRtzXmlFormatting"
  );

  const dialogClasses = useMemo(
    () => ({ paper: modalDialogClasses.dialogContainer }),
    [modalDialogClasses.dialogContainer]
  );
  const dialogActionsClasses = useMemo(
    () => ({ root: modalDialogClasses.actions }),
    [modalDialogClasses.actions]
  );
  const dialogActionsButtonClasses = useMemo(
    () => ({ root: modalDialogClasses.leftButton }),
    [modalDialogClasses.leftButton]
  );
  const gridClasses = useMemo(
    () => ({ root: modalDialogClasses.errorContainer }),
    [modalDialogClasses.errorContainer]
  );

  return (
    <Dialog open={show} fullWidth={true} maxWidth="sm" classes={dialogClasses}>
      <div className={modalDialogClasses.close} onClick={close}>
        <CloseIcon />
      </div>

      <DialogTitle classes={modalDialogTitleClasses}>
        Please select your route export preferences below.
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <WayfinderTypography
          variant="bodyLarge"
          className={classes.sectionHeader}
        >
          Routes
        </WayfinderTypography>
        <div className={classes.row}>
          <Checkbox
            checked={allSelected}
            onChange={toggleSelectAll}
            className={classes.checkbox}
          />
          <WayfinderTypography
            variant="buttonSmall"
            className={classes.rowText}
          >
            All routes
          </WayfinderTypography>
        </div>
        {routes
          .filter((r): r is Route & { extensions: { uuid: string } } =>
            Boolean(r.extensions?.uuid)
          )
          .map((route) => {
            const {
              extensions: { uuid },
              routeInfo: { routeName },
            } = route;
            const tag = getTag?.(uuid);
            return (
              <div className={classes.row} key={uuid}>
                <Checkbox
                  checked={selectedRoutes.includes(uuid)}
                  onChange={routeToggleCallbacks[uuid]}
                  className={classes.checkbox}
                />
                {routeStyles[uuid] ? (
                  <>
                    <div className={classes.chip}>
                      <RouteLabel tag={tag} color={routeStyles[uuid].color} />
                    </div>
                    <WayfinderTypography
                      variant="buttonSmall"
                      className={classes.rowText}
                    >
                      {routeName}
                    </WayfinderTypography>
                  </>
                ) : (
                  <WayfinderTypography
                    variant="buttonSmall"
                    className={classes.rowText}
                  >
                    {routeName}
                  </WayfinderTypography>
                )}
              </div>
            );
          })}
        <WayfinderTypography
          variant="bodyLarge"
          className={classes.sectionHeaderTopPadding}
        >
          Format
        </WayfinderTypography>

        {SUPPORTED_ROUTE_EXPORT_FORMATS.map((format) => (
          <div className={classes.row} key={format}>
            <Checkbox
              checked={selectedFormats.includes(format)}
              onChange={formatToggleCallbacks[format]}
              className={classes.checkbox}
            />
            <WayfinderTypography
              variant="buttonSmall"
              className={classes.rowText}
            >
              {EXPORT_FORMAT_LABELS[format]}{" "}
              {enableLegacyRtzXmlFormatting && format === "RTZ_XML" ? (
                <span style={legacyTextStyle}>legacy enabled</span>
              ) : null}
            </WayfinderTypography>
          </div>
        ))}

        {error && (
          <Grid
            direction="column"
            container
            alignItems="stretch"
            classes={gridClasses}
          >
            <Typography
              variant="subtitle1"
              className={modalDialogClasses.errorTitle}
            >
              Error: {error}
            </Typography>
          </Grid>
        )}
      </DialogContent>
      <DialogActions classes={dialogActionsClasses}>
        <Button
          onClick={onGoBackButtonClick}
          color="primary"
          autoFocus
          classes={dialogActionsButtonClasses}
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmitButtonClick}
          color="primary"
          disabled={!selectedFormats.length || !selectedRoutes.length}
        >
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
};
