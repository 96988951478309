import { useCallback, useMemo, useState } from "react";

export const useMultiselect = <T extends string>(items: T[]) => {
  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const onToggleItem = useCallback(
    (item: T) => {
      if (selectedItems.indexOf(item) >= 0) {
        setSelectedItems(selectedItems.filter((r) => r !== item));
      } else {
        setSelectedItems([...selectedItems, item]);
      }
    },
    [selectedItems]
  );
  const toggleCallbacks = useMemo(() => {
    const result: Partial<Record<T, () => void>> = {};
    items.forEach((r) => {
      result[r] = () => onToggleItem(r);
    });
    return result;
  }, [items, onToggleItem]);

  return useMemo(
    () => ({
      toggleCallbacks,
      selectedItems,
      setSelectedItems,
    }),
    [toggleCallbacks, selectedItems]
  );
};
