import { isNil } from "lodash";
import { useMemo } from "react";
import { RouteSummaryData } from "../../helpers/routeSummary";
import { RouteStyle } from "../WeatherAlongRoutePlot";
import {
  RouteLineItem,
  RouteSummaryPopoverContentProps,
  RouteSummaryPopoverSection,
} from "./RouteSummary/RouteSummaryPopoverContent";
import { useRelativeRouteSummary } from "./RouteSummary/use-route-summary-data";

const getInfoPopoverSectionProps = ({
  routeSummaryData,
  routeStyle,
  shouldShowEcaFuelBreakdown,
  shouldShowEuaEmissionsBreakdown,
}: {
  routeSummaryData: RouteSummaryData;
  routeStyle: RouteStyle;
  shouldShowEcaFuelBreakdown?: boolean;
  shouldShowEuaEmissionsBreakdown?: boolean;
}): RouteSummaryPopoverSection => {
  const {
    absoluteSummary: {
      time: { formattedTimeUnitCost, formattedTime, formattedTimeExpense },
      voyage: { formattedVoyageExpenses },
    },
    routeDescription,
    routeName,
  } = routeSummaryData;
  return {
    routeDescription,
    routeName,
    routeStyle: routeStyle,
    lineItems: [
      {
        label: "Time",
        formattedUnitCost: formattedTimeUnitCost,
        formattedQuantity: formattedTime,
        formattedSubtotal: formattedTimeExpense,
      },
      {
        label: "Standard M/E fuel",
        formattedUnitCost:
          routeSummaryData.absoluteSummary.fuel.standard.formattedFuelUnitCost,
        formattedQuantity:
          routeSummaryData.absoluteSummary.fuel.standard.formattedFuel,
        formattedSubtotal:
          routeSummaryData.absoluteSummary.fuel.standard.formattedFuelExpense,
      },
      ...((!!shouldShowEcaFuelBreakdown && [
        {
          label: "ECA M/E fuel",
          formattedUnitCost:
            routeSummaryData.absoluteSummary.fuel.eca.formattedFuelUnitCost,
          formattedQuantity:
            routeSummaryData.absoluteSummary.fuel.eca.formattedFuel,
          formattedSubtotal:
            routeSummaryData.absoluteSummary.fuel.eca.formattedFuelExpense,
        },
      ]) ||
        []),
      ...((!!shouldShowEuaEmissionsBreakdown && [
        {
          label: "Emissions",
          formattedUnitCost:
            routeSummaryData.absoluteSummary.emissions.eua
              .formattedEmissionsUnitCost,
          formattedQuantity:
            routeSummaryData.absoluteSummary.emissions.eua.formattedEmissions,
          formattedSubtotal:
            routeSummaryData.absoluteSummary.emissions.eua
              .formattedEmissionsExpense,
        },
      ]) ||
        []),
    ],
    formattedVoyageExpenses,
  };
};

export const useRouteSummaryPopoverProps = (
  alternateRouteSummaryData: RouteSummaryData | undefined,
  comparisonBasisSummaryData: RouteSummaryData | undefined,
  alternateStyle: RouteStyle | undefined,
  comparisonBasisStyle: RouteStyle | undefined
): RouteSummaryPopoverContentProps | undefined => {
  const isEcaInUseInComparisonBasisRoute =
    comparisonBasisSummaryData?.absoluteSummary.fuel.isEcaFuelInUse === true;
  const isEcaInUseInAlternateRoute =
    alternateRouteSummaryData?.absoluteSummary.fuel.isEcaFuelInUse === true;
  const isEuaInUseInComparisonBasisRoute = ["half", "full"].includes(
    comparisonBasisSummaryData?.absoluteSummary.emissions.euEtsStatus ?? ""
  );
  const isEuaInUseInAlternateRoute = ["half", "full"].includes(
    alternateRouteSummaryData?.absoluteSummary.emissions.euEtsStatus ?? ""
  );
  const shouldShowEcaFuelBreakdown =
    isEcaInUseInComparisonBasisRoute || isEcaInUseInAlternateRoute;
  const shouldShowEuaEmissionsBreakdown =
    isEuaInUseInComparisonBasisRoute || isEuaInUseInAlternateRoute;

  // arrange figures in a way that makes sense for the popover sections
  const comparisonBasisSection =
    comparisonBasisSummaryData &&
    comparisonBasisStyle &&
    getInfoPopoverSectionProps({
      routeSummaryData: comparisonBasisSummaryData,
      routeStyle: comparisonBasisStyle,
      shouldShowEcaFuelBreakdown,
      shouldShowEuaEmissionsBreakdown,
    });

  const alternativeRouteSection =
    alternateRouteSummaryData &&
    alternateStyle &&
    getInfoPopoverSectionProps({
      routeSummaryData: alternateRouteSummaryData,
      routeStyle: alternateStyle,
      shouldShowEcaFuelBreakdown,
      shouldShowEuaEmissionsBreakdown,
    });

  const relativeSummary = useRelativeRouteSummary(
    alternateRouteSummaryData,
    comparisonBasisSummaryData
  );

  const relativeSummarySection: RouteLineItem[] = [];
  if (alternateRouteSummaryData && relativeSummary) {
    relativeSummarySection.push({
      label:
        relativeSummary.time.timeDifferenceMs > 0
          ? "Time savings"
          : "Time cost",
      formattedUnitCost: relativeSummary.time.formattedTimeUnitCost,
      formattedQuantity: relativeSummary.time.formattedTimeDifference,
      formattedSubtotal: relativeSummary.time.formattedTimeExpenseSavings,
      bold: true,
    });

    if (relativeSummary.fuel.standard.fuelDifferenceMT) {
      const fuelLabel = "Standard M/E fuel";
      relativeSummarySection.push({
        label:
          relativeSummary.fuel.standard.fuelDifferenceMT > 0
            ? `${fuelLabel} savings`
            : `${fuelLabel} cost`,
        formattedUnitCost: relativeSummary.fuel.standard.formattedFuelUnitCost,
        formattedQuantity:
          relativeSummary.fuel.standard.formattedFuelDifference,
        formattedSubtotal:
          relativeSummary.fuel.standard.formattedFuelExpenseSavings,
        bold: true,
      });
    }

    if (
      shouldShowEcaFuelBreakdown &&
      !isNil(relativeSummary.fuel.eca.fuelDifferenceMT)
    ) {
      const fuelLabel = "ECA M/E fuel";
      relativeSummarySection.push({
        label:
          relativeSummary.fuel.eca.fuelDifferenceMT >= 0
            ? `${fuelLabel} savings`
            : `${fuelLabel} cost`,
        formattedUnitCost: relativeSummary.fuel.eca.formattedFuelUnitCost,
        formattedQuantity: relativeSummary.fuel.eca.formattedFuelDifference,
        formattedSubtotal: relativeSummary.fuel.eca.formattedFuelExpenseSavings,
        bold: true,
      });
    }

    if (
      shouldShowEuaEmissionsBreakdown &&
      !isNil(relativeSummary.emissions.eua.emissionsDifferenceCo2MT)
    ) {
      const emissionsLabel = "Emissions";
      relativeSummarySection.push({
        label:
          relativeSummary.emissions.eua.emissionsDifferenceCo2MT >= 0
            ? `${emissionsLabel} savings`
            : `${emissionsLabel} cost`,
        formattedUnitCost:
          relativeSummary.emissions.eua.formattedEmissionsUnitCost,
        formattedQuantity:
          relativeSummary.emissions.eua.formattedEmissionsDifference,
        formattedSubtotal:
          relativeSummary.emissions.eua.formattedEmissionsExpenseSavings,
        bold: true,
      });
    }
  }
  return useMemo(() => {
    return alternativeRouteSection &&
      comparisonBasisSection &&
      relativeSummary &&
      relativeSummarySection.length > 0
      ? {
          comparisonBasisSection,
          alternativeRouteSection,
          relativeSummarySection,
          formattedGains: relativeSummary.voyage.formattedVoyageExpenseSavings,
          isLoss:
            typeof relativeSummary.voyage.voyageExpenseSavings === "number" &&
            relativeSummary.voyage.voyageExpenseSavings < 0,
        }
      : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alternativeRouteSection, comparisonBasisSection, relativeSummary]);
};
