import { useMemo } from "react";
import { isNil } from "lodash";

import useRoute from "contexts/RouteStoreContext/use-route";
import { useRelativeRouteSummary } from "components/sidebar/RouteSummary/use-route-summary-data";
import useVoyage from "./data-fetch-hooks/use-voyage";
import { useRouteSuggestion } from "./data-fetch-hooks/use-route-suggestion";

export function useRouteSuggestionGains(
  voyageUuid: string | undefined
): number | undefined {
  const { voyage } = useVoyage(voyageUuid);
  const { routeSummaryData: activeRouteSummaryData } = useRoute(
    voyage?.activeRoute?.routeUuid ?? undefined,
    true
  );

  const { suggestedRoute } = useRouteSuggestion(voyageUuid);
  const { routeSummaryData: routeSuggestionSummaryData } = useRoute(
    suggestedRoute?.routeUuid,
    true
  );

  const relativeSummary = useRelativeRouteSummary(
    routeSuggestionSummaryData,
    activeRouteSummaryData
  );

  return useMemo(() => {
    const { fuelExpenseSavingsDollars } = relativeSummary?.fuel?.total ?? {};
    const { voyageExpenseSavings } = relativeSummary?.voyage ?? {};

    if (!isNil(fuelExpenseSavingsDollars) && !isNil(voyageExpenseSavings)) {
      return fuelExpenseSavingsDollars + voyageExpenseSavings;
    }
    return isNil(fuelExpenseSavingsDollars)
      ? voyageExpenseSavings
      : fuelExpenseSavingsDollars;
  }, [relativeSummary]);
}
