export const isBvs8Route = (rawString: string) =>
  rawString.indexOf("BVS8") > -1;
export const convertBvsString8ToJrcString = (rawString: string) => {
  // replace all comment lines missing spaces with ones that have a space
  let routeString = rawString.replaceAll(/\/\/(\S.*)/g, "// $1");
  // remove punctuation from name
  const nameLine = routeString.match(/\/\/\sRoute Name.*/);
  routeString = routeString
    .replace(
      /\/\/\sRoute Name.*/,
      nameLine?.[0].replaceAll(/[.|-]/g, " ") ?? ""
    )
    // fix wpt header
    .replaceAll(/WPT NO./g, "WPT No.")
    // fix speed column header
    .replaceAll(/Speed\[NM\]/g, "Speed[kn]");
  return routeString;
};
