import moment from "moment";
import { RouteValidationResult } from "helpers/importExport/route/validation";
import {
  calculateRouteDistanceNauticalMiles,
  computeNewRouteSpeedsAndTimes,
  shiftScheduleBy,
} from "helpers/routes";
import { Route } from "shared-types/RouteTypes";
import { hoursToMilliseconds } from "helpers/units";
import { round } from "lodash";
import { getRouteSchedule } from "../../../../helpers/getRouteSchedule";

export type CalculatedImportFormParameters = {
  eta?: string;
  etd?: string;
  calculatedAverageSpeedKts?: number;
  calculatedDuration?: number;
};

/**
 * Given a route, a validation result, and a set of intended eta and etd
 * update the schedule of the route to reflect our logical assumptions about
 * a user's intentions when setting the eta and etd.
 * @returns etd
 * @returns eta
 * @returns calculatedAverageSpeedKts in knots
 * @returns calculatedDuration in milliseconds
 */
export const updateImportedRouteSchedule = ({
  newDepartureTime,
  newArrivalTime,
  oldDepartureTime,
  oldArrivalTime,
  onImportError,
  pendingImportRoute,
  routeValidationResult,
  averageSpeedKts,
  endTimeLocked,
}: {
  newDepartureTime: string | undefined;
  newArrivalTime: string | undefined;
  oldDepartureTime: string | undefined;
  oldArrivalTime: string | undefined;
  onImportError: (error: Error) => void;
  pendingImportRoute: Route;
  routeValidationResult?: RouteValidationResult;
  averageSpeedKts?: number;
  endTimeLocked?: boolean;
}): CalculatedImportFormParameters | undefined => {
  let durationFromAverageSpeedInHours;
  const departureTime = newDepartureTime ?? oldDepartureTime;

  const distanceInNM = calculateRouteDistanceNauticalMiles(pendingImportRoute);
  // if average speed is present, set the eta based on the constraint, carry on with rest of operations
  if (averageSpeedKts) {
    durationFromAverageSpeedInHours = distanceInNM / averageSpeedKts;
    if (departureTime) {
      newArrivalTime = moment(departureTime)
        .add(durationFromAverageSpeedInHours, "hours")
        .utc()
        .toISOString();
    }
  }

  const arrivalTime = newArrivalTime ?? oldArrivalTime;

  // if input time is missing, change nothing, because we need input to calculate
  if (!arrivalTime && !departureTime) {
    return;
  }

  // derive duration from average speed if it is set
  let durationInMs = durationFromAverageSpeedInHours
    ? hoursToMilliseconds(durationFromAverageSpeedInHours)
    : undefined;

  const errorTypes = routeValidationResult?.errors?.map((e) => e.type);
  const routeHasTimestamps = !errorTypes?.includes("missing-times");
  const routeHasSpeeds = !errorTypes?.includes("missing-speeds");
  const formArrivalTimeAndEtdAlreadyPopulated = Boolean(
    oldArrivalTime && oldDepartureTime
  );
  const arrivalTimeEdited = newArrivalTime && oldArrivalTime !== newArrivalTime;
  const departureTimeEdited =
    newDepartureTime && oldDepartureTime !== newDepartureTime;

  // error out if missing schedule elements
  const { scheduleElements } = getRouteSchedule(pendingImportRoute); // validateAndFixRoute() has been called once already, so this should be manual schedule
  if (!scheduleElements?.length) {
    // the fixer should have added schdule elements if there were none
    const error = Error(
      "Somehow a route with no schedule elements was not fixed during import."
    );
    onImportError(error);
    return;
  }
  if (routeHasTimestamps) {
    const oldRouteEtd = scheduleElements[0].etd;
    const oldRouteEta = scheduleElements[scheduleElements.length - 1].eta;
    // also has speeds
    if (formArrivalTimeAndEtdAlreadyPopulated) {
      if (endTimeLocked && arrivalTime && departureTime) {
        if (!durationInMs) {
          durationInMs = moment(arrivalTime).diff(moment(departureTime));
        }
        computeNewRouteSpeedsAndTimes(
          pendingImportRoute,
          durationInMs,
          departureTime,
          arrivalTime
        );
      } else if (arrivalTimeEdited) {
        if (!durationInMs) {
          durationInMs = moment(newArrivalTime).diff(moment(departureTime));
        }
        computeNewRouteSpeedsAndTimes(
          pendingImportRoute,
          durationInMs,
          departureTime,
          arrivalTime
        );
      } else if (departureTimeEdited) {
        const differenceMs = moment(newDepartureTime).diff(oldDepartureTime);
        shiftScheduleBy(scheduleElements, differenceMs);
      }
    } else {
      // average speed is set initially, recompute etd and eta
      if (averageSpeedKts && durationInMs) {
        computeNewRouteSpeedsAndTimes(
          pendingImportRoute,
          durationInMs,
          departureTime,
          arrivalTime
        );
        // rta is set initially, calculate duration and recompute
      } else if (
        (endTimeLocked && arrivalTime && departureTime) ||
        (arrivalTimeEdited && departureTimeEdited)
      ) {
        durationInMs = moment(arrivalTime).diff(moment(departureTime));
        computeNewRouteSpeedsAndTimes(
          pendingImportRoute,
          durationInMs,
          departureTime,
          arrivalTime
        );
      } else {
        // dealing with only etd and eta changes
        let differenceMs;
        if (arrivalTimeEdited) {
          differenceMs = moment(arrivalTime).diff(oldRouteEta);
        } else {
          differenceMs = moment(departureTime).diff(oldRouteEtd);
        }
        shiftScheduleBy(scheduleElements, differenceMs);
      }
    }
  } else {
    // if there are no timestamps in the route, assign them only if we have two timestamps
    if (
      (arrivalTimeEdited || departureTimeEdited) &&
      departureTime &&
      arrivalTime
    ) {
      if (!durationInMs) {
        durationInMs = moment(arrivalTime).diff(moment(departureTime));
      }
      computeNewRouteSpeedsAndTimes(
        pendingImportRoute,
        durationInMs,
        departureTime,
        arrivalTime
      );
    } else if (routeHasSpeeds && (departureTime || arrivalTime)) {
      const speeds = scheduleElements.slice(1).map((se) => se.speed || 0);
      const routeAverageSpeed = speeds.reduce((a, b) => a + b) / speeds.length;
      durationInMs = hoursToMilliseconds(distanceInNM / routeAverageSpeed);
      computeNewRouteSpeedsAndTimes(
        pendingImportRoute,
        durationInMs,
        departureTime,
        arrivalTime
      );
    } else {
      // otherwise do nothing
      return;
    }
  }
  // finally, if we made changes, we will end up here
  // and pass the changes along in the return value
  const newRouteEtd = scheduleElements[0].etd;
  const newRouteEta = scheduleElements[scheduleElements.length - 1].eta;

  // calculate duration if it hasn't already been set
  if (!durationInMs && newRouteEta && newRouteEtd) {
    durationInMs = moment(newRouteEta).diff(moment(newRouteEtd));
  }

  // duration must be over 0 for us to calculate average speed
  const calculatedAverageSpeedKts =
    averageSpeedKts ?? (durationInMs && durationInMs > 0)
      ? calculateRouteDistanceNauticalMiles(pendingImportRoute) /
        moment.duration(durationInMs, "milliseconds").asHours()
      : undefined;

  return {
    eta: newRouteEta,
    etd: newRouteEtd,
    calculatedAverageSpeedKts: calculatedAverageSpeedKts
      ? round(calculatedAverageSpeedKts, 2)
      : undefined,
    calculatedDuration: durationInMs,
  };
};
