import { useCurrentVessel } from "components/WayfinderApp/CurrentSession/contexts";
import { CharterType } from "@sofarocean/wayfinder-typescript-client";
import { useLDFlags } from "./use-ld-flags";

export const useShowCharterTypeSelector = (): {
  showCharterTypeSelector: boolean;
  defaultCharterType: CharterType | undefined;
} => {
  const { allowEditCharterType } = useLDFlags();
  const { vessel } = useCurrentVessel();
  return {
    showCharterTypeSelector:
      allowEditCharterType && vessel?.defaultCharterType !== CharterType.None,
    defaultCharterType: vessel?.defaultCharterType,
  };
};
