import { useCallback, useMemo } from "react";

export type ArrivalWindowType = "on" | "before" | "after" | "between" | "none";

export const determineArrivalWindowTypeFromTimes = (
  rtaStartTimestampValue: string | undefined,
  rtaEndTimestampValue: string | undefined
) => {
  let menuValue: ArrivalWindowType = "before";
  if (rtaStartTimestampValue) {
    if (rtaEndTimestampValue) {
      // both are set
      // if the same, then choose "on"
      if (rtaStartTimestampValue === rtaEndTimestampValue) {
        menuValue = "on";
      } else {
        // otherwise "between"
        menuValue = "between";
      }
    } else {
      menuValue = "after";
    } // only start is set
  } else {
    // only end is set, choose "before"
    if (rtaEndTimestampValue) {
      menuValue = "before";
    }
    // none is set
    else menuValue = "none";
  }
  return menuValue;
};

export type ArrivalTimeMenuState = {
  arrivalWindowType: ArrivalWindowType | undefined;
  resetArrivalWindowValue: (
    rtaStartTimestampValue: string | undefined,
    rtaEndTimestampValue: string | undefined
  ) => void;
  onChangeArrivalWindowMenu: (value: ArrivalWindowType) => void;
  showEndTime: boolean;
  showStartTime: boolean;
};
export const useArrivalTimeMenuState = (
  setRTAStartTimestamp: (value: string | undefined) => void,
  setRTAEndTimestamp: (value: string | undefined) => void,
  rtaStartTimestampValue: string | undefined,
  rtaEndTimestampValue: string | undefined,
  arrivalWindowType: ArrivalWindowType | undefined,
  setArrivalWindowType: (value: ArrivalWindowType) => void
): ArrivalTimeMenuState => {
  const showBothTimes = arrivalWindowType === "between";
  const showEndTime = arrivalWindowType === "before" || showBothTimes;
  const showStartTime =
    arrivalWindowType === "after" ||
    arrivalWindowType === "none" ||
    arrivalWindowType === "on" ||
    showBothTimes;

  const onChangeArrivalWindowMenu = useCallback(
    (value: ArrivalWindowType) => {
      // help user avoid entering values more than once by moving value to correct field if needed
      // also clear out values in unused field so that api gets correct data
      const previousArrivalWindowType = arrivalWindowType;
      setArrivalWindowType(value);

      switch (value) {
        case "on":
          // if there is no start, set the start to match the end
          if (rtaEndTimestampValue && !rtaStartTimestampValue) {
            setRTAStartTimestamp(rtaEndTimestampValue);
          }
          // if there is no end, set the end to match the start
          if (!rtaEndTimestampValue && rtaStartTimestampValue) {
            setRTAEndTimestamp(rtaStartTimestampValue);
          }
          // if there are both, set both to the start
          if (rtaEndTimestampValue && rtaStartTimestampValue) {
            setRTAEndTimestamp(rtaStartTimestampValue);
          }
          // if there are none, leave them as they are
          break;
        case "after":
          if (previousArrivalWindowType === "before" && rtaEndTimestampValue) {
            setRTAStartTimestamp(rtaEndTimestampValue);
          } else if (rtaEndTimestampValue && !rtaStartTimestampValue) {
            setRTAStartTimestamp(rtaEndTimestampValue);
          }
          setRTAEndTimestamp(undefined);
          break;
        case "before":
          if (
            (previousArrivalWindowType === "on" ||
              previousArrivalWindowType === "after") &&
            rtaStartTimestampValue
          ) {
            setRTAEndTimestamp(rtaStartTimestampValue);
          } else {
            if (rtaStartTimestampValue && !rtaEndTimestampValue) {
              setRTAEndTimestamp(rtaStartTimestampValue);
            }
          }
          setRTAStartTimestamp(undefined);
          break;
        case "none":
          setRTAEndTimestamp(undefined);
          setRTAStartTimestamp(undefined);
      }
    },
    // do not run this if arrivalWindowType changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      setArrivalWindowType,
      rtaEndTimestampValue,
      rtaStartTimestampValue,
      setRTAEndTimestamp,
      setRTAStartTimestamp,
    ]
  );

  const resetArrivalWindowValue = useCallback(
    (
      rtaStartTimestampValue: string | undefined,
      rtaEndTimestampValue: string | undefined
    ) => {
      const newValue = determineArrivalWindowTypeFromTimes(
        rtaStartTimestampValue,
        rtaEndTimestampValue
      );
      setArrivalWindowType(newValue);
    },
    [setArrivalWindowType]
  );

  return useMemo(
    () => ({
      arrivalWindowType,
      resetArrivalWindowValue,
      onChangeArrivalWindowMenu,
      showEndTime,
      showStartTime,
    }),
    [
      arrivalWindowType,
      onChangeArrivalWindowMenu,
      resetArrivalWindowValue,
      showEndTime,
      showStartTime,
    ]
  );
};
