/**
 * Function to allow you to on demand, download a TXT or CSV file.
 *
 * @param contentProvider Callback function to get the content of the file
 * @param fileName
 * @param mimeType
 */
export const startFileDownload = (
  contentProvider: () => string,
  fileName: string,
  mimeType: "text/plain" | "text/csv" = "text/csv"
) => {
  const element = document.createElement("a");
  const file = new Blob([contentProvider()], {
    type: mimeType,
  });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
  document.body.removeChild(element);
};
