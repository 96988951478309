import { jsonToCSV, readString } from "react-papaparse";
import { v4 as uuid } from "uuid";

import { Route, Waypoint } from "shared-types/RouteTypes";
import { normalizePointLongitude } from "helpers/geometry";

import { maxBy } from "lodash";
import { getWaypointsForExport } from "../get-waypoints-for-export";

export enum Nacos1100WaypointCsvHeader {
  LAT = "Latitude",
  LON = "Longitude",
}

// better names for missing or incomplete header names in NACOS1100 spec
export const RENAMED_NACOS1100_WAYPOINT_HEADER_INDICES = {
  [Nacos1100WaypointCsvHeader.LAT]: 0,
  [Nacos1100WaypointCsvHeader.LON]: 1,
};

export type Nacos1100CsvWaypoint = Record<
  typeof Nacos1100WaypointCsvHeader[keyof typeof Nacos1100WaypointCsvHeader],
  string
>;

export const nacos1100CsvStringToRtzRoute = (csvString: string): Route => {
  const csvStringWithNormalizedLineEndings = csvString
    .replace(/\r\n/gm, "\n")
    .replace(/\r/gm, "\n")
    .trim();
  const csvData = readString(csvStringWithNormalizedLineEndings, {
    header: true,
    // supplies a better header name for missing or incomplete header names in NACOS1100 spec
    transformHeader: (header: string, index: number) => {
      for (const csvHeader of Object.keys(
        RENAMED_NACOS1100_WAYPOINT_HEADER_INDICES
      ) as (keyof typeof RENAMED_NACOS1100_WAYPOINT_HEADER_INDICES)[]) {
        if (RENAMED_NACOS1100_WAYPOINT_HEADER_INDICES[csvHeader] === index) {
          return csvHeader;
        }
      }
      return header;
    },
    skipEmptyLines: true,
  });
  const waypoints: Waypoint[] = (csvData.data as Nacos1100CsvWaypoint[]).map<Waypoint>(
    (csvWaypoint: Nacos1100CsvWaypoint, index: number) => {
      const normalizedPosition = normalizePointLongitude({
        lat: parseFloat(csvWaypoint["Latitude"]),
        lon: parseFloat(csvWaypoint["Longitude"]),
      });
      const result: Waypoint = {
        id: index,
        position: {
          lat: normalizedPosition.lat,
          lon: normalizedPosition.lon,
        },
        leg: {
          geometryType: "Loxodrome",
        },
      };
      return result;
    }
  );
  const maxId = maxBy(waypoints, "id")?.id ?? 0;
  return {
    version: "1.0",
    extensions: {
      readonly: false,
      uuid: uuid(),
    },
    routeInfo: {
      routeName: "Imported Route",
    },
    waypoints: {
      waypoints,
      defaultWaypoint: { id: maxId + 1 },
    },
    // let the validator catch the missing schedule and add one
  };
};

export const rtzRouteToNacos1100CsvString = (rtzRoute: Route): string => {
  // the sample route has a space before the comma, but that is silly
  const header = `${Nacos1100WaypointCsvHeader.LAT}, ${Nacos1100WaypointCsvHeader.LON}`;
  const data = getWaypointsForExport(rtzRoute).map((waypoint, index) => {
    return [waypoint.position.lat.toString(), waypoint.position.lon.toString()];
  });
  return `${header}\r\n${jsonToCSV({ data })}\r\n`;
};
