import { useCallback, useContext, useMemo } from "react";
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from "react-query";
import { v4 as uuid } from "uuid";
import { CrystalGlobeApiContext } from "contexts/CrystalGlobeApiContext/index";
import { RouteRejectionFeedback } from "components/modals/RouteRejectionFeedbackForm";
import { synchronizeQueryCache } from "helpers/crystalGlobeApi";
import UIContext from "contexts/UIContext/index";
import AnalyticsContext, { AnalyticsEvent } from "contexts/Analytics";
import {
  RouteSuggestionDto,
  RouteSuggestionResponse,
  RouteSuggestionsMutationResponseDtoData,
} from "@sofarocean/wayfinder-typescript-client";
import { useRouteSuggestionGains } from "shared-hooks/use-route-suggestion-gains";
import { retryWayfinderAPIRequestOn500Error } from "../../helpers/fetch/fetch-helpers";
import useVoyage from "./use-voyage";
import { useRouteSuggestion } from "./use-route-suggestion";

const RESPONSE_ERROR =
  "There was an error responding to the suggested route. Please check your internet connection and try again.";

export type RouteSuggestionResponseHookType = {
  acceptSuggestionAndUpdateActiveRoute: UseMutateAsyncFunction<
    RouteSuggestionsMutationResponseDtoData | null,
    Error & {
      response?:
        | {
            status?: number | undefined;
          }
        | undefined;
    },
    void,
    unknown
  >;
  rejectAndRemoveSuggestion: UseMutateAsyncFunction<
    RouteSuggestionsMutationResponseDtoData | null,
    Error & {
      response?:
        | {
            status?: number | undefined;
          }
        | undefined;
    },
    RouteRejectionFeedback,
    unknown
  >;
  resetSuggestionResponseError: () => void;
  suggestionResponseError: string | undefined;
  suggestionResponseIsLoading: boolean;
  suggestedRoute: RouteSuggestionDto | null | undefined;
  createRouteSuggestion: (
    routeUuid: string,
    voyageUuid: string
  ) => Promise<void>;
};

export function useRouteSuggestionResponse(
  voyageUuid: string | undefined
): RouteSuggestionResponseHookType {
  const queryClient = useQueryClient();
  const { suggestedRoute } = useRouteSuggestion(voyageUuid);
  const { RouteSuggestionsApi } = useContext(CrystalGlobeApiContext);
  const { hideRouteRejectionFeedbackForm } = useContext(UIContext);
  const { trackAnalyticsEvent } = useContext(AnalyticsContext);
  const { voyage } = useVoyage(voyageUuid);
  const comparisonSeenGains = useRouteSuggestionGains(voyageUuid);

  const createRouteSuggestion = useCallback(
    async (routeUuid: string, voyageUuid: string) => {
      const result = await RouteSuggestionsApi.createRouteSuggestion({
        createRouteSuggestionDto: {
          uuid: uuid(),
          routeUuid,
          voyageUuid,
        },
      });
      synchronizeQueryCache(result.data, queryClient);
    },
    [RouteSuggestionsApi, queryClient]
  );

  /**
   * Send the user's suggestion response to the API. This also triggers an update to the active route.
   * @param response the user's response to the suggestion
   * @param rejectionFeedback the feedback on the suggestion if it is rejected
   */
  const respondToRouteSuggestion = async (
    response: RouteSuggestionResponse,
    rejectionFeedback?: { reasons: string[]; comment: string }
  ): Promise<RouteSuggestionsMutationResponseDtoData | null> => {
    if (!suggestedRoute) return Promise.resolve(null);

    const res = await RouteSuggestionsApi.updateRouteSuggestion({
      routeSuggestionUuid: suggestedRoute.uuid,
      updateRouteSuggestionDto: {
        ...rejectionFeedback,
        response,
        comparisonRouteSeenUuid: voyage?.activeRoute?.routeUuid,
        comparisonSeenGains,
      },
    });
    synchronizeQueryCache(res.data, queryClient);
    return Promise.resolve(res.data);
  };

  const onRejectionSuccess = useCallback(() => {
    trackAnalyticsEvent(AnalyticsEvent.SuggestionRejected, {
      ...suggestedRoute,
    });
    hideRouteRejectionFeedbackForm("submit");
  }, [hideRouteRejectionFeedbackForm, suggestedRoute, trackAnalyticsEvent]);

  const onAcceptanceSuccess = useCallback(() => {
    trackAnalyticsEvent(AnalyticsEvent.SuggestionAccepted, {
      ...suggestedRoute,
    });
  }, [trackAnalyticsEvent, suggestedRoute]);

  const {
    mutateAsync: rejectAndRemoveSuggestion,
    isLoading: rejectionIsLoading,
    isError: rejectionIsError,
    reset: resetRejection,
  } = useMutation(
    (feedback: RouteRejectionFeedback) =>
      respondToRouteSuggestion(RouteSuggestionResponse.Rejected, feedback),
    {
      onSuccess: onRejectionSuccess,
      retry: retryWayfinderAPIRequestOn500Error,
    }
  );

  const {
    mutateAsync: acceptSuggestionAndUpdateActiveRoute,
    isLoading: acceptanceIsLoading,
    isError: acceptanceIsError,
    reset: resetAcceptance,
  } = useMutation(
    () => respondToRouteSuggestion(RouteSuggestionResponse.Accepted),
    {
      onSuccess: onAcceptanceSuccess,
      retry: retryWayfinderAPIRequestOn500Error,
    }
  );

  const suggestionResponseIsLoading = rejectionIsLoading || acceptanceIsLoading;
  const suggestionResponseError =
    rejectionIsError || acceptanceIsError ? RESPONSE_ERROR : undefined;
  const resetSuggestionResponseError = useCallback(() => {
    resetAcceptance();
    resetRejection();
  }, [resetAcceptance, resetRejection]);

  return useMemo(
    () => ({
      acceptSuggestionAndUpdateActiveRoute,
      rejectAndRemoveSuggestion,
      resetSuggestionResponseError,
      suggestionResponseError,
      suggestionResponseIsLoading,
      suggestedRoute,
      createRouteSuggestion,
    }),
    [
      acceptSuggestionAndUpdateActiveRoute,
      rejectAndRemoveSuggestion,
      resetSuggestionResponseError,
      suggestedRoute,
      suggestionResponseError,
      suggestionResponseIsLoading,
      createRouteSuggestion,
    ]
  );
}
