import { Card } from "@mui/material";
import { extendedPalette } from "styles/theme";
import React, { useContext, useMemo, useState } from "react";
import {
  RouteExplorerTable,
  RouteRowData,
} from "components/sidebar/RouteExplorer/RouteExplorerTable";
import { useCurrentRoutesToCompare } from "components/WayfinderApp/CurrentSession/contexts";
import { UseManyRoutesResult } from "contexts/RouteStoreContext/use-many-routes";
import { useRouteStyles } from "styles/use-route-styles";
import { RouteStyle } from "components/WeatherAlongRoutePlot";
import { getRouteSchedule } from "helpers/getRouteSchedule";
import { useCurrentUrl } from "helpers/navigation";
import { RouteExportForm } from "components/modals/RouteExportForm";
import { VoyageMenuStateContext } from "components/sidebar/Voyage/use-voyage-menu-state";
import { compact } from "lodash";

export const COMPARISON_TABLE_HEIGHT = 400;

const MISSING_DATA_STRING = "--";
// todo fix this type
const getRowData = (
  route: UseManyRoutesResult,
  routeStylesList: Record<string, RouteStyle>
): RouteRowData => {
  const {
    departureScheduleElement = undefined,
    arrivalScheduleElement = undefined,
  } = route.route ? getRouteSchedule(route.route) : {};
  return {
    uuid: route.uuid,
    routeName: route.route?.routeInfo.routeName ?? null,
    routeColor: routeStylesList[route.uuid].color,
    eta: arrivalScheduleElement?.eta ?? MISSING_DATA_STRING,
    etd: departureScheduleElement?.etd ?? MISSING_DATA_STRING,
    formattedTime:
      route.routeSummaryData?.absoluteSummary.time.formattedTime ??
      MISSING_DATA_STRING,
    formattedTimeCostDollar:
      route.routeSummaryData?.absoluteSummary.time.formattedTimeExpense ??
      MISSING_DATA_STRING,
    formattedFuelCostDollar:
      route.routeSummaryData?.absoluteSummary.fuel.total.formattedFuelExpense ??
      MISSING_DATA_STRING,
    formattedTotalCostDollar:
      route.routeSummaryData?.absoluteSummary.voyage.formattedVoyageExpenses ??
      MISSING_DATA_STRING,
    formattedFuelMT:
      route.routeSummaryData?.absoluteSummary.fuel.total.formattedFuel ??
      MISSING_DATA_STRING,
    formattedEmissionsMT:
      route.routeSummaryData?.absoluteSummary.emissions
        .formattedTotalEmissions ?? MISSING_DATA_STRING,
    formattedAvgSpeedKts:
      route.routeSummaryData?.absoluteSummary.avgSpeedRemaining
        .formattedAvgSpeedRemaining ?? MISSING_DATA_STRING,
  };
};

export const ConnectedRouteExplorerTable: React.FC<{}> = () => {
  const {
    hideExportForm,
    exportFormVisible,
    exportError,
    downloadRoutesWithFormats,
    onDeleteRoute,
  } = useContext(VoyageMenuStateContext);
  const { routesToCompare } = useCurrentRoutesToCompare();

  const routeStylesList = useRouteStyles();
  const currentUrl = useCurrentUrl();
  const [routesToCompareUuids, setRoutesToCompareUuids] = useState<string[]>(
    routesToCompare.map((r) => r.uuid)
  );

  const visibleRoutes = routesToCompare.filter((r) =>
    routesToCompareUuids.includes(r.uuid)
  );

  const routeList: RouteRowData[] = useMemo(
    () =>
      visibleRoutes.map((r: UseManyRoutesResult) =>
        getRowData(r, routeStylesList)
      ),
    [routeStylesList, visibleRoutes]
  );

  const handleRemoveRoute = (routeUuid: string) => {
    const newRoutesToCompareUuids = routesToCompareUuids.filter(
      (uuid) => uuid !== routeUuid
    );
    setRoutesToCompareUuids(newRoutesToCompareUuids);
    onDeleteRoute(routeUuid);
  };

  return (
    <Card
      sx={{
        maxHeight: `${COMPARISON_TABLE_HEIGHT}px`,
        borderTop: `1px solid ${extendedPalette.neutral}`,
        overflow: "auto",
      }}
    >
      <RouteExplorerTable
        routeList={routeList}
        onRemoveRoute={handleRemoveRoute}
      />
      <RouteExportForm
        show={exportFormVisible}
        close={hideExportForm}
        onSubmit={downloadRoutesWithFormats}
        routes={compact(visibleRoutes.map((r) => r.route))}
        error={exportError}
      />
    </Card>
  );
};
