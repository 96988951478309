import parser from "fast-xml-parser";
import {
  Route,
  Waypoint,
  RouteInfo,
  Waypoints,
  Schedule,
  Schedules,
} from "shared-types/RouteTypes";
// this is a json version of the rtz schema douwnloaded from CIRM
import { v4 as uuid } from "uuid";
import { isEmpty } from "lodash";
import { getWaypointsForExport } from "../get-waypoints-for-export";
import { fixDataType } from "../validation";

/*

When importing routes, we blindly follow the shape of the xml tree under `extensions`,
except that we put sofar extensions at the root level of the `extensions` object as ashorthand.

When exporting, we mirror it all back out, but we put the sofar extensions back into a sensible
structure for xml, eg an `extension` node with an attrubute corresponding to each sofar extension

// Sofar json route containing objects from vendor xml:

waypoint: {
  extensions: {
    someSofarExtension: 1, // our shorthand puts them here. should be nested in `extension` and set as an attribute
    extension: {
      name:"AdditionalLegInfo", // this is the name of the non-sofar `extension`
      manufacturer: "Furno", // according to spec, should be in an attribute of `extension`
      version: 1,
      property: {
        margin: 0, // a string or number should always be an attribute
        parallelLine1: 0 // a string or number should always be an attribute
      }
    }
  }
}
waypoint {
  extensions:
    extension: [
      {
        name:"AdditionalLegInfo2",
        manufacturer: "Furno", // according to spec, should be in an attribute of `extension` 
        property: {
          margin: 0,
          parallelLine1: 0
        }
      },{
        name="AdditionalLegInfo2",
        manufacturer: "Furno", // according to spec, should be in an attribute of `extension` 
        property: {
          margin: 0,
          parallelLine1: 0
        }
      }
    ]
  }
}

// Sofar json route altered so it can be passed to the xml outputter

waypoint: {
  extensions: {
    extension: [
      {
        manufacturer: "Sofar_Ocean", // according to spec, should be in an attribute
        name: "WaypointExtension", // give it a name based on parent
        attributes:{
          someExtension: 1 // put all sofar values in attributes
        }
      },
      {
        manufacturer: "Furno", // according to spec, should be in an attribute
        name: AdditionalLegInfo,
        property: { // any node under extensions should have number and string values set as attributes
          attributes:{
            margin: 0,
            parallelLine1: 0
          }
        }
      },
      extension: [
        {
          manufacturer: "Furno",
          name: "AdditionalLegInfo2",
          property: {
            attributes:{
              margin: 0,
              parallelLine1: 0
            }
          }
        },{
          manufacturer: "Furno",
          name: "AdditionalLegInfo2",
          property: {
            attributes:{
              margin: 0,
              parallelLine1: 0
            }
          }
        }
      ]
    ]
  }
}

*/

const SOFAR_EXTENSION_MANUFACTURER = "Sofar_Ocean";
const SOFAR_EXTENSION_VERSION = "1.0";

type PropertyWithoutChildren = number | string | boolean | undefined;
type PropertyValue = PropertyWithoutChildren | object[] | object | undefined;

// Sofar extension values are at the root level of the `extensions` object, but we need them to be nested in
// an `extension` object or array to get the right xml shape out
// note this has no "s" after "Extension"
type ParseableExtensionObject<T> = { [K in keyof T]: T[K] } & {
  manufacturer: typeof SOFAR_EXTENSION_MANUFACTURER;
  version: typeof SOFAR_EXTENSION_VERSION;
  name: string;
};
// note this DOES HAVE an "s" after "Extension"
type ParseableExtensionsObject<T> = {
  extension: ParseableExtensionObject<T> | ParseableExtensionObject<T>[];
};
// this type says that any child could have extensions and that they must have the right attributes
type ParseableObjectWithExtensions<T extends { extensions?: object }> = {
  [K in string]?: ParseableObjectWithExtensions<T> | PropertyWithoutChildren;
} & {
  extensions?: ParseableExtensionsObject<T["extensions"]>;
} & T;

type RouteWithParseableArrays = {
  [K in keyof Route]: Route[K] extends Waypoints
    ? Omit<Waypoints, "waypoints"> & { waypoint: Waypoints["waypoints"] }
    : Route[K] extends Schedule
    ? Omit<Schedules, "schedules"> & { schedule: Schedules["schedules"] }
    : Route[K];
};

type ElementWithAttributes<T> = {
  attributes?: Partial<Record<keyof T, number | string>>;
} & {
  [K in keyof T]: ElementWithAttributes<T[K]> | PropertyValue;
};

type ParseableRouteWithAttributes = ElementWithAttributes<RouteWithParseableArrays>;

function omit(key: string, obj: any) {
  if (!obj) return obj;
  const { [key]: omitted, ...rest } = obj;
  return rest;
}

/**
 * Get the definitions in the schema that have properties
 * TODO consider updating this to return them for the name of the element, rather than types like GMPoint, which are being lost
 * See comments below
 */
// const definitionsWithProperties: DefinitionsWithProperties = Object.fromEntries(
//   Object.entries(rtzSchema.definitions).filter((entry): entry is [
//     any,
//     { properties: any } & typeof entry[1]
//   ] => Boolean(entry[1].hasOwnProperty("properties")))
// );

/**
 * Uses the schema definitions to group and order attributes, rather than just checking their type.
 *
 * TODO: follow definitions references so we get the nodes whose types are not the same as their name
 * such as GMPoint, and enforce attribute order.
 *
 * Until these items are completed, this method will not be used, in favor of simply using data type and implicit ordering in route json.
 *
 * @param elementName
 * @param element
 * @param isExtensionDescendant
 * @returns
 */
// const groupElementAttributesWithSchemaDefinitions = <
//   T extends { [K in string]: any }
// >(
//   elementName: string,
//   element: T,
//   isExtensionDescendant?: boolean
// ): ElementWithAttributes<T> => {
//   let resultElement: ElementWithAttributes<T> = element;
//   // Move properties that are defined in definitions as attributes into an `attributes` child
//   // this may actually just be any string or number, but not sure about that, so using spec
//   const definitionName = Object.keys(definitionsWithProperties).find((d) =>
//     d.match(new RegExp(`^${elementName}$`, "i"))
//   ) as keyof typeof definitionsWithProperties | undefined;
//   const properties =
//     definitionName && definitionsWithProperties[definitionName]["properties"];
//   if (properties) {
//     const attributes = Object.fromEntries(
//       (Object.entries(element) as [keyof T, string | number][]).filter(
//         // coersion needed because entries does not get the key types right
//         (entry) => {
//           const property = entry[0];
//           //for (let property in properties) {
//           // check if the schema assigns the property an attribute name
//           if (
//             entry[1] !== undefined &&
//             entry[1] !== null &&
//             properties[property]?.attributeName
//           ) {
//             // and make sure it is something that can be an attribute
//             if (
//               typeof element[property] === "string" ||
//               typeof element[property] === "number"
//             ) {
//               return true;
//             } else {
//               throw Error(
//                 `Could not assign type "${typeof element[
//                   property
//                 ]}" to an xml attribute while outputting "${property}" of "${elementName}" during route export`
//               );
//             }
//           }
//           //}
//           return false;
//         }
//       )
//     ) as { [K in keyof T]: string | number }; // coersion needed because fromEntries does not get the key types right

//     resultElement = {
//       ...element,
//       attributes,
//     };
//   }
//   // The spec does not say how to handle attributes in extensions (they are vendor specific)
//   // but in all of the CIRM and customer examples, all strings and numbers go into attributes
//   const attributeDataTypes = ["string", "number", "boolean"];
//   if (elementName === "extension" || isExtensionDescendant) {
//     const attributes = Object.fromEntries(
//       Object.entries(resultElement).filter((entry) =>
//         attributeDataTypes.includes(typeof entry[1])
//       )
//     );
//     resultElement = {
//       ...resultElement,
//       attributes: { ...resultElement.attributes, ...attributes },
//     };
//   }

//   // now that the attributes are in the attribute child, delete them from the parent
//   for (let attributeName in resultElement.attributes) {
//     delete resultElement[attributeName];
//   }

//   return resultElement;
// };

/**
 * Format a number for output.
 *
 * There is no clear spec on degrees of decimal precision. It appers that there can be anywhere between 1 and 8
 * decimal places for a value that is not a "version", "revision" or "id".
 *
 * Here are the resources used to review the spec:
 * http://cirm.org/rtz/index.html
 * https://s3-eu-west-1.amazonaws.com/stm-stmvalidation/uploads/20160420144429/ML2-D1.3.2-Voyage-Exchange-Format-RTZ.pdf
 *
 * @param valueKeypath this can be a property name, or it can be any other value that helps disabiguate properties with the same name, eg `waypoint.id` vs `schedule.id`
 * @param value the value to format
 * @returns formatted value
 */
const INTEGER_VALUE_KEYPATHS = ["revision", "id"];
const MAX_DECIMAL_DEGREES_OF_PRECISION = 8;
const formatValue = (
  valueKeypath: string,
  value: string | number | boolean
): string => {
  if (typeof value === "number") {
    if (valueKeypath === "version") return value.toFixed(1);
    if (INTEGER_VALUE_KEYPATHS.includes(valueKeypath)) return value.toFixed(0);
    // show up to, but not always, MAX_DECIMAL_DEGREES_OF_PRECISION after the decimal point
    return parseFloat(
      value.toFixed(MAX_DECIMAL_DEGREES_OF_PRECISION)
    ).toString();
  }
  return value.toString();
};

const groupElementAttributesByDataType = <T extends { [K in string]: any }>(
  element: T
): ElementWithAttributes<T> => {
  const attributeDataTypes = ["string", "number", "boolean"];
  const attributes = Object.fromEntries(
    Object.entries(element).filter((entry) =>
      attributeDataTypes.includes(typeof entry[1])
    )
  );
  const resultElement: ElementWithAttributes<T> = {
    ...element,
    attributes: { ...element.attributes, ...attributes },
  };
  // now that the attributes are in the attribute child, delete them from the parent
  for (const attributeName in resultElement.attributes) {
    delete resultElement[attributeName];
  }

  return resultElement;
};

const traverseObjectPropertiesAndAddAttributes = (
  childName: string,
  child: Record<string, any>,
  isExtensionDescendant?: boolean
) => {
  const newChild: typeof child = { ...child };
  for (const property in newChild) {
    if (typeof newChild[property] === "object") {
      if (!Array.isArray(newChild[property])) {
        // if the property name is not an element name, the element will be unchanged
        newChild[property] = traverseObjectPropertiesAndAddAttributes(
          property,
          newChild[property],
          isExtensionDescendant || childName === "extension"
        );
      } else {
        newChild[property] = [...newChild[property]];
        newChild[property].forEach(
          (
            item: typeof newChild[keyof typeof newChild][number],
            index: number
          ) => {
            newChild[property][
              index
            ] = traverseObjectPropertiesAndAddAttributes(
              property,
              item,
              isExtensionDescendant || childName === "extension"
            );
          }
        );
      }
    }
  }
  const elementWithAttributes = groupElementAttributesByDataType(newChild);
  // If we are not inside vendor-specific branches of the route, format the values
  if (!isExtensionDescendant) {
    for (const attributeName in elementWithAttributes.attributes) {
      const value = elementWithAttributes.attributes[attributeName];
      if (value !== undefined) {
        elementWithAttributes.attributes[attributeName] = formatValue(
          attributeName,
          value
        );
      }
    }
  }
  return elementWithAttributes;
};
const groupRouteAttributes = (
  route: RouteWithParseableArrays
): ParseableRouteWithAttributes => {
  // using coersion here because it is not worth figuring out the typescript for what we get back
  return traverseObjectPropertiesAndAddAttributes("Route", {
    ...route,
  }) as ParseableRouteWithAttributes;
};

const getParseableObjectWithExtensions = <
  T extends {
    extensions?: object & {
      extension?:
        | ParseableExtensionObject<object>
        | ParseableExtensionObject<object>[];
    };
  }
>(
  object: T,
  extensionName: string
) => {
  const { extension: existingVendorExtension, ...sofarRootLevelExtensions } =
    object.extensions ?? {};
  // if there are no sofar extensions then just return a copy of the object ( it must have only vendor extensions )
  if (isEmpty(sofarRootLevelExtensions)) return { ...object };

  // define the extesion object that will hold the sofar data
  let newExtension:
    | ParseableExtensionObject<typeof sofarRootLevelExtensions>
    | ParseableExtensionObject<object>[] = {
    manufacturer: "Sofar_Ocean",
    name: extensionName,
    version: "1.0",
    ...sofarRootLevelExtensions,
  };
  // if there are indeed sofar extensions
  // check if there are already vendor extensions in the object.
  if (existingVendorExtension) {
    // if there is an array
    if (Array.isArray(existingVendorExtension)) {
      // then add sofar extension to the array
      newExtension = [...existingVendorExtension, newExtension];
    } else {
      // otherwise convert to an array and add both
      newExtension = [existingVendorExtension, newExtension];
    }
  }

  // TODO THIS IS A TEMPORARY FIX FOR A PROBLEM.
  // on 2020-09-14 we had an issue with the Navig8 GreatEpsilon route.
  // Turns out, the RTZ from Polaris has a property 'guidanceType' in the schedule elements that is an array.
  // According to Pieter Smit, this is a Polaris property that should not occur on production. Well, it does for some reason..
  //
  // The exporter causes this to be exported as sub node which seems to break the import on the captain's ECDIS.
  // We should devise a way to handle array types here, or just simply, to ignore them.
  // This fix bellow is obviously not sustainable and has to be revised. But right now, we want to get the route to the captain.
  //
  // See https://sofarocean.slack.com/archives/C029C4UGJRF/p1631628528022800 for details.
  if ((Object.keys(newExtension) as string[]).includes("guidanceType")) {
    newExtension = { ...omit("guidanceType", newExtension) };
  }

  const newObject = {
    ...object,
    extensions: {
      extension: newExtension,
    } as ParseableExtensionsObject<T["extensions"]>,
  } as ParseableObjectWithExtensions<T>;

  return newObject;
};

const traverseObjectAndMakeExtensionsParseable = (
  object: { extensions?: object },
  objectName: string
) => {
  let result = { ...object };
  const { extensions, ...otherProperties } = object;
  if (extensions) {
    result = getParseableObjectWithExtensions(
      object,
      `${objectName.charAt(0).toUpperCase() + objectName.slice(1)}Extensions`
    );
  }
  for (const childName in otherProperties) {
    const childValue = (otherProperties as Record<string, any>)[childName];
    if (childValue && typeof childValue === "object") {
      if (Array.isArray(childValue)) {
        const newArray = [];
        for (const item of childValue) {
          newArray.push(
            traverseObjectAndMakeExtensionsParseable(item, childName)
          );
        }
        (result as Record<string, any>)[childName] = newArray;
      } else {
        (result as Record<string, any>)[
          childName
        ] = traverseObjectAndMakeExtensionsParseable(childValue, childName);
      }
    }
  }
  return result;
};

const getRouteWithParsableExtensions = (route: RouteWithParseableArrays) => {
  return traverseObjectAndMakeExtensionsParseable(route, "Route");
};

export const rtzRouteToXmlString = (
  route: Route,
  enableLegacyRtzXmlFormatting?: boolean,
  includeCalculatedSchedule?: boolean
) => {
  const waypoints = getWaypointsForExport(route, includeCalculatedSchedule);

  const schedules = !route.schedules // destructure either the schedules if they exist, or undefined (nothing) if they do not exist
    ? undefined
    : {
        schedules: {
          ...omit("schedules", route.schedules),
          // singular name instead
          schedule: route.schedules?.schedules?.map((schedule) => ({
            ...omit("calculated", omit("manual", schedule)),
            // singular name instead
            ...(!schedule.manual
              ? undefined
              : {
                  manual: {
                    ...omit("scheduleElements", schedule.manual),
                    // singular name instead
                    scheduleElement: schedule.manual?.scheduleElements,
                  },
                }),
            // singular name instead
            ...(!schedule.calculated || !includeCalculatedSchedule
              ? undefined
              : {
                  calculated: {
                    ...omit("scheduleElements", schedule.calculated),
                    // singular name instead
                    scheduleElement: schedule.calculated?.scheduleElements,
                  },
                }),
          })),
        },
      };
  let parseableRoute: RouteWithParseableArrays;
  if (enableLegacyRtzXmlFormatting) {
    // keep the option to use the old format available until all customers are verified to have success with new format
    parseableRoute = {
      ...route,
      waypoints: {
        // leave out the "route.waypoints.waypoints" node and then add it back in below as "waypoint"
        ...omit("waypoints", route.waypoints),
        // singular name instead
        // also, get only the waypoints that we want to export
        waypoint: waypoints,
        defaultWaypoint: { id: Math.max(...waypoints.map((w) => w.id)) + 1 },
      },
      ...schedules,
    };
  } else {
    // The array properties in the js route need to be renamed to singular node names
    // before they are output as xml, otherwise we get plural names in individual elements
    // e.g. <waypoints id="1" name="..." ...><waypoints>
    parseableRoute = {
      routeInfo: route.routeInfo,
      waypoints: {
        // leave out the "route.waypoints.waypoints" node and then add it back in below as "waypoint"
        ...omit("defaultWaypoint", omit("waypoints", route.waypoints)),
        // singular name instead
        // also, get only the waypoints that we want to export
        waypoint: waypoints.map(({ leg, position, ...rest }, index) => ({
          position,
          ...(index > 0 ? { leg } : undefined),
          ...rest,
        })),
      },
      ...schedules,
      extensions: omit("readonly", omit("isSimulated", route.extensions)),
      version: route.version,
    };
  }

  const routeWithParsableExtensions = getRouteWithParsableExtensions(
    parseableRoute
  );

  const parsableRouteWithAttributes: ParseableRouteWithAttributes = groupRouteAttributes(
    routeWithParsableExtensions as RouteWithParseableArrays
  );
  const xmlSerializer = new parser.j2xParser({
    format: true,
    attrNodeName: "attributes",
    supressEmptyNode: true,
  });

  const routeWithXMLNS = {
    ...parsableRouteWithAttributes,
    attributes: {
      ...parsableRouteWithAttributes.attributes,
      xmlns: "http://www.cirm.org/RTZ/1/0",
    },
  };

  return `<?xml version="1.0" encoding="UTF-8"?>\r\n${xmlSerializer
    .parse({
      route: {
        ...routeWithXMLNS,
      },
    })
    .replace(/([^\r])\n/gi, "$1\r\n")}`;
};

/**
 * The `extensions` child of route descendant has `extension` children.
 * Move these into properties of the `extensions` object instead
 * @param extensionsNode
 */
const moveSofarExtensionToExtensionsProperties = (
  extensionsNode: ObjectWithExtensionProperty
) => {
  const extension = extensionsNode["extension"];
  if (extension !== undefined) {
    // flatten array into properties named by each extension's name property, and remove name from extension's properties
    // if there are more than one extension with the same name, put them in an array and assign it to the property of that name
    if (Array.isArray(extension)) {
      // if the extension node is an array...
      for (const item of extension) {
        // (note: the spec does not apper to require that all extensions in a list have the same name)
        // if array item is a sofar extension, move it into the root level of the extensions
        if (item.manufacturer === SOFAR_EXTENSION_MANUFACTURER) {
          addExtensionPropertiesToParent(item, extensionsNode);
        }
      }
      // if there were any extensions that did not belong to sofar
      // keep them as either an object (if only one) or an array (if more)
      const remainingExtensions = extension.filter(
        (item) => item.manufacturer !== SOFAR_EXTENSION_MANUFACTURER
      );
      if (remainingExtensions.length > 1) {
        extensionsNode["extension"] = remainingExtensions;
      } else if (remainingExtensions.length === 1) {
        extensionsNode["extension"] = remainingExtensions[0];
      } else {
        // otherwise delete the extensions array entirely
        delete extensionsNode["extension"];
      }
    } else {
      // if the node is not an array,
      // and if it is a sofar extension, move the properties into the root level of the extensions
      if (extension.manufacturer === SOFAR_EXTENSION_MANUFACTURER) {
        addExtensionPropertiesToParent(extension, extensionsNode);
        delete extensionsNode.extension;
      }
    }
  }
};

// These types are used when preparing a route for export
type ObjectWithExtensionProperty = {
  [K in string]: any;
} & {
  extension?:
    | ParseableExtensionObject<{ [K in string]: PropertyValue }>
    | ParseableExtensionObject<{ [K in string]: PropertyValue }>[];
};
const addExtensionPropertiesToParent = (
  extension: ParseableExtensionObject<{ [K in string]: PropertyValue }>,
  parent: {
    [K in string]: any;
  }
) => {
  const { name, version, manufacturer, ...properties } = extension;
  for (const propertyName in properties) {
    parent[propertyName] = properties[propertyName];
  }
};

/**
 * The xml parser we use puts the `<extension>` childrent of an `<extensions>` node directly into
 * either an `extension` property of the corresponding js `extensions` object, or if there are many of them,
 * it adds them to an array and assigns that to the `extensions.extension` property
 */
const traverseObjectPropertiesAndConvertSofarExtensions = (
  parent: Record<string, any>
) => {
  for (const childName in parent) {
    if (childName === "extensions" && parent.extensions.extension) {
      moveSofarExtensionToExtensionsProperties(parent.extensions);
    } else {
      if (typeof parent[childName] === "object") {
        if (Array.isArray(parent[childName])) {
          for (const item of parent[childName]) {
            if (typeof parent[childName] === "object") {
              // note: there are only 1d arrays in the spec
              traverseObjectPropertiesAndConvertSofarExtensions(item);
            }
          }
        } else {
          traverseObjectPropertiesAndConvertSofarExtensions(parent[childName]);
        }
      }
    }
  }
};

const convertSofarExtensionsInRouteIntoProperties = (route: Route) => {
  traverseObjectPropertiesAndConvertSofarExtensions(route);
};

const traverseObjectPropertiesAndFixDataTypes = (
  parent: Record<string, any>
) => {
  for (const childName in parent) {
    // fix problems in Navig8 Totem routes
    if (childName === "waypintId") {
      parent.waypointId = parent[childName];
      delete parent[childName];
    }
    if (childName === "planSpeedMax") {
      parent.speedMax = parent[childName];
      delete parent[childName];
    }
    if (childName === "planSpeedMin") {
      parent.speedMin = parent[childName];
      delete parent[childName];
    }

    if (typeof parent[childName] === "string" && childName !== "version") {
      if (parent[childName] === "") {
        delete parent[childName];
      } else {
        parent[childName] = fixDataType(childName, parent[childName]);
      }
    } else {
      if (typeof parent[childName] === "number" && childName === "version") {
        const fixedVersion = fixDataType(childName, parent[childName]);
        parent[childName] = fixedVersion;
      }
      if (typeof parent[childName] === "object") {
        if (Array.isArray(parent[childName])) {
          for (const item of parent[childName]) {
            if (typeof parent[childName] === "object") {
              // note: there are only 1d arrays in the spec
              traverseObjectPropertiesAndFixDataTypes(item);
            }
          }
        } else {
          traverseObjectPropertiesAndFixDataTypes(parent[childName]);
        }
      }
    }
  }
};

const fixDataTypes = (route: Route) => {
  traverseObjectPropertiesAndFixDataTypes(route);
};

export const xmlStringToRtzRoute = (xmlString: string): Route => {
  const options = {
    ignoreAttributes: false,
    attributeNamePrefix: "",
    parseAttributeValue: true,
  };
  const jsonRoute = parser.parse(xmlString, options);

  if (!jsonRoute.route) {
    throw Error("Could not decode xml route file.");
  }

  const waypoints: Waypoint[] = jsonRoute.route?.waypoints?.waypoint
    ? jsonRoute.route.waypoints.waypoint.map(
        (wp: any): Waypoint => wp as Waypoint
      )
    : [];

  // fixme position lat and lon are turning into nodes, not attributes

  const route = {
    version: jsonRoute.route.version,
    extensions: {
      readonly: false,
      uuid: uuid(), // TODO should this be fresh every time the same route is imported? Should this route be compared to existing routes before getting a uuid?
    },
    routeInfo: jsonRoute.route.routeInfo as RouteInfo,
    waypoints: {
      waypoints,
      defaultWaypoint: { id: Math.max(...waypoints.map((w) => w.id)) + 1 },
    } as Waypoints,
    schedules: {
      // schedules must be an array even if parser says it is one object
      schedules: [jsonRoute.route?.schedules?.schedule].flat().filter((s) => s),
    },
  };

  // array values like `scheduleElement` have plural name in wayfinder
  route.schedules.schedules.forEach((s) => {
    if (s) {
      const { manual, calculated } = s;
      if (manual) {
        // there are routes out there with scheduleElements misspelled as "sheduleElement"
        // scheduleElements must be an array even if existing schedule elements are objects
        const elements = manual.scheduleElement || manual.sheduleElement;
        manual.scheduleElements = [elements].flat().filter((s) => s);
        delete manual.scheduleElement;
        delete manual.sheduleElement;
      }
      if (calculated) {
        const elements =
          calculated.scheduleElement || calculated.sheduleElement;
        calculated.scheduleElements = [elements].flat().filter((s) => s);
        delete calculated.scheduleElement;
        delete calculated.sheduleElement;
      }
    }
  });

  convertSofarExtensionsInRouteIntoProperties(route);
  fixDataTypes(route);

  return route;
};
