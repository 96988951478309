import {
  UpdateVoyageDto,
  VesselDto,
  VoyageDto,
} from "@sofarocean/wayfinder-typescript-client";
import { useCallback, useContext, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  synchronizeQueryCache,
  UndifferentiatedSynchronizationDto,
} from "../helpers/crystalGlobeApi";
import AnalyticsContext, { AnalyticsEvent } from "../contexts/Analytics";
import { CrystalGlobeApiContext } from "../contexts/CrystalGlobeApiContext";
import { retryWayfinderAPIRequestOn500Error } from "../helpers/fetch/fetch-helpers";
import { WayfinderAPIError } from "../contexts/CrystalGlobeApiContext/index";

export function useUpdateVoyage() {
  const queryClient = useQueryClient();

  const { VoyagesApi } = useContext(CrystalGlobeApiContext);
  const { trackAnalyticsEvent } = useContext(AnalyticsContext);

  const updateVoyageOnServer = async (
    voyageUuid: string,
    voyage: UpdateVoyageDto
  ) => {
    return VoyagesApi.updateVoyage({
      voyageUuid,
      updateVoyageDto: voyage,
    }) as Promise<{ data: UndifferentiatedSynchronizationDto }>;
  };

  const onSaveSuccess = useCallback(
    (results: (VoyageDto | VesselDto)[]) => {
      results.forEach((r) =>
        trackAnalyticsEvent(
          r.__type === "Voyage"
            ? AnalyticsEvent.UpdatedVoyage
            : AnalyticsEvent.UpdatedVessel,
          { type: r.__type, uuid: r.uuid }
        )
      );
    },
    [trackAnalyticsEvent]
  );

  const {
    mutateAsync: updateVoyage,
    isLoading: updateVoyageIsLoading,
    isError: updateVoyageIsError,
    isSuccess: updateVoyageIsSuccess,
    reset: resetUpdateVoyage,
  } = useMutation<
    (VoyageDto | VesselDto)[],
    WayfinderAPIError,
    { voyageUuid: string; voyage: UpdateVoyageDto }
  >(
    async ({
      voyageUuid,
      voyage,
    }: {
      voyageUuid: string;
      voyage: UpdateVoyageDto;
    }) => {
      const res = await updateVoyageOnServer(voyageUuid, voyage);
      synchronizeQueryCache(res.data, queryClient);
      return res.data.updated as (VoyageDto | VesselDto)[];
    },
    {
      onSuccess: onSaveSuccess,
      retry: retryWayfinderAPIRequestOn500Error,
    }
  );

  return useMemo(
    () => ({
      updateVoyage,
      updateVoyageIsLoading,
      updateVoyageIsError,
      resetUpdateVoyage,
      updateVoyageIsSuccess,
    }),
    [
      resetUpdateVoyage,
      updateVoyage,
      updateVoyageIsError,
      updateVoyageIsLoading,
      updateVoyageIsSuccess,
    ]
  );
}
