import { UpdateArrivalWindowDto } from "@sofarocean/wayfinder-typescript-client";

import { ArrivalWindowType } from "helpers/form-fields/use-arrival-time-menu-state";

export const constructArrivalWindowDto = (
  timeOfArrivalStart?: string,
  timeOfArrivalEnd?: string,
  windowMode?: ArrivalWindowType
): UpdateArrivalWindowDto => {
  if (!windowMode) {
    const {
      timeOfArrivalStart: start,
      timeOfArrivalEnd: end,
    } = getEtaEtdMenuValueFromTimes(timeOfArrivalStart, timeOfArrivalEnd);

    return {
      startTimestamp: start,
      endTimestamp: end,
    };
  }

  let startTimestamp: string | null | undefined = null;
  let endTimestamp: string | null | undefined = null;

  switch (windowMode) {
    case "on": {
      if (timeOfArrivalStart) {
        startTimestamp = timeOfArrivalStart;
        endTimestamp = timeOfArrivalStart;
      }
      break;
    }
    case "after": {
      if (timeOfArrivalStart) {
        startTimestamp = timeOfArrivalStart;
        endTimestamp = null;
      }
      break;
    }
    case "before": {
      if (timeOfArrivalEnd) {
        startTimestamp = null;
        endTimestamp = timeOfArrivalEnd;
      }
      break;
    }
    case "between": {
      if (timeOfArrivalStart && timeOfArrivalEnd) {
        startTimestamp = timeOfArrivalStart;
        endTimestamp = timeOfArrivalEnd;
      }
      break;
    }
    case "none": {
      startTimestamp = null;
      endTimestamp = null;
      break;
    }
    default: {
      startTimestamp = null;
      endTimestamp = null;
    }
  }
  return {
    startTimestamp,
    endTimestamp,
  };
};

export const getEtaEtdMenuValueFromTimes = (
  startTimestamp?: string | null,
  endTimestamp?: string | null
): {
  timeOfArrivalStart: string | null | undefined;
  timeOfArrivalEnd: string | null | undefined;
  windowMode: ArrivalWindowType;
} => {
  if (endTimestamp && startTimestamp && endTimestamp === startTimestamp) {
    const arrivalTimeStart = startTimestamp;
    return {
      timeOfArrivalStart: arrivalTimeStart,
      timeOfArrivalEnd: arrivalTimeStart,
      windowMode: "on",
    };
  }

  if (!endTimestamp && startTimestamp) {
    return {
      timeOfArrivalStart: startTimestamp,
      timeOfArrivalEnd: null,
      windowMode: "after",
    };
  }

  if (endTimestamp && !startTimestamp) {
    return {
      timeOfArrivalStart: null,
      timeOfArrivalEnd: endTimestamp,
      windowMode: "before",
    };
  }

  if (endTimestamp && startTimestamp) {
    return {
      timeOfArrivalStart: startTimestamp,
      timeOfArrivalEnd: endTimestamp,
      windowMode: "between",
    };
  }

  return {
    timeOfArrivalStart: null,
    timeOfArrivalEnd: null,
    windowMode: "none",
  };
};
