import { useCurrentVoyageLeg } from "components/WayfinderApp/CurrentSession/contexts";
import AnalyticsContext, { AnalyticsEvent } from "contexts/Analytics";
import { findPort } from "contexts/MultilegVoyageEditContext/useLegInEdit";
import { PortContext } from "contexts/PortContext";
import React, { useContext, useMemo, useState, useCallback } from "react";

type TimezoneContextType = {
  isLocalTimeEnabled: boolean;
  handleLocalTimeSwitch: () => void;
  currentVoyageDestinationTimezone: string | null;
  currentVoyageDepartureTimezone: string | null;
};

const TimezoneContextDefaults: TimezoneContextType = {
  isLocalTimeEnabled: true,
  handleLocalTimeSwitch: () => undefined,
  currentVoyageDepartureTimezone: null,
  currentVoyageDestinationTimezone: null,
};

export const TimezoneContext = React.createContext<TimezoneContextType>(
  TimezoneContextDefaults
);

const useTimezoneState = (): TimezoneContextType => {
  const { trackAnalyticsEvent } = useContext(AnalyticsContext);
  const [isLocalTimeEnabled, setIsLocalTimeEnabled] = useState(true);
  const { voyage } = useCurrentVoyageLeg();
  const { ports } = useContext(PortContext);

  const handleLocalTimeSwitch = useCallback(() => {
    setIsLocalTimeEnabled(!isLocalTimeEnabled);
    trackAnalyticsEvent(AnalyticsEvent.ToggledLocalTimeSwitch, {
      updatedSwitchStatus: !isLocalTimeEnabled ? "On" : "Off",
    });
  }, [isLocalTimeEnabled, trackAnalyticsEvent]);

  const currentVoyageDepartureTimezone =
    voyage?.departurePort?.timezone ??
    findPort(voyage?.departurePortName, ports)?.timezone ??
    null;

  const currentVoyageDestinationTimezone =
    voyage?.destinationPort?.timezone ??
    findPort(voyage?.destinationPortName, ports)?.timezone ??
    null;

  return useMemo(
    () => ({
      isLocalTimeEnabled: isLocalTimeEnabled,
      handleLocalTimeSwitch,
      currentVoyageDepartureTimezone,
      currentVoyageDestinationTimezone,
    }),
    [
      isLocalTimeEnabled,
      handleLocalTimeSwitch,
      currentVoyageDepartureTimezone,
      currentVoyageDestinationTimezone,
    ]
  );
};

export const TimezoneContextProvider: React.FC<{}> = ({ children }) => {
  const state = useTimezoneState();
  return (
    <TimezoneContext.Provider value={state}>
      {children}
    </TimezoneContext.Provider>
  );
};
