import { AisDataDto } from "@sofarocean/wayfinder-typescript-client";
import { isNil, maxBy } from "lodash";
import { DateTime } from "luxon";
import { useContext, useEffect, useMemo, useRef } from "react";
import { useInfiniteQuery } from "react-query";
import { QUERY_KEY_STRINGS } from "shared-types";
import config from "../../config";
import { CrystalGlobeApiContext } from "../../contexts/CrystalGlobeApiContext";

const failedVesselUuids: Set<string> = new Set([]);

type UseVesselPositionHistoryOptions = {
  beforeTimestamp?: Date | null;
  afterTimestamp?: Date | null;
  enabled: boolean;
};

export const useVesselPositionHistory = (
  vesselUuid: string | undefined,
  options: UseVesselPositionHistoryOptions = {
    enabled: true,
  }
): {
  aisHistory: AisDataDto[];
  aisHistoryIsLoading: boolean;
  latestAis: AisDataDto | undefined;
} => {
  const { AisDataApi } = useContext(CrystalGlobeApiContext);

  // Cap the retrieval of ais data to 30 days ago if afterTimestamp is not provided, and use a ref
  // to avoid re-triggering the query when the component re-renders.
  const afterEventTimestampRef = useRef(
    options.afterTimestamp?.toISOString() ??
      DateTime.utc().minus({ days: 30 }).toISO()
  );

  const {
    data: aisPages,
    isLoading: aisHistoryIsLoading,
    hasNextPage: canFetchMore,
    fetchNextPage: fetchMore,
    isFetching,
  } = useInfiniteQuery(
    [
      QUERY_KEY_STRINGS.VESSEL_AIS_DATA_LIST,
      {
        vesselUuid,
        afterEventTimestamp: options.afterTimestamp,
        beforeEventTimestamp: options.beforeTimestamp,
      },
    ],
    async ({ pageParam }) => {
      if (!isNil(vesselUuid)) {
        try {
          const res = await AisDataApi.listVesselAisData({
            vesselUuid,
            afterEventTimestamp: afterEventTimestampRef.current ?? undefined,
            beforeEventTimestamp: options?.beforeTimestamp?.toISOString(),
            includeHindcastWeather: true,
            cursor: pageParam,
            pageSize: 400,
          });
          return res;
        } catch (e: any) {
          if (e.response.status !== 404) {
            throw e;
          } else {
            if (!failedVesselUuids.has(vesselUuid)) {
              console.warn(
                `Got 404 checking ais location of vessel: ${vesselUuid}`
              );
              failedVesselUuids.add(vesselUuid);
            }
            return Promise.resolve(undefined);
          }
        }
      } else {
        return Promise.resolve(undefined);
      }
    },
    {
      retry: 3,
      staleTime: config.aisPollIntervalMs,
      enabled: options.enabled && !isNil(vesselUuid),
      getNextPageParam: (lastGroup) => {
        return lastGroup?.metadata.hasNextPage
          ? lastGroup.metadata.nextCursor
          : undefined;
      },
    }
  );

  useEffect(() => {
    if (canFetchMore && !isFetching) {
      fetchMore();
    }
  }, [canFetchMore, fetchMore, isFetching]);

  const aisHistory = useMemo(
    () =>
      aisPages?.pages
        ?.flatMap((p) => p?.data)
        .filter((p: AisDataDto | undefined): p is AisDataDto => Boolean(p)) ??
      [],
    [aisPages]
  );

  const latestAis = maxBy(aisHistory, "eventTimestamp");

  return { aisHistory, aisHistoryIsLoading, latestAis };
};
