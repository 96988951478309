import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { extendedPalette } from "../../styles/theme";

const modalDialogClasses = makeStyles()((theme: Theme) => ({
  actions: { display: "flex" },
  leftButton: { marginRight: 24 },
  dialogContainer: {
    paddingTop: 35,
    paddingLeft: 29,
    paddingRight: 29,
    paddingBottom: 27,
    borderRadius: 0,
  },
  errorContainer: {
    paddingTop: 10,
  },
  errorTitle: {
    fontSize: 14,
    color: extendedPalette.neutralDark,
  },
  close: {
    position: "absolute",
    top: 24,
    right: 24,
    color: extendedPalette.neutralMedDark,
    cursor: "pointer",
  },
}));
const modalDialogTitleClasses = makeStyles()((theme: Theme) => ({
  root: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
    },
  },
}));
export const useModalDialogStyles = () => ({
  modalDialogClasses: modalDialogClasses().classes,
  modalDialogTitleClasses: modalDialogTitleClasses().classes,
});
