import { isEmpty, isNil } from "lodash";
import xmlParser from "fast-xml-parser";
import { v4 as uuid } from "uuid";
import { Route } from "shared-types/RouteTypes";
import { xmlStringToRtzRoute as rtzXmlStringToRtzRoute } from "helpers/importExport/route/rtz/rtzXmlRouteFileFormat";
import { tokimecCsvStringToRtzRoute } from "helpers/importExport/route/tokimec/tokimecRouteFileFormat";
import { jrcCsvStringToRtzRoute } from "helpers/importExport/route/jrc/jrcRouteFileFormat";
import { furunoTxtStringToRtzRoute } from "helpers/importExport/route/furunoSimple/furunoSimpleFileFormat";
import { totemCsvStringToRtzRoute } from "helpers/importExport/route/totemCsv/totemCsvRouteFileFormat";
import { kmlStringToRtzRoute } from "helpers/importExport/route/kml/kmlRouteFileFormat";
import {
  convertBvsString8ToJrcString,
  isBvs8Route,
} from "helpers/importExport/route/jrc/bvs8RouteFileFormat";
import { convertRtzJsonDtoToRoute } from "helpers/api";
import { nacos1100CsvStringToRtzRoute } from "helpers/importExport/route/nacos1100/nacos1100RouteFileFormat";

// This type is helpful for testing so we can verify the correct parser was chosen when we
// expected it to be.
type RouteParser =
  | "rtz-json"
  | "nacos-1100"
  | "jrc"
  | "tokimec"
  | "kml"
  | "rtz-xml"
  | "furuno"
  | "totem";

export const getRouteFromString = (
  rawString: string,
  options: { enableFurunoSimple: boolean; enableJsonRoutes: boolean }
): { route: Route; parser: RouteParser } => {
  if (options.enableJsonRoutes) {
    try {
      const route = JSON.parse(rawString);
      if (!isNil(route)) {
        if (isNil(route.rtz)) {
          return {
            route: route.rtzRouteObject ?? route,
            parser: "rtz-json",
          };
        }
        const { rtzRoute, errors } = convertRtzJsonDtoToRoute({
          rtzJsonDto: route.rtz,
        });
        if (isEmpty(errors)) {
          rtzRoute.extensions = {
            ...(rtzRoute.extensions ?? { readonly: true }),
            uuid: rtzRoute.extensions?.uuid ?? uuid(),
          };
          return {
            route: rtzRoute,
            parser: "rtz-json",
          };
        }
      }
    } catch (e) {
      // this will usually fail. not an error
    }
  }

  const routeString = isBvs8Route(rawString)
    ? convertBvsString8ToJrcString(rawString)
    : rawString;

  if (routeString.indexOf("Latitude") === 0) {
    return {
      route: nacos1100CsvStringToRtzRoute(routeString),
      parser: "nacos-1100",
    };
  }

  if (routeString.indexOf("// ROUTE SHEET") === 0) {
    return {
      route: jrcCsvStringToRtzRoute(routeString),
      parser: "jrc",
    };
  }

  if (routeString.indexOf("Route Name:") >= 0) {
    return {
      route: tokimecCsvStringToRtzRoute(routeString),
      parser: "tokimec",
    };
  }

  const isXml = xmlParser.parse(routeString);
  if (isXml) {
    if (routeString.indexOf("<kml") >= 0) {
      return {
        route: kmlStringToRtzRoute(routeString),
        parser: "kml",
      };
    } else {
      // assume any other XML is RTZ
      return {
        route: rtzXmlStringToRtzRoute(routeString),
        parser: "rtz-xml",
      };
    }
  }

  if (options?.enableFurunoSimple && routeString.match(/^NAME\t+LAT\t+LON/)) {
    return {
      route: furunoTxtStringToRtzRoute(routeString),
      parser: "furuno",
    };
  }

  if (
    routeString.match(
      /^Route Name\/WPT Num,WPT Name,WPT Latitude,WPT Longtitude,/
    )
  ) {
    return {
      route: totemCsvStringToRtzRoute(routeString),
      parser: "totem",
    };
  }

  throw Error("Route format not supported");
};
