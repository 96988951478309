import { Box, Chip, SxProps } from "@mui/material";
import { RouteSummaryTag } from "components/sidebar/RouteSummary";
import React from "react";
import { WayfinderTypography } from "DLS/WayfinderTypography";

export const RouteLabel = ({
  tag,
  color,
  sx,
}: {
  tag: RouteSummaryTag | undefined;
  color: string;
  sx?: SxProps;
}) => {
  const isPastActive = tag === "past-active";
  const isPastSuggested = tag === "past-suggested";
  const label = isPastActive
    ? "active"
    : isPastSuggested
    ? "suggested"
    : tag ?? "";

  const chipSx =
    isPastActive || isPastSuggested
      ? { border: `1px solid ${color}`, background: "transparent", color }
      : { backgroundColor: color ?? "#000", color: "#fff" };

  return (
    <Chip
      title={
        isPastActive
          ? "Past active route"
          : isPastSuggested
          ? "Past suggested route"
          : undefined
      }
      label={
        <Box display="flex" flexDirection="row" alignItems="center">
          <WayfinderTypography variant="displaySmall">
            {label.toUpperCase()}
          </WayfinderTypography>
        </Box>
      }
      data-testid={`chip-${label.toLowerCase()}`}
      sx={{
        paddingLeft: 2,
        paddingRight: 2,
        width: tag ? 96 : "auto",
        textAlign: "center",
        height: 20,
        ".MuiChip-label": {
          padding: tag ? 0 : "10px",
        },
        ...sx,
        ...chipSx,
        textTransform: "uppercase",
      }}
    />
  );
};
