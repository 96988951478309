import { RouteRequestStatus } from "@sofarocean/wayfinder-typescript-client";
import AnalyticsContext, { AnalyticsEvent } from "contexts/Analytics";
import { AuthenticationContext } from "contexts/AuthenticationContext";
import { useCallback, useContext, useMemo, useRef } from "react";
import { useVessel } from "shared-hooks/data-fetch-hooks/use-vessel";
import useVoyage from "shared-hooks/data-fetch-hooks/use-voyage";
import { match } from "ts-pattern";

export const requestIsOpen = (status: RouteRequestStatus | undefined) =>
  match(status)
    .with(RouteRequestStatus.Requested, RouteRequestStatus.InProgress, () => {
      return true;
    })
    .with(
      RouteRequestStatus.Completed,
      RouteRequestStatus.Cancelled,
      undefined,
      () => {
        return false;
      }
    )
    .exhaustive();

export const useRouteRequestAnalytics = () => {
  const { trackAnalyticsEvent, deepLink } = useContext(AnalyticsContext);
  const { voyage } = useVoyage(deepLink.voyageUuid);
  const voyageOnLoad = useRef(voyage);
  const { vessel } = useVessel(deepLink.vesselUuid);
  const { user } = useContext(AuthenticationContext);

  if (!voyageOnLoad.current && voyage) {
    voyageOnLoad.current = voyage;
  }
  // keep a stable reference to the voyage on load around to reflect the
  // state of it when the deeplink was followed
  const { routeRequest: routeRequestOnLoad } = voyageOnLoad.current ?? {};

  // keep track of the up-to-date status of the request.
  // when it is no longer open, the current Request should be null
  const currentRequestStatus = voyage?.routeRequest?.status;

  // the current request is set to the request from the voyage on load
  // only if the deep link had the right tracking source and the request is currently open
  // if the request is closed during the user's session, the current request will be set to null
  const currentRouteRequest = useMemo(() => {
    const isTracked =
      deepLink.source &&
      ["pdp-routing-task", "route-request-notification"].includes(
        deepLink.source
      );

    const routeRequestFromLoadIsStillOpen =
      !!routeRequestOnLoad && requestIsOpen(currentRequestStatus);

    const trackedRouteRequestIsStillOpen =
      isTracked && routeRequestFromLoadIsStillOpen;

    return trackedRouteRequestIsStillOpen ? routeRequestOnLoad : null;
  }, [currentRequestStatus, deepLink.source, routeRequestOnLoad]);

  const trackRouteRequestEvent = useCallback(
    (type: AnalyticsEvent, data: Record<string, any>) => {
      if (currentRouteRequest) {
        trackAnalyticsEvent(type, {
          ...data,
          distinct_id: currentRouteRequest.uuid,
          routeRequestUuid: currentRouteRequest.uuid,
          // unfortunately, the org is not human readable
          // but include it anyway, since I think we can use mappings in mixpanel to decode it
          vesselOrganizationId: vessel?.organizationId,
          // by including these properties, we can use mixpanel to break down the conversion times
          // in a human readable way by user, vessel, and voyage
          userEmail: user?.email,
          userName: user?.name,
          vesselName: vessel?.name,
          voyageName: voyage?.name,
        });
      }
    },
    [
      currentRouteRequest,
      trackAnalyticsEvent,
      user?.email,
      user?.name,
      vessel?.name,
      vessel?.organizationId,
      voyage?.name,
    ]
  );

  return useMemo(
    () => ({
      trackRouteRequestEvent,
      currentRouteRequest,
    }),
    [currentRouteRequest, trackRouteRequestEvent]
  );
};
