import { useContext, useEffect } from "react";
import { SIMULATED_HISTORICAL_TIME_QUERY_PARAM } from "shared-hooks/use-wayfinder-url";
import { usePastRouteTypes } from "shared-hooks/use-past-route-types";
import { useCurrentUrl } from "helpers/navigation";
import AnalyticsContext from "contexts/Analytics";
import { DateTime } from "luxon";

import { useVoyagePlan } from "./use-voyage-plan";

// TODO fold this into the centralized redirects
// TODO add integration tests
// it redirects a past suggestion with no sim time to its time on load
// it redirects a non-past suggestion with a sim time to current forecast if there was no sim time on load
// it keeps a sim time on load
export function useHistoricalTimeRedirectEffect() {
  const {
    isPastSuggestionComparison,
    suggestionDateTime,
    isLoadingPastRoutes,
    isPastSuggestionComparisonOnLoad,
  } = usePastRouteTypes();

  const currentUrl = useCurrentUrl();

  const currentSimulatedHistoricalTime = currentUrl
    .search()
    .get(SIMULATED_HISTORICAL_TIME_QUERY_PARAM);

  // the plan id in the params is the canonical representation
  // of a plan in the url, so that is where we get it from
  const voyagePlanUuid = currentUrl.getVoyagePlanUuidQueryParam();

  const isPublishedVoyagePlan = !!voyagePlanUuid;

  const isPlanWithHistoricalTime =
    isPublishedVoyagePlan && currentSimulatedHistoricalTime;

  const { voyagePlan } = useVoyagePlan(voyagePlanUuid);

  // if the url starts with a simulated historic time and is not a historic suggestion, keep the time for good
  // this enables demo and reporting workflows where out team needs to specify the time directly in the url at load time
  const { deepLink } = useContext(AnalyticsContext);
  const hasHistoricalTimeOnLoad = deepLink.urlSearchParams.has(
    SIMULATED_HISTORICAL_TIME_QUERY_PARAM
  );
  const hasPermanentHistoricalTime =
    hasHistoricalTimeOnLoad && isPastSuggestionComparisonOnLoad === false;

  useEffect(() => {
    const suggestionTimeMatchesForecast =
      currentSimulatedHistoricalTime &&
      suggestionDateTime?.valueOf() ===
        DateTime.fromISO(currentSimulatedHistoricalTime).valueOf();

    // check if we should leave the url alone
    if (
      isLoadingPastRoutes ||
      hasPermanentHistoricalTime ||
      suggestionTimeMatchesForecast ||
      isPlanWithHistoricalTime
    ) {
      return;
    }

    // otherwise, set and unset the time when the url changes.

    // plans
    if (
      isPublishedVoyagePlan &&
      !currentSimulatedHistoricalTime &&
      voyagePlan?.forecastInitTime
    ) {
      currentUrl
        .withSimulatedHistoricalTime(voyagePlan?.forecastInitTime)
        .redirect();
      return;
    }

    // suggestions

    // if this is a historic suggestion, add the time param
    if (isPastSuggestionComparison && suggestionDateTime) {
      currentUrl
        .withSimulatedHistoricalTime(suggestionDateTime?.toUTC().toISO())
        .redirect();
      return;
    }

    // if this is not a historic suggestion, but there is still a time in the url, remove it
    if (currentSimulatedHistoricalTime) {
      // otherwise remove it
      currentUrl.withSimulatedHistoricalTime(null).redirect();
      return;
    }
  }, [
    currentSimulatedHistoricalTime,
    currentUrl,
    hasPermanentHistoricalTime,
    isLoadingPastRoutes,
    isPastSuggestionComparison,
    isPlanWithHistoricalTime,
    isPublishedVoyagePlan,
    suggestionDateTime,
    voyagePlan?.forecastInitTime,
  ]);
}
