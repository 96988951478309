import { readString } from "react-papaparse";
import { v4 as uuid } from "uuid";
import { toInteger } from "lodash";
import { default as sexagesimal } from "@mapbox/sexagesimal";
import moment from "moment";

import { padValue } from "helpers/routes";
import {
  GM_Point,
  Route,
  ScheduleElement,
  Waypoint,
} from "shared-types/RouteTypes";
import {
  coordToDMS,
  kmToNauticalMiles,
  nauticalMilesToKm,
} from "helpers/units";
import {
  normalizePointLongitude,
  POSITION_IMPORT_DECIMAL_PRECISION,
} from "helpers/geometry";

import { getWaypointsForExport } from "../get-waypoints-for-export";

// these are the tokimec date formats we know about.
// specify day and month tightly enough that they will reject when possible if the day and month are swapped
// allowing hours, days and months to be single digit in all formats despite only ever seeing it that way in "day first" format
const TIME_FIRST_REGEXP = /^(?<hour>[0-9]{1,2}):(?<minutes>[0-9]{2})(:(?<seconds>[0-9]{2}))? (?<day>[0-3]{0,1}[0-9]{1})\/(?<month>[0-1]{0,1}[0-9]{1})\/(?<year>[0-9]{4})/;
const YEAR_FIRST_REGEXP = /^(?<year>[0-9]{4})\/(?<month>[0-1]{0,1}[0-9]{1})\/(?<day>[0-3]{0,1}[0-9]{1}) (?<hour>[0-9]{1,2}):(?<minutes>[0-9]{2})(:(?<seconds>[0-9]{2}))?/;
const DAY_FIRST_REGEXP = /^(?<day>[0-3]{0,1}[0-9]{1})\/(?<month>[0-1]{0,1}[0-9]{1})\/(?<year>[0-9]{4}) (?<hour>[0-9]{1,2}):(?<minutes>[0-9]{2})(:(?<seconds>[0-9]{2}))?/;

export const EMPTY_CSV_VALUE = "***";

export enum WaypointCsvHeader {
  // the csv header names for columns that are supported and sufficiently specified in the Tokimec spec
  WAYPOINT_ID = "ID",
  LAT = "LAT",
  LON = "LON",
  RADIUS = "Radius (m)",
  REACH = "Reach (L)",
  ROT = "ROT[deg/min]",
  XTD = "XTD (m)",
  SPEED = "SPD (kn)",
  GEOMETRY_TYPE = "RL/GC",
  LEG = "Leg",
  DISTANCE = "Distance (NM)",
  TOTAL_DISTANCE = "TOTAL",
  ETA = "ETA",
}

// better names for missing or incomplete header names in JRC spec
export const RENAMED_WAYPOINT_HEADER_INDICES = {
  [WaypointCsvHeader.WAYPOINT_ID]: 0,
  [WaypointCsvHeader.LAT]: 1,
  [WaypointCsvHeader.LON]: 2,
  [WaypointCsvHeader.RADIUS]: 3,
  [WaypointCsvHeader.REACH]: 4,
  [WaypointCsvHeader.ROT]: 5,
  [WaypointCsvHeader.XTD]: 6,
  [WaypointCsvHeader.SPEED]: 7,
  [WaypointCsvHeader.GEOMETRY_TYPE]: 8,
  [WaypointCsvHeader.LEG]: 9,
  [WaypointCsvHeader.DISTANCE]: 10,
  [WaypointCsvHeader.TOTAL_DISTANCE]: 11,
  [WaypointCsvHeader.ETA]: 12,
};

const parseETA = (csvETA: string, regexp: RegExp): Date | undefined => {
  const match = csvETA.trim().match(regexp);
  if (match && match.groups) {
    return new Date(
      Date.UTC(
        toInteger(match.groups.year),
        toInteger(match.groups.month) - 1, // Months are 0 based ....
        toInteger(match.groups.day),
        toInteger(match.groups.hour),
        toInteger(match.groups.minutes),
        toInteger(match.groups.seconds)
      )
    );
  }
  return undefined;
};

export const getTimesFromStrings = (timeStrings: string[]) => {
  const regexpList = [TIME_FIRST_REGEXP, YEAR_FIRST_REGEXP, DAY_FIRST_REGEXP];
  const matchingRegexps: RegExp[] = timeStrings
    .map((eta: string) => {
      return regexpList.map((regexp) => {
        if (!eta) {
          // Early return
          return undefined;
        }
        const matchGroups = eta.match(regexp)?.groups;
        return matchGroups &&
          parseInt(matchGroups.month) <= 12 &&
          parseInt(matchGroups.day) <= 31
          ? regexp
          : undefined;
      });
    })
    // flatten the per-waypoint array into a union where any row that cannot be parsed by a given regexp causes that regexp to be excluded from the final list of regexps
    // eg [[undefined, undefined, DAY_FIRST_SHORT_HOUR_REGEXP],[undefined, YEAR_FIRST_REGEXP, undefined]...] => [undefined, undefined, undefined]
    .reduce(
      (
        result: (RegExp | undefined)[],
        waypointResult: (RegExp | undefined)[]
      ) =>
        result.map((r: RegExp | undefined, i: number) => r && waypointResult[i])
    )
    .filter((r): r is RegExp => Boolean(r));

  // if only one matching regexp is found, great, use it! otherwise, we cannot safely parse the dates, because we cannot be sure what their format is
  // currently there is known format the puts the month first, so if we do not see the year first, we can safely assume that the nuber is the day
  // if that changes, then this logic needs to be updated e.g. if they all could be day first or month first except row 2, then use what is working on row 2 for all rows
  const regexp: RegExp | undefined =
    matchingRegexps.length === 1 ? matchingRegexps[0] : undefined;
  return regexp
    ? timeStrings.map((timeString) =>
        parseETA(timeString, regexp)?.toISOString()
      )
    : undefined;
};

export const parseLatLonToPosition = (lat: string, lon: string) => {
  const latMatch = lat.match(
    /(?<deg>[0-9]{2})-(?<min>[0-9]{2}.[0-9]{3})'(?<hem>[S|N])/
  )?.groups ?? { deg: undefined, min: undefined, hem: undefined };
  const lonMatch = lon.match(
    /(?<deg>[0-9]{3})-(?<min>[0-9]{2}.[0-9]{3})'(?<hem>[E|W])/
  )?.groups ?? { deg: undefined, min: undefined, hem: undefined };

  const latOut = Number(
    (sexagesimal(`${latMatch.deg}°${latMatch.min}′${latMatch.hem}`) as
      | number
      | null)?.toFixed(POSITION_IMPORT_DECIMAL_PRECISION)
  );
  const lonOut = Number(
    (sexagesimal(`${lonMatch.deg}°${lonMatch.min}′${lonMatch.hem}`) as
      | number
      | null)?.toFixed(POSITION_IMPORT_DECIMAL_PRECISION)
  );
  return normalizePointLongitude({
    lat: latOut,
    lon: lonOut,
  });
};

export const tokimecCsvStringToRtzRoute = (csvString: string): Route => {
  // If a route name contains a comma, the first element is enclosed in "'.
  // Also, the comma after 'Route Name:,` is not present everywhere
  const nameMatch = csvString.match(
    /(Route Name:,?(?<name1>[\w\s:]+),*)|("?Route Name:(?<name2>[\w\s-,]*)"?,*)/
  );

  const routeName = nameMatch?.groups
    ? nameMatch.groups.name1 || nameMatch.groups.name2
    : "Imported Route";

  let cleanedCsvString = csvString
    .replace(/\r\n/gm, "\n") // replace \r\n with \n to harmonize line endings
    .replace(/\r/gm, "\n") // replace \r with \n to harmonize line endings
    .replace(/^\/\/\s/gm, "") // remove comment delimiters from column headers
    .trim();

  // Some file formats have a , after 'TOTAL', some don't
  const headerMatch = cleanedCsvString.match(
    /(?<header>ID,LAT,LON,,,,,,,,To WPT,TOTAL,?)/
  );
  const header = headerMatch?.groups?.header;
  if (!header) {
    throw new Error("Invalid Tokimec CSV file format");
  }

  const headerIndex = cleanedCsvString.indexOf(header);
  cleanedCsvString = cleanedCsvString.substr(headerIndex + header.length);

  const csvData = readString(cleanedCsvString, {
    header: false,
    skipEmptyLines: true,
  });
  let index = 0;
  let previousCsvWaypoint: string[] = [];
  const waypoints: Waypoint[] = (csvData.data as string[][]).map<Waypoint>(
    (csvWaypoint: string[]) => {
      const position = parseLatLonToPosition(
        csvWaypoint[RENAMED_WAYPOINT_HEADER_INDICES["LAT"]],
        csvWaypoint[RENAMED_WAYPOINT_HEADER_INDICES["LON"]]
      );

      const xtdMeters = parseFloat(
        previousCsvWaypoint[RENAMED_WAYPOINT_HEADER_INDICES["XTD (m)"]]
      );
      const xtd = !isNaN(xtdMeters)
        ? kmToNauticalMiles(xtdMeters / 1000)
        : undefined;

      const result: Waypoint = {
        id: index++,
        name: csvWaypoint[RENAMED_WAYPOINT_HEADER_INDICES["ID"]],
        position: position,
        radius:
          csvWaypoint[RENAMED_WAYPOINT_HEADER_INDICES["Radius (m)"]] &&
          !isNaN(
            parseFloat(
              csvWaypoint[RENAMED_WAYPOINT_HEADER_INDICES["Radius (m)"]]
            )
          )
            ? kmToNauticalMiles(
                parseFloat(
                  csvWaypoint[RENAMED_WAYPOINT_HEADER_INDICES["Radius (m)"]]
                ) / 1000
              )
            : undefined,
        leg: {
          geometryType:
            previousCsvWaypoint.length &&
            previousCsvWaypoint[RENAMED_WAYPOINT_HEADER_INDICES["RL/GC"]] &&
            previousCsvWaypoint[RENAMED_WAYPOINT_HEADER_INDICES["RL/GC"]] !==
              EMPTY_CSV_VALUE
              ? // if it is defined, translate to rtz values
                previousCsvWaypoint[
                  RENAMED_WAYPOINT_HEADER_INDICES["RL/GC"]
                ] === "GC"
                ? "Orthodrome"
                : "Loxodrome"
              : // otherwise, leave it undefined
                undefined,
          starboardXTD: xtd,
          portsideXTD: xtd,
        },
      };
      for (const property in result) {
        if (result[property as keyof Waypoint] === undefined)
          delete result[property as keyof Waypoint];
      }
      for (const property in result.leg) {
        if (result.leg[property as keyof Waypoint["leg"]] === undefined)
          delete result.leg[property as keyof Waypoint["leg"]];
      }
      previousCsvWaypoint = csvWaypoint;
      return result;
    }
  );
  let scheduleIndex = 0;
  previousCsvWaypoint = [];

  const timeStrings = (csvData.data as string[][])
    // map the csv rows to the regexp's that can read the ETA ie [[undefined, undefined, DAY_FIRST_SHORT_HOUR_REGEXP],[undefined, undefined, DAY_FIRST_SHORT_HOUR_REGEXP]...]
    .map(
      (csvWaypoint: string[]) =>
        csvWaypoint[RENAMED_WAYPOINT_HEADER_INDICES["ETA"]]
    );
  const times = getTimesFromStrings(timeStrings);

  const scheduleElements: ScheduleElement[] = (csvData.data as string[][]).map<ScheduleElement>(
    (csvWaypoint: string[], index) => {
      const speed = previousCsvWaypoint.length
        ? parseFloat(
            previousCsvWaypoint[RENAMED_WAYPOINT_HEADER_INDICES["SPD (kn)"]]
          )
        : NaN;
      const etaFromCSV = times?.[index];

      // The first waypoint needs to have an etd, all others, an eta
      let etaEtd = {};
      if (index === 0) {
        etaEtd = { etd: etaFromCSV };
      } else {
        etaEtd = { eta: etaFromCSV };
      }

      const result: ScheduleElement = {
        waypointId: scheduleIndex++,
        speed: !isNaN(speed) ? speed : undefined,
        ...etaEtd,
      };
      for (const property in result) {
        if (result[property as keyof ScheduleElement] === undefined)
          delete result[property as keyof ScheduleElement];
      }
      previousCsvWaypoint = csvWaypoint;
      return result;
    }
  );
  return {
    version: "1.0",
    extensions: {
      readonly: false,
      uuid: uuid(), // TODO should this be fresh every time the same route is imported? Should this route be compared to existing routes before getting a uuid?
    },
    routeInfo: {
      routeName: routeName.trim(), // Route names could start with a space that isn't filtered from the regex
    },
    waypoints: {
      waypoints,
      defaultWaypoint: { id: Math.max(...waypoints.map((w) => w.id)) + 1 },
    },
    schedules: { schedules: [{ id: 0, manual: { scheduleElements } }] },
  };
};

export const formatTokimecPosition = (position: GM_Point) => {
  const lon = coordToDMS(position.lon, "lon");
  const lat = coordToDMS(position.lat, "lat");
  const minuteDigits = 3;
  return `${padValue(lat.whole, 2)}-${padValue(
    (lat.minutes + lat.seconds / 60).toFixed(minuteDigits),
    6
  )}'${lat.dir},${padValue(lon.whole, 3)}-${padValue(
    (lon.minutes + lon.seconds / 60).toFixed(minuteDigits),
    6
  )}'${lon.dir}`;
};
const normalizeBearing = (bearing: number) => {
  while (bearing > 360) bearing -= 360;
  while (bearing < 0) bearing += 360;
  return bearing;
};
const getWaypointLine = (
  wpt: Waypoint,
  nextWpt: Waypoint | undefined,
  scheduleElement?: ScheduleElement,
  nextScheduleElement?: ScheduleElement | undefined
) => {
  const name = wpt.name ?? wpt.id;
  const pos = formatTokimecPosition(wpt.position);
  const rad = // tokimec wants meters
    (wpt.radius && Math.round(nauticalMilesToKm(wpt.radius) * 1000)) ?? "";
  const reach = "";
  const rot = "";

  // "XTD SPD and RL/GC and LEG all come from next waypoint in the tokimec format
  const xtdNm = // take the min of port and stbd xtd, or if only one is defined, use that
    (nextWpt?.leg?.portsideXTD &&
      nextWpt?.leg?.starboardXTD && //use the smaller xtd, for safety
      Math.min(nextWpt.leg?.portsideXTD, nextWpt.leg?.starboardXTD)) ||
    (nextWpt?.leg?.portsideXTD ?? nextWpt?.leg?.starboardXTD ?? "");

  const xtd = xtdNm && (nauticalMilesToKm(xtdNm) * 1000).toFixed(1);

  const spd = nextScheduleElement?.speed?.toFixed(1) ?? "";
  const geo =
    nextWpt?.leg?.geometryType === "Loxodrome"
      ? "RL"
      : nextWpt?.leg?.geometryType === "Orthodrome"
      ? "GC"
      : "";
  const leg =
    nextWpt?.extensions?.course !== undefined &&
    nextWpt.extensions?.course !== null
      ? normalizeBearing(nextWpt.extensions?.course).toFixed(1)
      : "";

  const dToWpt = ""; // TODO add these
  const dTotal = "";
  const eta =
    (scheduleElement &&
      moment(scheduleElement?.eta).utc().format("YYYY/MM/DD HH:mm:ss")) ??
    "";
  return `${name},${pos},${rad},${reach},${rot},${xtd},${spd},${geo},${leg},${dToWpt},${dTotal},${eta}\r\n`;
};
export const routeToTokimecCsvString = (route: Route) => {
  const columnHeaders = `Route Name:,${route.routeInfo.routeName.replace(
    /,/,
    ""
  )},,,,,,,,,,,\r
Way Point,Position,,"Radius\r
(m)","Reach\r
(L)","ROT\r
(�/min)","XTD\r
(m)","SPD\r
(kn)",RL/GC,"Leg\r
(�)",Distance (NM),,ETA\r
ID,LAT,LON,,,,,,,,To WPT,TOTAL,\r
`;
  // prefer calculated schedule with sofar data in it
  const scheduleElements = (
    route.schedules?.schedules?.[0]?.calculated ??
    route.schedules?.schedules?.[0]?.manual
  )?.scheduleElements;
  return `${columnHeaders}${getWaypointsForExport(route)
    .map((w, index, waypoints) =>
      getWaypointLine(
        w,
        waypoints[index + 1],
        scheduleElements?.find((s) => s.waypointId === w.id),
        scheduleElements?.find((s) => s.waypointId === waypoints[index + 1]?.id)
      )
    )
    .join("")}`;
};
