import React, { forwardRef } from "react";
import {
  CustomContentProps,
  SnackbarContent,
  useSnackbar,
  SnackbarProviderProps,
} from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton } from "@mui/material";
import { match, P } from "ts-pattern";
import { corePalette } from "styles/theme";
import { WayfinderTypography } from "DLS";

interface SnackbarProps extends CustomContentProps {
  isCloseable?: boolean;
}

/**
 * A thin wrapper around the snackbar components so we can restrict styling to just how we want it.
 *
 * The main things are:
 *   - No variants: it's loud and the snackbar behavior already provides enough loudness with their motion
 *   - Built-in closeability: so we don't need to reimplement it every time
 */
const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>(
  ({ id, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();

    const action = match(props.action)
      .with(
        P.when((a): a is CallableFunction => typeof a === "function"),
        (a) => a(id)
      )
      .otherwise((a) => a);

    return (
      <SnackbarContent ref={ref}>
        <Box
          bgcolor={corePalette.blackGray}
          p="16px"
          display="flex"
          alignItems="center"
        >
          <WayfinderTypography color={corePalette.white} sx={{ mr: "24px" }}>
            {props.message}
          </WayfinderTypography>

          {action}

          {props.isCloseable && (
            <IconButton
              size="small"
              aria-label="close"
              sx={{ color: corePalette.white }}
              onClick={() => closeSnackbar(id)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </SnackbarContent>
    );
  }
);

// This lets us restrict variants and add some new props, locking down the
// exposed customizability and keeping the design system constrained.
declare module "notistack" {
  interface VariantOverrides {
    error: false;
    success: false;
    warning: false;
    info: false;
    default: {
      isCloseable?: boolean;
    };
  }
}

const WayfinderSnackbarProviderConfig: SnackbarProviderProps = {
  Components: {
    default: Snackbar,
  },
  anchorOrigin: {
    horizontal: "center",
    vertical: "bottom",
  },
};

export { WayfinderSnackbarProviderConfig };
