import { useImportedRouteUuids } from "components/routes/Import/use-imported-route-uuids";
import config, {
  CompositeWeatherVariableDefinition,
  WeatherQuantities,
} from "config";
import { NowContext } from "contexts/Now";
import { useEditedRouteUuids } from "contexts/RouteEditorContext/hooks";
import { WaypointLookup } from "contexts/RouteStoreContext/state-types";
import { TimezoneContext } from "contexts/TimezoneContext";
import WeatherContext from "contexts/WeatherContext";
import {
  AbsoluteSummary,
  NearFutureMetrics,
  RelativeSummary,
  RouteSummaryData,
  calculateRemainingMetricsFromTime,
  computeAbsoluteRouteSummary,
  computeRelativeRouteSummary,
} from "helpers/routeSummary";
import { getEta, getEtd, getLmtTimezone } from "helpers/routes";
import { isNumber } from "helpers/units";
import { isUnderway } from "helpers/voyage-status";
import { isNil } from "lodash";
import { useContext, useMemo } from "react";
import { useLatestOrProjectedVesselPosition } from "shared-hooks/data-fetch-hooks/use-current-vessel-position";
import { useRouteSuggestion } from "shared-hooks/data-fetch-hooks/use-route-suggestion";
import useVoyage from "shared-hooks/data-fetch-hooks/use-voyage";
import { useActiveRoute } from "shared-hooks/use-active-route";
import { SimulatedRoute } from "shared-types/RouteTypes";
import { useAlignedRouteComparisonStartTime } from "./use-aligned-route-comparison-start-time";
import { useRouteScoreOptions } from "./use-route-score-options";

const WEATHER_SUMMARY_CONFIG_NAMES: WeatherQuantities[] = [
  "combinedWaves",
  "wind",
  "currents",
];

function useRouteSummaryDataForecastEndDates(
  isSimulating: boolean
): {
  weatherIsComplete: boolean;
  forecastEndDates: Record<WeatherQuantities, Date> | undefined;
} {
  const { currentForecastMetadata } = useContext(WeatherContext);
  const {
    weatherVariables: weatherVariablesMetaData,
    downloadStatus: weatherDownloadStatus,
    initialized,
  } = currentForecastMetadata;

  return useMemo(() => {
    const weatherConfigs: [
      WeatherQuantities,
      CompositeWeatherVariableDefinition
    ][] = WEATHER_SUMMARY_CONFIG_NAMES.map((key) => [
      key,
      config.weatherVariables[key],
    ]);

    const weatherVariables = weatherConfigs
      .map(([key, c]) => {
        switch (c.type) {
          case "magnitude-direction":
            return [key, c.magnitudeVariableID];
          case "scalar":
            return [key, c.variableID];
          case "vector-components":
            return [key, c.northingVariableID];
          default:
            return undefined;
        }
      })
      .filter((v): v is [WeatherQuantities, string] => Boolean(v));

    type MetadataEntry = [
      WeatherQuantities,
      typeof weatherVariablesMetaData[number]
    ];

    const forecastEndEpochs = (weatherVariables.map(([key, variable]) => [
      key,
      weatherVariablesMetaData.find((m) => m.variableID === variable),
    ]) as MetadataEntry[])
      .filter((result): result is MetadataEntry => Boolean(result[1]))
      .map(([key, metaData]) => [
        key,
        metaData.enumeratedOutputTimes
          ? Math.max(
              ...metaData.enumeratedOutputTimes.map((t) =>
                new Date(t).getTime()
              )
            )
          : undefined,
      ])
      .filter((result): result is [WeatherQuantities, number] =>
        Boolean(result[1])
      );

    // only include weather scores if the routes have completed simulation and have complete weather
    const weatherIsComplete =
      !isSimulating &&
      weatherDownloadStatus === "idle" &&
      initialized &&
      forecastEndEpochs.length === WEATHER_SUMMARY_CONFIG_NAMES.length;

    const forecastEndDates = !weatherIsComplete
      ? undefined
      : (Object.fromEntries(
          forecastEndEpochs.map(([key, epoch]) => [key, new Date(epoch)])
        ) as Record<WeatherQuantities, Date>);

    return { weatherIsComplete, forecastEndDates };
  }, [
    initialized,
    isSimulating,
    weatherDownloadStatus,
    weatherVariablesMetaData,
  ]);
}

function useAbsoluteSummaryFromTime(
  simulatedRoute: SimulatedRoute | undefined,
  waypointsById: WaypointLookup["byId"] | undefined,
  startTime: Date,
  isSimulating: boolean,
  voyageUuid: string | undefined,
  simulatedStartTime?: Date
): { absoluteSummary?: AbsoluteSummary; nearFuture?: NearFutureMetrics } {
  const {
    weatherIsComplete,
    forecastEndDates,
  } = useRouteSummaryDataForecastEndDates(isSimulating);
  const routeScoreOptions = useRouteScoreOptions();
  const vesselLongitude = useLatestOrProjectedVesselPosition(
    voyageUuid,
    startTime
  ).lon;
  const { voyage: { statusV2: voyageStatus } = {} } = useVoyage(voyageUuid);

  return useMemo(() => {
    if (isNil(simulatedRoute) || isNil(waypointsById) || isNil(startTime)) {
      return {};
    }

    const etaString = simulatedRoute ? getEta(simulatedRoute) : undefined;
    const eta = etaString ? new Date(etaString) : undefined;
    const etdString = simulatedRoute ? getEtd(simulatedRoute) : undefined;
    const etd = etdString ? new Date(etdString) : undefined;

    if (isNil(eta) || isNil(etd)) {
      return {};
    }

    const currentTimezone = isNumber(vesselLongitude)
      ? getLmtTimezone(vesselLongitude)
      : undefined;
    const simulatedRouteMetrics = calculateRemainingMetricsFromTime(
      simulatedRoute,
      waypointsById,
      startTime,
      forecastEndDates,
      routeScoreOptions,
      weatherIsComplete,
      currentTimezone,
      simulatedStartTime
    );
    if (isNil(simulatedRouteMetrics)) {
      return {};
    }

    const absoluteSummary = computeAbsoluteRouteSummary({
      simulatedRouteMetrics,
      eta,
      etd,
      // We expect that an underway voyage's route is regularly updated due to Polaris routing
      // so the route's ETD should keep moving forward in time and be roughly aligned with the
      // current time. However, if the route's ETD is too old, we want to start calculating the
      // route's remaining duration from the current time.
      // That's not the case for planning voyages though. We always want to use the route's ETD
      // to calculate a planning voyage's duration because we don't expect its route ETD to keep
      // updating in time.
      durationCalculationStartTime:
        startTime > etd && isUnderway(voyageStatus) ? startTime : etd,
      route: simulatedRoute,
    });
    return { absoluteSummary, nearFuture: simulatedRouteMetrics.nearFuture };
  }, [
    simulatedRoute,
    waypointsById,
    startTime,
    vesselLongitude,
    forecastEndDates,
    weatherIsComplete,
    routeScoreOptions,
    voyageStatus,
    simulatedStartTime,
  ]);
}

export function useRouteSummaryData(
  simulatedRoute: SimulatedRoute | undefined,
  waypointsById: WaypointLookup["byId"] | undefined,
  voyageUuid: string | undefined,
  isSimulating: boolean
): RouteSummaryData | undefined {
  const {
    alignedRouteComparisonStartTime,
  } = useAlignedRouteComparisonStartTime();
  const { now } = useContext(NowContext);

  const startTime = alignedRouteComparisonStartTime ?? now;
  const {
    currentVoyageDepartureTimezone,
    currentVoyageDestinationTimezone,
  } = useContext(TimezoneContext);

  const { suggestedRouteUuid } = useRouteSuggestion(voyageUuid);
  const { activeRouteUuid } = useActiveRoute(voyageUuid);
  const importedRouteUuids = useImportedRouteUuids();
  const editedRouteUuids = useEditedRouteUuids();

  const routeDescription = useMemo(
    () =>
      simulatedRoute?.extensions.uuid === suggestedRouteUuid
        ? "Suggested Route"
        : simulatedRoute?.extensions.uuid === activeRouteUuid
        ? "Active Route"
        : simulatedRoute &&
          importedRouteUuids.includes(simulatedRoute.extensions.uuid)
        ? "Imported Route"
        : simulatedRoute &&
          editedRouteUuids.includes(simulatedRoute.extensions.uuid)
        ? "Edited Route"
        : "Alternate Route",
    [
      activeRouteUuid,
      importedRouteUuids,
      simulatedRoute,
      suggestedRouteUuid,
      editedRouteUuids,
    ]
  );

  const { absoluteSummary, nearFuture } = useAbsoluteSummaryFromTime(
    simulatedRoute,
    waypointsById,
    startTime,
    isSimulating,
    voyageUuid
  );

  return useMemo(() => {
    if (isNil(absoluteSummary) || isNil(simulatedRoute)) {
      return undefined;
    }

    return {
      routeUuid: simulatedRoute.extensions.uuid,
      routeName: simulatedRoute.routeInfo?.routeName ?? "(unnamed)",
      routeDescription,
      absoluteSummary,
      nearFuture,
      routeEtdTimezone: currentVoyageDepartureTimezone,
      routeEtaTimezone: currentVoyageDestinationTimezone,
    };
  }, [
    absoluteSummary,
    simulatedRoute,
    nearFuture,
    routeDescription,
    currentVoyageDepartureTimezone,
    currentVoyageDestinationTimezone,
  ]);
}

export const useRelativeRouteSummary = (
  routeToCompare: RouteSummaryData | undefined,
  comparisonBasis: RouteSummaryData | undefined
): RelativeSummary | undefined => {
  const options = useRouteScoreOptions();
  const comparisonBasisAbsoluteSummary = comparisonBasis?.absoluteSummary;
  const routeToCompareAbsoluteSummary = routeToCompare?.absoluteSummary;
  return useMemo(
    () =>
      comparisonBasisAbsoluteSummary &&
      routeToCompareAbsoluteSummary &&
      comparisonBasis?.routeUuid !== routeToCompare?.routeUuid
        ? computeRelativeRouteSummary({
            absoluteSummary: routeToCompareAbsoluteSummary,
            comparisonBasisAbsoluteSummary,
            options,
          })
        : undefined,
    [
      comparisonBasis?.routeUuid,
      comparisonBasisAbsoluteSummary,
      options,
      routeToCompare?.routeUuid,
      routeToCompareAbsoluteSummary,
    ]
  );
};
