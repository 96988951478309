import { RouteSource } from "@sofarocean/wayfinder-typescript-client";
import { useCurrentVoyageLeg } from "components/WayfinderApp/CurrentSession/contexts";
import AnalyticsContext, { AnalyticsEvent } from "contexts/Analytics";
import { RouteStoreDispatchContext } from "contexts/RouteStoreContext";
import { RouteEditorConfiguration } from "contexts/RouteStoreContext/state-types";
import useRoute from "contexts/RouteStoreContext/use-route";
import UIContext from "contexts/UIContext";
import { useRecallStack } from "helpers/recallStack";
import { union } from "lodash";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRouteMatch } from "react-router-dom";
import { useLDFlags } from "shared-hooks/use-ld-flags";
import { useSaveRoute } from "shared-hooks/use-route-save";
import {
  EDIT_ROUTE_PATH,
  ROUTE_EXPLORER_EDIT_ROUTE_PATHS,
  useWayfinderUrl,
  WayfinderUrlOptions,
} from "shared-hooks/use-wayfinder-url";
import {
  GeometryType,
  GM_Point,
  Route,
  SimulatedRoute,
  Waypoint,
} from "shared-types/RouteTypes";
import {
  buildRouteEditVersion,
  cloneRouteForEditing,
  MultiSelectWaypointsType,
  RouteEditVersion,
} from "./helpers";
import {
  useDraggableRoute,
  UseDraggableRouteType,
  useMultiSelectWaypoints,
} from "./hooks";

type WaypointEditorActionsType = {
  moveWaypoint: (waypointID: number, position: GM_Point) => void;
  addWaypoint: (
    previousWaypointID: number,
    nextWaypointID: number | undefined,
    position: GM_Point
  ) => void;
  removeWaypoint?: (waypointID: number) => void;
  updateWaypointGeometryType: (
    waypointID: number,
    geometryType: GeometryType
  ) => void;
};

type RouteCrudActionsType = {
  saveRoute: (() => void) | undefined;
  editRoute: (
    route: Route,
    configuration?: RouteEditorConfiguration,
    routeMetadata?: Record<string, any>
  ) => void;
  routeMetadata?: Record<string, any>;
  updateRouteMetadata: (metadata?: Record<string, any>) => void;
};

type MultiWaypointEditorActionsType = MultiSelectWaypointsType & {
  updateMultiWaypointsSpeed: (speedKts: number) => void;
  removeMultiWaypoints: () => void;

  showMultiDeleteWarning: boolean;
  showMultiDeleteWarningMessage: () => void;
  closeMultiDeleteWarningMessage: () => void;

  isMultiEditModalOpen: boolean;
  showMultiEditModal: () => void;
  closeMultiEditModal: () => void;
};

type WaypointScheduleEditorActionsType = {
  enableScheduleEdits: boolean;
  updateWaypointSpeed: (waypointID: number, speedKts: number) => void;
  updateWaypointTimestamp: (waypointID: number, iso: string) => void;
  updateWaypointDrifting: (
    waypointID: number,
    state: boolean,
    endTimestamp: string
  ) => void;
};

type RouteRecallStackType = {
  undo: (() => void) | undefined;
  revert: (() => void) | undefined;
};

type RouteEditorContextBaseType = {
  isRouteEditorOpen: boolean;
  isRouteExplorerEditorOpen: boolean;
  closeRouteEditor: (
    urlOptions?: WayfinderUrlOptions<"route-detail" | "route-explorer-detail">
  ) => void;

  draftRouteUuid: string | undefined;
  draftRoute: Route | undefined;

  currentWaypoint: number | undefined;
  setCurrentWaypoint: (value: number) => void;
};

type RouteEditorContextType = RouteEditorContextBaseType &
  RouteRecallStackType &
  WaypointEditorActionsType &
  RouteCrudActionsType &
  UseDraggableRouteType &
  MultiWaypointEditorActionsType &
  WaypointScheduleEditorActionsType & {
    simulatedDraftRoute: SimulatedRoute | undefined;
    simulatedDraftWaypointsById: Record<number, Waypoint> | undefined;

    speedUpdatedWaypointIDs: number[];
  };

export const RouteEditorContextDefaults: RouteEditorContextType = {
  currentWaypoint: undefined,
  setCurrentWaypoint: (value: number) => undefined,
  isRouteEditorOpen: false,
  isRouteExplorerEditorOpen: false,
  isMultiSelected: false,
  draftRouteUuid: undefined,
  draftRoute: undefined,
  simulatedDraftRoute: undefined,
  simulatedDraftWaypointsById: undefined,
  closeRouteEditor: () => undefined,
  saveRoute: () => undefined,
  endDrag: () => undefined,
  editRoute: (
    route: Route,
    configuration?: RouteEditorConfiguration,
    routeMetadata?: Record<string, any>
  ) => undefined,
  draggableRoute: undefined,
  addWaypoint: (
    previousWaypointID: number,
    nextWaypointID: number | undefined,
    position: GM_Point
  ) => undefined,
  moveWaypoint: (waypointID: number, position: GM_Point) => undefined,
  enableScheduleEdits: false,
  updateWaypointSpeed: (waypointID: number, speedKts: number) => undefined,
  updateWaypointTimestamp: (waypointID: number, iso: string) => undefined,
  updateWaypointDrifting: (
    waypointID: number,
    state: boolean,
    endTimestamp: string
  ) => undefined,
  updateDraggableRouteWaypointPosition: (
    waypointID: number,
    position: GM_Point
  ) => {},
  updateWaypointGeometryType: () => undefined,
  undo: undefined,
  revert: undefined,
  routeMetadata: undefined,
  updateRouteMetadata: () => undefined,
  multiSelectWaypoint: () => {},
  removeMultiWaypoints: () => {},
  clearMultiSelectedWaypoints: () => {},
  showMultiDeleteWarning: false,
  showMultiDeleteWarningMessage: () => {},
  closeMultiDeleteWarningMessage: () => {},
  selectedWaypointIDs: [],
  showMultiEditModal: () => {},
  closeMultiEditModal: () => {},
  isMultiEditModalOpen: false,
  updateMultiWaypointsSpeed: () => {},
  speedUpdatedWaypointIDs: [],
  setModifierKeyIsActive: (state: boolean) => {},
};

export const RouteEditorContext = createContext<RouteEditorContextType>(
  RouteEditorContextDefaults
);

export const useRouteEditorState = (): RouteEditorContextType => {
  const isDefaultRouteEditorOpen = Boolean(
    useRouteMatch(EDIT_ROUTE_PATH)?.isExact
  );
  const isRouteExplorerEditorOpen = Boolean(
    useRouteMatch(ROUTE_EXPLORER_EDIT_ROUTE_PATHS)?.isExact
  );
  const isRouteEditorOpen =
    isDefaultRouteEditorOpen || isRouteExplorerEditorOpen;

  const { allowSpeedEditing } = useLDFlags();

  const { trackAnalyticsEvent } = useContext(AnalyticsContext);

  const [draftRouteUuid, setDraftRouteUuid] = useState<string | undefined>(
    undefined
  );
  const [routeMetadata, setRouteMetadata] = useState<
    Record<string, any> | undefined
  >();
  const [showMultiDeleteWarning, setShowMultiDeleteWarning] = useState<boolean>(
    false
  );
  const [isMultiEditModalOpen, setIsMultiEditModalOpen] = useState<boolean>(
    false
  );

  const [speedUpdatedWaypointIDs, setSpeedUpdatedWaypointIDs] = useState<
    number[]
  >([]);

  const { createRoute, updateRoute, updateEditorConfiguration } = useContext(
    RouteStoreDispatchContext
  );
  const { saveRoute: saveRouteToApi } = useSaveRoute();
  const { voyage: { uuid: voyageUuid } = {} } = useCurrentVoyageLeg();
  const { clearMapInspector } = useContext(UIContext);
  const [currentWaypoint, setCurrentWaypoint] = useState<number | undefined>(
    undefined
  );

  const enableScheduleEdits = useMemo(
    () => (isRouteExplorerEditorOpen ? false : allowSpeedEditing),
    [allowSpeedEditing, isRouteExplorerEditorOpen]
  );

  const { setRoutesToCompare, setUrl, routeUuidsToCompare } = useWayfinderUrl<{
    voyageUuid: string;
  }>();

  const {
    route: draftRoute,
    simulatedRoute: simulatedDraftRoute,
    updateWaypointPosition,
    addWaypoint: reallyAddWaypoint,
    removeWaypoint: reallyRemoveWaypoint,
    updateWaypointGeometryType: reallyUpdateWaypointGeometryType,
    updateWaypointSpeed: reallyUpdateWaypointSpeed,
    updateWaypointTimestamp: reallyUpdateWaypointTimestamp,
    updateWaypointDrifting: reallyUpdateWaypointDrifting,

    lookup: {
      simulatedRouteWaypoints: {
        byId: simulatedDraftWaypointsById = undefined,
      } = {},
    } = {},
  } = useRoute(draftRouteUuid, true, !enableScheduleEdits);

  const {
    isMultiSelected,
    selectedWaypointIDs,
    multiSelectWaypoint,
    setModifierKeyIsActive,
    clearMultiSelectedWaypoints,
  } = useMultiSelectWaypoints(draftRoute?.waypoints.waypoints ?? []);

  const recallStackAction = useCallback(
    (routeVersion?: RouteEditVersion) => {
      if (routeVersion && draftRouteUuid) {
        updateRoute(draftRouteUuid, { route: routeVersion.route });
        setRouteMetadata(routeVersion.metadata);
        setSpeedUpdatedWaypointIDs(routeVersion.speedUpdatedWaypointIDs);
      }
    },
    [draftRouteUuid, updateRoute]
  );

  const {
    undo,
    revert,
    push: pushToStack,
    clear: clearStack,
    size: undoStackSize,
  } = useRecallStack<RouteEditVersion>(recallStackAction);

  const updateUndoStack = useCallback(() => {
    const currentRouteVersion = buildRouteEditVersion(
      draftRoute,
      speedUpdatedWaypointIDs,
      routeMetadata
    );
    if (currentRouteVersion) {
      pushToStack(currentRouteVersion);
    }
  }, [draftRoute, speedUpdatedWaypointIDs, routeMetadata, pushToStack]);

  const updateRouteMetadata = useCallback(
    (metadata?: Record<string, any>) => {
      updateUndoStack();
      setRouteMetadata(metadata);
    },
    [setRouteMetadata, updateUndoStack]
  );

  const moveWaypoint = useCallback(
    (waypointID: number, position: GM_Point) => {
      updateUndoStack();
      updateWaypointPosition(waypointID, position);
      setCurrentWaypoint(waypointID);
    },
    [updateUndoStack, updateWaypointPosition]
  );

  const {
    endDrag,
    draggableRoute,
    updateDraggableRouteWaypointPosition,
  } = useDraggableRoute({
    route: simulatedDraftRoute,
    moveWaypoint,
  });

  const addSpeedUpdatedWaypointID = useCallback(
    (waypointIDs: number[]) => {
      const noDuplicatedWaypointIDs = union(
        waypointIDs,
        speedUpdatedWaypointIDs
      );

      setSpeedUpdatedWaypointIDs(noDuplicatedWaypointIDs);
    },
    [speedUpdatedWaypointIDs]
  );

  const updateWaypointSpeed = useCallback(
    (waypointID: number, speedKts: number) => {
      updateUndoStack();
      addSpeedUpdatedWaypointID([waypointID]);
      reallyUpdateWaypointSpeed(waypointID, speedKts);
    },
    [updateUndoStack, addSpeedUpdatedWaypointID, reallyUpdateWaypointSpeed]
  );

  const updateWaypointTimestamp = useCallback(
    (waypointID: number, iso: string) => {
      updateUndoStack();
      reallyUpdateWaypointTimestamp(waypointID, iso);
    },
    [updateUndoStack, reallyUpdateWaypointTimestamp]
  );

  const updateWaypointDrifting = useCallback(
    (waypointID: number, state: boolean, timestamp: string) => {
      updateUndoStack();
      reallyUpdateWaypointDrifting(waypointID, state, timestamp);
    },
    [updateUndoStack, reallyUpdateWaypointDrifting]
  );

  const updateMultiWaypointsSpeed = useCallback(
    (speedKts: number) => {
      if (!draftRoute?.waypoints) return;
      updateUndoStack();
      addSpeedUpdatedWaypointID(selectedWaypointIDs);
      selectedWaypointIDs.forEach((w) => {
        reallyUpdateWaypointSpeed(w, speedKts);
      });
      trackAnalyticsEvent(AnalyticsEvent.ClickedMultiEdit, {
        routeUuid: draftRouteUuid,
      });
      setIsMultiEditModalOpen(false);
      clearMultiSelectedWaypoints();
    },
    [
      draftRoute?.waypoints,
      updateUndoStack,
      addSpeedUpdatedWaypointID,
      selectedWaypointIDs,
      trackAnalyticsEvent,
      draftRouteUuid,
      reallyUpdateWaypointSpeed,
      clearMultiSelectedWaypoints,
    ]
  );

  const addWaypoint = useCallback(
    (
      previousWaypointID: number,
      nextWaypointID: number | undefined,
      position: GM_Point
    ) => {
      updateUndoStack();
      const waypointID =
        draftRoute?.waypoints.defaultWaypoint?.id ??
        Math.max(...draftRoute?.waypoints.waypoints.map((w) => w.id)!) + 1;

      reallyAddWaypoint(previousWaypointID, nextWaypointID, position);

      setCurrentWaypoint(waypointID);
    },
    [
      updateUndoStack,
      draftRoute?.waypoints.defaultWaypoint?.id,
      draftRoute?.waypoints.waypoints,
      reallyAddWaypoint,
    ]
  );

  const updateWaypointGeometryType = useCallback(
    (waypointID: number, geometryType: GeometryType) => {
      updateUndoStack();
      return reallyUpdateWaypointGeometryType(waypointID, geometryType);
    },
    [updateUndoStack, reallyUpdateWaypointGeometryType]
  );

  const removeWaypoint = useCallback(
    (waypointID: number) => {
      updateUndoStack();
      reallyRemoveWaypoint(waypointID);
    },
    [updateUndoStack, reallyRemoveWaypoint]
  );

  const showMultiDeleteWarningMessage = useCallback(() => {
    setShowMultiDeleteWarning(true);
  }, []);

  const closeMultiDeleteWarningMessage = useCallback(() => {
    setShowMultiDeleteWarning(false);
  }, []);

  const showMultiEditModal = useCallback(() => {
    setIsMultiEditModalOpen(true);
  }, []);

  const closeMultiEditModal = useCallback(() => {
    setIsMultiEditModalOpen(false);
  }, []);

  const removeMultiWaypoints = useCallback(() => {
    if (!draftRoute?.waypoints) return;
    updateUndoStack();
    selectedWaypointIDs.forEach((w) => {
      reallyRemoveWaypoint(w);
    });
    trackAnalyticsEvent(AnalyticsEvent.ClickedMultiDelete, {
      routeUuid: draftRouteUuid,
    });
    setShowMultiDeleteWarning(false);
    clearMultiSelectedWaypoints();
  }, [
    draftRoute?.waypoints,
    updateUndoStack,
    selectedWaypointIDs,
    trackAnalyticsEvent,
    draftRouteUuid,
    reallyRemoveWaypoint,
    clearMultiSelectedWaypoints,
  ]);

  const closeRouteEditor = useCallback(
    (
      urlOptions?: WayfinderUrlOptions<"route-detail" | "route-explorer-detail">
    ) => {
      // restore the nautical charts visibility prior to edit
      clearStack();
      setSpeedUpdatedWaypointIDs([]);
      clearMultiSelectedWaypoints();
      if (draftRouteUuid) {
        setDraftRouteUuid(undefined);
      }
      if (isRouteExplorerEditorOpen) {
        setUrl("route-explorer-detail", urlOptions);
      } else if (isDefaultRouteEditorOpen) {
        setUrl("route-detail", urlOptions);
      }
    },
    [
      draftRouteUuid,
      isRouteExplorerEditorOpen,
      isDefaultRouteEditorOpen,
      clearStack,
      clearMultiSelectedWaypoints,
      setUrl,
    ]
  );

  const editRoute = useCallback(
    (
      route: Route,
      configuration?: RouteEditorConfiguration,
      routeMetadata?: Record<string, any>
    ) => {
      clearMapInspector();
      const { route: newRoute, routeUuid: newUuid } = cloneRouteForEditing({
        route,
      });
      createRoute(newUuid, newRoute, voyageUuid, {
        doNotPersist: true,
        isEdited: true,
      });
      setDraftRouteUuid(newUuid);
      if (routeMetadata) {
        setRouteMetadata(routeMetadata);
      }

      // update the configuration in the route store
      if (configuration) {
        updateEditorConfiguration(configuration);
      }
    },
    [clearMapInspector, createRoute, updateEditorConfiguration, voyageUuid]
  );

  // Whenever the draftRouteUuid changes, clear then reinialize the undo stack
  useEffect(() => {
    if (draftRoute) {
      clearStack();
      updateUndoStack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftRouteUuid]);

  const saveRoute = useCallback(async () => {
    const savedRouteResponse =
      voyageUuid && draftRoute
        ? await saveRouteToApi({
            voyageUuid,
            route: draftRoute,
            source: RouteSource.Edited,
          })
        : undefined;
    const savedRoute = savedRouteResponse?.data?.created[0];
    if (draftRoute && savedRoute) {
      if (updateRoute) {
        // route is no longer local only
        updateRoute(savedRoute.uuid, undefined, {
          doNotPersist: false,
          remoteExistence: "exists",
        });
      }
      setRoutesToCompare([...routeUuidsToCompare, savedRoute.uuid]);
      closeRouteEditor({
        params: {
          routeUuid: savedRoute.uuid,
        },
      });
      trackAnalyticsEvent(AnalyticsEvent.ImportRouteSuccess, {
        routeUUID: savedRoute.uuid,
        voyageUuid,
      });
    } else {
      setUrl("routes");
      closeRouteEditor();
    }
  }, [
    closeRouteEditor,
    draftRoute,
    saveRouteToApi,
    setRoutesToCompare,
    setUrl,
    trackAnalyticsEvent,
    updateRoute,
    voyageUuid,
    routeUuidsToCompare,
  ]);

  const onBackButton = useCallback(() => {
    if (isRouteEditorOpen) {
      closeRouteEditor();
      trackAnalyticsEvent(AnalyticsEvent.AbandonedRouteEditMode, {
        routeUuid: draftRouteUuid,
      });
    }
  }, [
    isRouteEditorOpen,
    closeRouteEditor,
    trackAnalyticsEvent,
    draftRouteUuid,
  ]);

  useEffect(() => {
    window.addEventListener("popstate", onBackButton);
    return () => window.removeEventListener("popstate", onBackButton);
  }, [onBackButton]);

  return useMemo(
    () => ({
      currentWaypoint,
      setCurrentWaypoint,
      isRouteEditorOpen,
      isRouteExplorerEditorOpen,
      closeRouteEditor,
      editRoute,
      endDrag,
      addWaypoint,
      updateWaypointGeometryType,
      moveWaypoint,
      removeWaypoint:
        (draftRoute?.waypoints.waypoints.length ?? 0) > 2
          ? removeWaypoint
          : undefined,
      draftRouteUuid,
      draftRoute,
      simulatedDraftRoute,
      simulatedDraftWaypointsById,
      draggableRoute,
      updateDraggableRouteWaypointPosition,
      saveRoute: undoStackSize > 1 ? saveRoute : undefined,
      undo: undoStackSize > 1 ? undo : undefined,
      revert: undoStackSize > 1 ? revert : undefined,
      routeMetadata,
      updateRouteMetadata,
      isMultiSelected,
      multiSelectWaypoint,
      removeMultiWaypoints,
      clearMultiSelectedWaypoints,
      showMultiDeleteWarning,
      showMultiDeleteWarningMessage,
      closeMultiDeleteWarningMessage,
      selectedWaypointIDs,
      enableScheduleEdits,
      updateWaypointSpeed,
      updateWaypointTimestamp,
      updateWaypointDrifting,
      showMultiEditModal,
      closeMultiEditModal,
      isMultiEditModalOpen,
      updateMultiWaypointsSpeed,
      speedUpdatedWaypointIDs,
      setModifierKeyIsActive,
    }),
    [
      currentWaypoint,
      isRouteEditorOpen,
      isRouteExplorerEditorOpen,
      closeRouteEditor,
      editRoute,
      endDrag,
      addWaypoint,
      updateWaypointGeometryType,
      moveWaypoint,
      draftRoute,
      removeWaypoint,
      draftRouteUuid,
      simulatedDraftRoute,
      simulatedDraftWaypointsById,
      draggableRoute,
      updateDraggableRouteWaypointPosition,
      undoStackSize,
      saveRoute,
      undo,
      revert,
      routeMetadata,
      updateRouteMetadata,
      isMultiSelected,
      multiSelectWaypoint,
      removeMultiWaypoints,
      clearMultiSelectedWaypoints,
      showMultiDeleteWarning,
      showMultiDeleteWarningMessage,
      closeMultiDeleteWarningMessage,
      enableScheduleEdits,
      selectedWaypointIDs,
      updateWaypointSpeed,
      updateWaypointTimestamp,
      updateWaypointDrifting,
      showMultiEditModal,
      closeMultiEditModal,
      isMultiEditModalOpen,
      updateMultiWaypointsSpeed,
      speedUpdatedWaypointIDs,
      setModifierKeyIsActive,
    ]
  );
};

export const RouteEditorContextProvider: React.FC<{}> = ({ children }) => {
  const state = useRouteEditorState();
  return (
    <RouteEditorContext.Provider value={state}>
      {children}
    </RouteEditorContext.Provider>
  );
};
