import config from "config";
import { CrystalGlobeApiContext } from "contexts/CrystalGlobeApiContext";
import { getVoyagePlanQueryKey } from "helpers/crystalGlobeApi";
import { useContext, useMemo } from "react";
import { useQuery } from "react-query";

export const useVoyagePlan = (voyagePlanUuid: string | null | undefined) => {
  const { VoyagePlansApi } = useContext(CrystalGlobeApiContext);

  const { data: voyagePlan, isLoading: voyagePlanIsLoading } = useQuery(
    getVoyagePlanQueryKey(voyagePlanUuid),
    async () => {
      try {
        if (voyagePlanUuid) {
          return await VoyagePlansApi.getVoyagePlan({ voyagePlanUuid });
        } else {
          return undefined;
        }
      } catch (e) {
        console.error("Could not fetch voyage plan");
        return undefined;
      }
    },
    {
      retry: 3,
      refetchInterval: config.routePollIntervalMs,
      enabled: Boolean(voyagePlanUuid),
    }
  );

  return useMemo(
    () => ({
      voyagePlan,
      voyagePlanIsLoading,
    }),
    [voyagePlan, voyagePlanIsLoading]
  );
};
