import { MultiLegVoyageDto } from "@sofarocean/wayfinder-typescript-client";
import { GM_Point } from "./RouteTypes";
import { SpotterWave, SpotterWind } from "./SpotterTypes";

export type Departure = GM_Point & {
  label: string | null;
  datetime: string;
};

export type Arrival = GM_Point & {
  label: string | null;
  targetDatetime: string | null;
};

export type MultiLegVoyage = MultiLegVoyageDto;

export type MapBoundCoordinates = [number, number, number, number];

export type MapBounds = {
  bounds: MapBoundCoordinates;
  options?: mapboxgl.FitBoundsOptions;
};

export type FrontAppTeammate = {
  id: string;
  email: string;
  userName: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  isAvailable: boolean;
  isBlocked: boolean;
  customFields: any;
};

// for presentation of spotter data on the map, because the raw spotters have
// functions and promises in them that are best normalized by the view model
export type SpotterMapData = {
  label: string;
  position: GM_Point;
  id: string;
  timestamp: string;
  wind?: SpotterWind; // m / s (some older spotters do not provide wind)
  waves: SpotterWave; // m
};

export enum QUERY_KEY_STRINGS {
  REMOTE_ROUTE = "remote-route",
  SEARCH_ROUTES = "RoutesApi.searchAllRoutes",
  SEARCH_CHARTWORLD = "ChartworldApi.searchChartWorld",
  SEA_TRIAL_ROUTE_SUGGESTIONS = "sea-trial-route-suggestions",
  ACTIVE_ROUTE = "ActiveRoutesApi.getActiveRoute",
  LATEST_ROUTE_SUGGESTION = "VoyagesApi.getLatestRouteSuggestionForVoyage",
  SPOTTER_REALTIME = "spotter-all-realtime",
  TROPICAL_STORMS = "StormGlassAPI.topicalStorms",
  VOYAGE = "VoyagesApi.getVoyage",
  VOYAGE_ALERTS = "VesselAlertingEventsApi.listVesselAlertsForRun",
  VOYAGES = "VoyagesApi.listVoyages",
  VOYAGE_UPDATES = "VoyagesApi.getVoyageUpdates",
  ALL_ORGANIZATION = "OrganizationsApi.getOrganizations",
  VESSEL = "VesselApi.getVessel",
  VESSELS = "VesselApi.getVessels",
  VESSEL_GROUPS = "VesselGroupsApi.listVesselGroups",
  VESSEL_UPDATES = "VesselApi.getVesselUpdates",
  VESSEL_RPM_GUIDANCE = "VesselApi.getVesselRpmGuidanceRequest",
  UPCOMING_POSITIONS = "VesselApi.getUpcommingPositions",
  MULTI_LEG_VOYAGE = "MultiLegVoyageApi.getMultiLegVoyage",
  MULTI_LEG_VOYAGES = "MultiLegVoyageApi.listMultiLegVoyages",
  AREA_CONSTRAINTS = "AreaConstraintsAPI.listAreaConstraintsV2",
  FRONT_TEAMMATES = "FrontApi.getTeammates",
  CURRENT_USER = "CurrentUserApi.getCurrentUserInfo",
  PORTS = "PortsApi.getPorts",
  PRESIGNED_URLS = "ReportingApi.getMlvPresignedUrls",
  FLEET_SUMMARY = "FleetSummaryApi.getFleetSummary",
  FLEET_SUMMARY_FOR_VESSEL = "FleetSummaryApi.getFleetSummaryForVessel",
  VESSEL_PERFORMANCE_MODEL_DATA = "FleetSummaryApi.getVesselPerformanceModelData",
  CHARTER_PARTY_END_VOYAGE_REPORT = "VoyagesApi.getCharterPartyEndVoyageReport",
  VESSEL_AIS_DATA_LIST = "AisDataApi.listVesselAisData",
  VESSEL_REPORT_DATA_LIST = "VesselReportDataApi.listVesselReportData",
  VPM_EVALUATION_DATA = "PolarisApi.vpmEvaluationData",
  BASE_CURVE_DATA = "BaseCurveVesselPerformanceModelDataApi.getBaseCurveVesselPerformanceModelDataForVessel",
  ICE_LAYER_DATA = "WeatherApi.getIceLayer",
  ROUTING_CONTROLS_TYPES = "VesselApi.getRoutingControlsTypes",
  NAVAREA_WARNING_DATA = "NavareaWarningsApi.getNavareaWarnings",
  MAINTENANCE_EVENT = "MaintenanceEventsApi.getMaintenanceEvent",
  VOYAGE_PLAN = "VoyagePlansApi.getVoyagePlan",
}
export type RemoteRouteQueryKeyVars = {
  routeUuid: string;
};

export type NoUndefinedField<T> = {
  [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>>;
};
