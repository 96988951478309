import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Route } from "shared-types/RouteTypes";
import { useWayfinderUrl } from "shared-hooks/use-wayfinder-url";
import { useSaveRoute } from "shared-hooks/use-route-save";
import { useActiveRoute } from "shared-hooks/use-active-route";
import UIContext from "contexts/UIContext";
import AnalyticsContext, { AnalyticsEvent } from "contexts/Analytics";
import {
  RouteValidationResult,
  validateRoute,
} from "helpers/importExport/route/validation";
import {
  CalculatedImportFormParameters,
  updateImportedRouteSchedule,
} from "components/sidebar/voyage-creation/helpers/updateImportedRouteSchedule";
import {
  CharterType,
  RouteSource,
  RoutingControlsType,
  UpdateVoyageDto,
} from "@sofarocean/wayfinder-typescript-client";
import { useUpdateVoyage } from "shared-hooks/use-update-voyage";
import { constructArrivalWindowDto } from "components/sidebar/voyage-creation/helpers/arrivalWindowHelpers";
import _, { isNull, isUndefined } from "lodash";
import { FormFieldChangeAnalyticsEvent } from "contexts/Analytics/use-analytics-state";
import { createSimpleFormChangeAnalyticsEventsList } from "contexts/Analytics/helpers";
import {
  useCurrentMultiLegVoyage,
  useCurrentRoutesToCompare,
} from "components/WayfinderApp/CurrentSession/contexts";
import { consoleAndSentryError } from "helpers/error-logging";
import {
  ArrivalWindowType,
  determineArrivalWindowTypeFromTimes,
} from "helpers/form-fields/use-arrival-time-menu-state";
import { useRoutingControlsTypes } from "contexts/MultilegVoyageEditContext/useRoutingControlsTypes";
import useVoyage from "shared-hooks/data-fetch-hooks/use-voyage";
import { match } from "ts-pattern";
import { getRoutingControlsTypeMapsByUuid } from "contexts/MultilegVoyageEditContext/useLegInEdit";
import { getRoutingControlsForType } from "components/sidebar/voyage-edit/helpers/transformLegToUpdateVoyageDto";
import {
  RouteStoreContext,
  RouteStoreDispatchContext,
} from "../../../contexts/RouteStoreContext/index";
import WayfinderSupportEmailLink from "../../../DLS/WayfinderEmailLink";
import useAppSetting from "../../../contexts/AppSettingsContext";
import { handleFilesImport } from "../../sidebar/voyage-creation/helpers/handleFilesImport";
import { ErrorContext } from "../../../contexts/ErrorContext";
import { useImportedRouteUuids } from "./use-imported-route-uuids";

export type RoutingControlsConstraintsUiState = {
  averageSpeedKts: number | null;
  rtaStartTime?: string;
  rtaEndTime?: string;
  maxDailyFuelRate: number | null;
};

export type RoutingControlsInstructionsUiState = {
  instructedSpeedKts: number | null;
  maxDailyFoRate: number | null;
  maxDailyDoGoRate: number | null;
};

export type RoutingControlsIntentionsUiState = {
  intendedSpeedKts: number | null;
  intendedRtaStartTime?: string;
  intendedRtaEndTime?: string;
};

export type RoutingControlsUiState = Partial<
  RoutingControlsConstraintsUiState &
    RoutingControlsInstructionsUiState &
    RoutingControlsIntentionsUiState & {
      charterType?: CharterType | null;
      routingControlsType?: RoutingControlsType | null;
      arrivalWindowType: ArrivalWindowType;
      intendedArrivalWindowType: ArrivalWindowType;
    }
>;

export type RouteImportFormValues = CalculatedImportFormParameters &
  RoutingControlsUiState & {
    voyageUuid?: string | undefined;
  };

// result must include eta and etd, but there may not be an rta or average speed
export type RouteImportFormResult = RouteImportFormValues &
  Required<Pick<RouteImportFormValues, "eta" | "etd">>;

export type ImportedRouteResult = {
  route?: Route;
  fileName?: string;
};

const getEtaFromRta = (
  rtaStartTime: string | undefined,
  rtaEndTime: string | undefined,
  arrivalWindowType: ArrivalWindowType | undefined // set the eta to a different side of the window depending on the type of window
) =>
  match(arrivalWindowType)
    .with("on", () => rtaStartTime)
    .with("after", () => rtaStartTime)
    .with("before", () => rtaEndTime)
    .with("between", () => rtaEndTime)
    .with("none", () => undefined)
    .with(undefined, () => undefined)
    .exhaustive();

export const getEtaFromRoutingControlsFormFields = (
  fields: RoutingControlsUiState,
  routingControlsType: RoutingControlsType | null | undefined
) =>
  match(routingControlsType)
    .with(RoutingControlsType.RoutingControlsIntentions, () => ({
      etaFromRta: getEtaFromRta(
        fields.intendedRtaStartTime,
        fields.intendedRtaEndTime,
        fields.intendedArrivalWindowType
      ),
      endTimeLocked: fields.intendedArrivalWindowType !== "none",
    }))
    .otherwise(() => ({
      etaFromRta: getEtaFromRta(
        fields.rtaStartTime,
        fields.rtaEndTime,
        fields.arrivalWindowType
      ),
      endTimeLocked: fields.arrivalWindowType !== "none",
    }));

export const useRouteImport = (voyageUuid: string | undefined) => {
  // State management related to imported routes

  const { trackAnalyticsEvent, trackFormChangeAnalyticsEvents } = useContext(
    AnalyticsContext
  );
  const { setUrl, setRoutesToCompare, vesselUuid } = useWayfinderUrl();
  const { routeUuidsToCompare } = useCurrentRoutesToCompare();

  // Use the uuid based data accessor hooks here so that importing can happen
  // on a voyage that is not the "current" voyage
  const { setRouteActive } = useActiveRoute(voyageUuid);
  const { saveRoute: saveRouteToCrystalGlobe } = useSaveRoute();
  const { setIsLoading } = useContext(UIContext);
  const { updateVoyage } = useUpdateVoyage();

  const { voyage, voyageIsLoading } = useVoyage(voyageUuid);
  const {
    routingControlsTypesByCharterType: rawRoutingControlsTypeMap,
    isLoading: isLoadingRawRoutingControlsTypes,
  } = useRoutingControlsTypes(vesselUuid);
  const routingControlsTypesByCharterType = useMemo(() => {
    const overrides = voyage
      ? getRoutingControlsTypeMapsByUuid([voyage])[voyage.uuid]
      : {};
    return { ...rawRoutingControlsTypeMap, ...overrides };
  }, [rawRoutingControlsTypeMap, voyage]);

  const isLoadingRoutingControlsTypes =
    isLoadingRawRoutingControlsTypes || voyageIsLoading;

  const importedRouteUuids = useImportedRouteUuids();
  const { routes: routesFromStore } = useContext(RouteStoreContext);
  const {
    createRoute: createRouteInLocalStore,
    updateRoute: updateRouteInLocalStore,
  } = useContext(RouteStoreDispatchContext);
  const { data: currentMultiLegVoyage } = useCurrentMultiLegVoyage();

  const previousVoyageLegArrivalTime: string | undefined = useMemo(() => {
    if (currentMultiLegVoyage) {
      const previousVoyageIndex =
        currentMultiLegVoyage.voyageLegs.findIndex(
          (voyage) => voyage.uuid === voyageUuid
        ) - 1;

      const { arrivalWindows, eta } =
        currentMultiLegVoyage.voyageLegs[previousVoyageIndex] ?? {};
      const { endTimestamp, startTimestamp } = arrivalWindows?.[0] ?? {};
      return endTimestamp ?? startTimestamp ?? eta ?? undefined;
    }
  }, [currentMultiLegVoyage, voyageUuid]);

  const saveImportedRouteToApi = useCallback(
    async (
      route: Route,
      rawRoutingControlsUiState?: RoutingControlsUiState
    ) => {
      const savedRouteResponse = voyageUuid
        ? await saveRouteToCrystalGlobe({
            route,
            voyageUuid,
            source: RouteSource.Imported,
          })
        : undefined;
      const savedRoute = savedRouteResponse?.data?.created[0];
      if (savedRoute) {
        setIsLoading(false);
        if (createRouteInLocalStore && voyageUuid) {
          const routingControlsUiState = {
            ...rawRoutingControlsUiState,
          };
          // better not persist these transient values
          delete routingControlsUiState.arrivalWindowType;
          delete routingControlsUiState.intendedArrivalWindowType;
          createRouteInLocalStore(savedRoute.uuid, route, voyageUuid, {
            routingControlsUiState,
            isImported: true,
          });
        }
        if (updateRouteInLocalStore) {
          // route is no longer local only
          updateRouteInLocalStore(savedRoute.uuid, undefined, {
            doNotPersist: false,
            remoteExistence: "exists",
          });
        }
        setRoutesToCompare([...routeUuidsToCompare, savedRoute.uuid]);
        trackAnalyticsEvent(AnalyticsEvent.ImportRouteSuccess, {
          routeUUID: savedRoute.uuid,
          voyageUuid,
        });
      } else {
        setUrl("routes");
      }
    },
    [
      saveRouteToCrystalGlobe,
      voyageUuid,
      setIsLoading,
      createRouteInLocalStore,
      updateRouteInLocalStore,
      setRoutesToCompare,
      routeUuidsToCompare,
      trackAnalyticsEvent,
      setUrl,
    ]
  );

  // delete doesn't delete the route from CrystalGlobe, it just removes it from the list
  const deleteImportedRoute = useCallback(
    (routeUuid: string) => {
      if (updateRouteInLocalStore) {
        updateRouteInLocalStore(routeUuid, undefined, { isImported: false });
      }
    },
    [updateRouteInLocalStore]
  );

  const followImportedRoute = useCallback(
    async (routeUuid: string) => {
      await setRouteActive({
        routeUuid,
      });
      // Copy routingControlsUiState from imported route if it exists
      const routingControlsUiState = Object.values(routesFromStore).find(
        (route) => route.uuid === routeUuid
      )?.data?.routingControlsUiState;
      if (routingControlsUiState) {
        const updateDto: UpdateVoyageDto = getRoutingControlsForType({
          ...routingControlsUiState,
          routingControlsType:
            routingControlsUiState.routingControlsType ?? null,
        });
        if (voyageUuid) {
          await updateVoyage({
            voyageUuid,
            voyage: updateDto,
          });
        }
      }

      if (updateRouteInLocalStore) {
        updateRouteInLocalStore(routeUuid, undefined, {
          isImported: false,
          // since we use the same function to follow imported or edited route,
          // we need to reset the isEdited to false here.
          isEdited: false,
        }); // is now accepted
      }
    },
    [
      setRouteActive,
      routesFromStore,
      updateRouteInLocalStore,
      updateVoyage,
      voyageUuid,
    ]
  );

  // State management related to the process of importing new routes

  const { setError } = useContext(ErrorContext);
  const [importError, setImportError] = useState(false);
  const resetImportError = useCallback(() => setImportError(false), []);

  const { value: enableFurunoSimple } = useAppSetting(
    "enableFurunoSimpleImport"
  );
  const { value: enableJsonRoutes } = useAppSetting("enableJsonRouteImport");

  const [importedRoute, setImportedRoute] = useState<Route | null>(null);
  const [initialFormValues, setInitialFormValues] = useState<
    RouteImportFormValues | undefined
  >();

  const [routeImportFormValues, setRouteImportFormValues] = useState<
    RouteImportFormValues | undefined
  >();

  const [isImporting, setIsImporting] = useState(false);
  const stopImporting = useCallback(() => {
    setIsImporting(false);
    setIsLoading(false);
    setImportedRoute(null);
    setRouteImportFormValues(undefined);
  }, [setIsLoading]);

  const [
    importedRouteValidationResult,
    setImportedRouteValidationResult,
  ] = useState<RouteValidationResult | null>(null);
  const [importedFileName, setImportedFileName] = useState<string | null>(null);

  const reportAnalyticsOnSubmit = useCallback(() => {
    if (!routeImportFormValues) {
      return;
    }

    const oldArrivalWindow = constructArrivalWindowDto(
      ...match(initialFormValues?.routingControlsType)
        .with(RoutingControlsType.RoutingControlsConstraints, () => [
          initialFormValues?.rtaStartTime,
          initialFormValues?.rtaEndTime,
        ])
        .with(RoutingControlsType.RoutingControlsIntentions, () => [
          initialFormValues?.intendedRtaStartTime,
          initialFormValues?.intendedRtaEndTime,
        ])
        .otherwise(() => [])
    );

    const newArrivalWindow = constructArrivalWindowDto(
      ...match(routeImportFormValues?.routingControlsType)
        .with(RoutingControlsType.RoutingControlsConstraints, () => [
          routeImportFormValues?.rtaStartTime,
          routeImportFormValues?.rtaEndTime,
        ])
        .with(RoutingControlsType.RoutingControlsIntentions, () => [
          routeImportFormValues?.intendedRtaStartTime,
          routeImportFormValues?.intendedRtaEndTime,
        ])
        .otherwise(() => [])
    );

    const analytics: FormFieldChangeAnalyticsEvent[] = createSimpleFormChangeAnalyticsEventsList(
      initialFormValues ?? {},
      routeImportFormValues,
      [
        {
          event: AnalyticsEvent.EditedAverageSpeedInRouteImportForm,
          key: "averageSpeedKts",
        },
        {
          event: AnalyticsEvent.EditedAverageSpeedInRouteImportForm,
          key: "instructedSpeedKts",
        },
        {
          event: AnalyticsEvent.EditedMaxDailyFuelInRouteImportForm,
          key: "maxDailyFuelRate",
        },
        {
          event: AnalyticsEvent.EditedMaxDailyFoInRouteImportForm,
          key: "maxDailyFoRate",
        },
        {
          event: AnalyticsEvent.EditedMaxDailyDoGoInRouteImportForm,
          key: "maxDailyDoGoRate",
        },
      ]
    );

    analytics.push({
      hasChange: !_.isEqual(newArrivalWindow, oldArrivalWindow),
      eventName: AnalyticsEvent.EditedRtaInRouteImportForm,
      data: { newArrivalWindow },
    });

    trackFormChangeAnalyticsEvents(analytics);
  }, [
    initialFormValues,
    routeImportFormValues,
    trackFormChangeAnalyticsEvents,
  ]);

  const initializeForm = useCallback(
    (initialValues?: Partial<RouteImportFormValues>) => {
      const normalizedInitialValues: RouteImportFormValues = {
        eta:
          match(initialValues?.routingControlsType)
            .with(
              RoutingControlsType.RoutingControlsConstraints,
              () => initialValues?.rtaEndTime ?? initialValues?.rtaStartTime
            )
            .with(
              RoutingControlsType.RoutingControlsIntentions,
              () =>
                initialValues?.intendedRtaEndTime ??
                initialValues?.intendedRtaStartTime
            )
            .otherwise(() => null) ?? initialValues?.eta,
        voyageUuid,
        ...(initialValues ?? {}),
      };
      normalizedInitialValues.arrivalWindowType = determineArrivalWindowTypeFromTimes(
        initialValues?.rtaStartTime,
        initialValues?.rtaEndTime
      );
      normalizedInitialValues.intendedArrivalWindowType = determineArrivalWindowTypeFromTimes(
        initialValues?.intendedRtaStartTime,
        initialValues?.intendedRtaEndTime
      );

      setRouteImportFormValues(normalizedInitialValues);
      setInitialFormValues(normalizedInitialValues);
    },
    [voyageUuid]
  );

  const importFilesToVoyage = useCallback(
    async (
      files: File[],
      initialRouteImportValues?: Partial<RouteImportFormValues>
    ): Promise<ImportedRouteResult> => {
      if (files.length === 0) {
        return {};
      }
      setIsImporting(true);
      let importedRoute;
      let importedFileName;
      await handleFilesImport(
        files[0],
        enableFurunoSimple,
        enableJsonRoutes,
        setIsLoading,
        setError,
        (
          route: Route,
          validationResult: RouteValidationResult,
          fileName?: string
        ) => {
          setIsLoading(false);
          setImportedFileName(fileName ?? null);
          setImportedRoute(route);
          setImportedRouteValidationResult(validationResult);
          initializeForm(initialRouteImportValues);
          importedRoute = route;
          importedFileName = fileName;
        },
        voyageUuid
      );

      return { route: importedRoute, fileName: importedFileName };
    },
    [
      enableFurunoSimple,
      enableJsonRoutes,
      setIsLoading,
      setError,
      voyageUuid,
      initializeForm,
    ]
  );

  useEffect(() => {
    if (
      isUndefined(initialFormValues) ||
      isUndefined(initialFormValues.etd) ||
      isNull(importedRoute) ||
      isNull(importedRouteValidationResult)
    ) {
      return;
    }
    const speedForCalculationKts =
      match(initialFormValues?.routingControlsType ?? null)
        .with(
          RoutingControlsType.RoutingControlsConstraints,
          () => initialFormValues.averageSpeedKts
        )
        .with(
          RoutingControlsType.RoutingControlsIntentions,
          () => initialFormValues.intendedSpeedKts
        )
        .with(
          RoutingControlsType.RoutingControlsInstructions,
          () => initialFormValues.instructedSpeedKts
        )
        .with(null, () => undefined)
        .exhaustive() ?? undefined;

    const routeScheduleChanges = updateImportedRouteSchedule({
      newDepartureTime: initialFormValues.etd,
      newArrivalTime: initialFormValues.eta,
      oldDepartureTime: undefined,
      oldArrivalTime: undefined,
      onImportError: onImportError,
      pendingImportRoute: importedRoute,
      routeValidationResult: importedRouteValidationResult,
      averageSpeedKts: speedForCalculationKts,
      endTimeLocked: Boolean(
        initialFormValues.rtaStartTime || initialFormValues.rtaEndTime
      ),
    });

    setImportedRouteValidationResult(
      validateRoute({ route: importedRoute, isRouteImported: true })
    );
    setRouteImportFormValues((prev) => {
      return {
        ...prev,
        ...routeScheduleChanges,
      };
    });
    // when the form is initialized, update the route based on values if we have to
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormValues]);

  const onImportError = useCallback(
    (error: Error) => {
      consoleAndSentryError(error);
      setError({
        error,
        description: `Cannot proceed with import. ${error.message}`,
      });
      setImportError(true);
      stopImporting();
    },
    [setError, stopImporting]
  );

  const fileTypes = useMemo(
    () => [
      Object.entries({
        RTZ_XML: ".rtz",
        JRC_CSV: ".csv",
        Tokimec_CSV: ".csv",
        TOTEM_CSV: ".csv",
        KML: ".kml",
        ...(enableJsonRoutes ? { JSON: ".json" } : undefined),
        ...(enableFurunoSimple ? { FURUNO_SIMPLE: ".txt" } : undefined),
      }),
    ],
    [enableFurunoSimple, enableJsonRoutes]
  );

  const validateFileExtension = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const parts = acceptedFiles[0].name.split(".");
        const extension = "." + parts[parts.length - 1].toLowerCase();
        if (fileTypes[0].map((e) => `${e[1]}`).indexOf(extension) === -1) {
          setError({
            error: Error("Invalid file format"),
            description: (
              <>
                Currently Wayfinder only supports route import for{" "}
                {enableFurunoSimple ? "CSV, RTZ, and TXT " : "CSV and RTZ "}
                file formats. Please import your route in one of the above
                formats, or email us at <WayfinderSupportEmailLink /> for
                support.
              </>
            ),
          });
          return false;
        }
      }
      return true;
    },
    [enableFurunoSimple, fileTypes, setError]
  );

  const onChangeRouteImportFormValues = useCallback(
    (changes: Partial<RouteImportFormValues> | undefined) => {
      setRouteImportFormValues((prev) => {
        const { charterType: newCharterType } = changes ?? {};
        const {
          eta: oldEta,
          etd: oldEtd,
          averageSpeedKts: oldAverageSpeedKts,
          instructedSpeedKts: oldInstructedSpeedKts,
          intendedSpeedKts: oldIntendedSpeedKts,
          charterType: oldCharterType,
        } = prev ?? {};

        const routingControlsCharterType = newCharterType ?? oldCharterType;
        const routingControlsType =
          routingControlsCharterType &&
          routingControlsTypesByCharterType?.[routingControlsCharterType];

        // merge the changes
        const mergedChanges = { ...prev, ...changes };

        // maintain synchronization between eta and rta window
        const {
          etaFromRta,
          endTimeLocked,
        } = getEtaFromRoutingControlsFormFields(
          mergedChanges,
          routingControlsType
        );

        if (endTimeLocked) {
          mergedChanges.eta = etaFromRta;
        }

        const {
          eta: updatedEta,
          etd: updatedEtd,
          averageSpeedKts: updatedAverageSpeedKts,
          instructedSpeedKts: updatedInstructedSpeedKts,
          intendedSpeedKts: updatedIntendedSpeedKts,
        } = mergedChanges ?? {};

        let routeScheduleChanges: ReturnType<
          typeof updateImportedRouteSchedule
        >;

        if (
          // recompute schedule if the related fields have changed
          importedRoute &&
          importedRouteValidationResult &&
          (updatedEta !== oldEta ||
            changes?.etd ||
            changes?.averageSpeedKts ||
            changes?.instructedSpeedKts ||
            changes?.intendedSpeedKts)
        ) {
          // any speed control can update the eta
          const speedForCalculationKts =
            match(routingControlsType ?? null)
              .with(
                RoutingControlsType.RoutingControlsConstraints,
                () => updatedAverageSpeedKts ?? oldAverageSpeedKts
              )
              .with(
                RoutingControlsType.RoutingControlsIntentions,
                () => updatedIntendedSpeedKts ?? oldIntendedSpeedKts
              )
              .with(
                RoutingControlsType.RoutingControlsInstructions,
                () => updatedInstructedSpeedKts ?? oldInstructedSpeedKts
              )
              .with(null, () => undefined)
              .exhaustive() ?? undefined;

          routeScheduleChanges = updateImportedRouteSchedule({
            newDepartureTime: updatedEtd,
            newArrivalTime: updatedEta,
            oldDepartureTime: oldEtd,
            oldArrivalTime: oldEta,
            onImportError: onImportError,
            pendingImportRoute: importedRoute,
            routeValidationResult: importedRouteValidationResult,
            averageSpeedKts: speedForCalculationKts,
            endTimeLocked,
          });
          setImportedRouteValidationResult(
            validateRoute({ route: importedRoute, isRouteImported: true })
          );
        }

        const result = {
          ...mergedChanges,
          ...routeScheduleChanges,
          // only update the routing controls type if the charter type has changed
          routingControlsType,
        };
        return result;
      });
    },
    [
      importedRoute,
      importedRouteValidationResult,
      onImportError,
      routingControlsTypesByCharterType,
    ]
  );

  return useMemo(
    () => ({
      importedRouteUuids,
      saveImportedRouteToApi,
      followImportedRoute,
      deleteImportedRoute,
      stopImporting,
      isImporting,
      resetImportError,
      importError,
      enableFurunoSimple,
      importedRoute,
      importedFileName,
      importFilesToVoyage,
      onImportError,
      fileTypes,
      validateFileExtension,
      importedRouteValidationResult,
      routeImportFormValues,
      onChangeRouteImportFormValues,
      previousVoyageLegArrivalTime,
      reportAnalyticsOnSubmit,
      isLoading: isLoadingRoutingControlsTypes,
    }),
    [
      importedRouteUuids,
      saveImportedRouteToApi,
      followImportedRoute,
      deleteImportedRoute,
      stopImporting,
      isImporting,
      resetImportError,
      importError,
      enableFurunoSimple,
      importedRoute,
      importedFileName,
      importFilesToVoyage,
      onImportError,
      fileTypes,
      validateFileExtension,
      importedRouteValidationResult,
      routeImportFormValues,
      onChangeRouteImportFormValues,
      previousVoyageLegArrivalTime,
      reportAnalyticsOnSubmit,
      isLoadingRoutingControlsTypes,
    ]
  );
};
