import { VoyageStatusV2 } from "@sofarocean/wayfinder-typescript-client";

export const isCompleted = (status: VoyageStatusV2 | undefined) =>
  status === VoyageStatusV2.Archived || status === VoyageStatusV2.Arrived;

export const isPlanning = (status: VoyageStatusV2 | undefined) =>
  status === VoyageStatusV2.PendingData ||
  status === VoyageStatusV2.StandbyForArrival ||
  status === VoyageStatusV2.StandbyForDeparture;

export const isUnderway = (status: VoyageStatusV2 | undefined) =>
  status === VoyageStatusV2.RoutingUnderway ||
  status === VoyageStatusV2.StandbyUnderway;
