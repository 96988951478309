import { cloneDeep, isNil } from "lodash";
import { v4 as uuid } from "uuid";

import {
  CreateOrUpdateRouteRequestDto,
  CreateRouteDto,
  CreateVoyageDto,
  GeoJsonPointDtoFromJSON,
  PointEnum,
  RouteSource,
  RoutingControlsType,
  RtzJsonDto,
  VoyageDto,
} from "@sofarocean/wayfinder-typescript-client";

import { LegInEditContext } from "contexts/MultilegVoyageEditContext/useLegInEdit";
import { knotsToMetersPerSecond } from "helpers/units";
import { DateTime } from "luxon";
import { LoadConditionEnum } from "shared-types/LoadConditionTypes";
import { portToGeoJsonPointDto } from "shared-types/PortTypes";
import { Route } from "shared-types/RouteTypes";
import { match } from "ts-pattern";
import { constructArrivalWindowDto } from "./arrivalWindowHelpers";

export const transformLegToCreateVoyageDto = (
  leg: LegInEditContext,
  primaryVoyage: VoyageDto | undefined,
  vesselUuid: string,
  getRouteForLeg: (legUuid: string) => Route | undefined
): CreateVoyageDto => {
  const {
    voyageUuid: legUuid,
    rtaStartTime,
    rtaEndTime,
    departurePort,
    arrivalPort,
    overrideDeparturePortEuEtsStatus,
    overrideDestinationPortEuEtsStatus,
    etd,
    eta,
    loadCondition,
    charterType,
    averageSpeedKts,
    instructedSpeedKts,
    maxDailyFuelRate,
    maxDailyFoRate,
    maxDailyDoGoRate,
    intendedSpeedKts,
    intendedRtaStartTime,
    intendedRtaEndTime,
    routingControlsType,
  } = leg;
  const name = createVoyageName(leg);

  const arrivalWindowDto = constructArrivalWindowDto(rtaStartTime, rtaEndTime);
  const intendedArrivalWindowDto = constructArrivalWindowDto(
    intendedRtaStartTime,
    intendedRtaEndTime
  );

  const { defaultRoute, departurePoint, destinationPoint } = getRouteAndPoints(
    leg,
    getRouteForLeg
  );

  const routeRequest = getRouteRequest(leg);

  const routingControls = match(routingControlsType ?? null)
    .with(RoutingControlsType.RoutingControlsConstraints, () => ({
      averageSpeed:
        !arrivalWindowDto.endTimestamp &&
        !arrivalWindowDto.startTimestamp &&
        averageSpeedKts
          ? knotsToMetersPerSecond(averageSpeedKts)
          : null,
      arrivalWindows: [arrivalWindowDto],
      maxDailyFuelRate: maxDailyFuelRate,
    }))
    .with(RoutingControlsType.RoutingControlsIntentions, () => ({
      intendedSpeedMps:
        !intendedArrivalWindowDto.endTimestamp &&
        !intendedArrivalWindowDto.startTimestamp &&
        intendedSpeedKts
          ? knotsToMetersPerSecond(intendedSpeedKts)
          : null,
      intendedArrivalWindows: [intendedArrivalWindowDto],
    }))
    .with(RoutingControlsType.RoutingControlsInstructions, () => ({
      instructedSpeed: instructedSpeedKts
        ? knotsToMetersPerSecond(instructedSpeedKts)
        : null,
      maxDailyFoRate: maxDailyFoRate,
      maxDailyDoGoRate: maxDailyDoGoRate,
    }))
    .with(null, () => ({}))
    .exhaustive();

  const legDto: CreateVoyageDto = {
    uuid: legUuid,
    vesselUuid,
    routeCostOperationalCostsDollars:
      primaryVoyage?.routeCostOperationalCostsDollars,
    routeCostFuelCostsDollars: primaryVoyage?.routeCostFuelCostsDollars,
    routeCostBoundaryCost: primaryVoyage?.routeCostBoundaryCost,
    routeCostEcaFuelCostsDollars: primaryVoyage?.routeCostEcaFuelCostsDollars,
    routeCostEuAllowanceCostsDollars:
      primaryVoyage?.routeCostEuAllowanceCostsDollars,
    voyageTimeBaseUpdateAtHoursLocal:
      primaryVoyage?.voyageTimeBaseUpdateAtHoursLocal,
    voyageTimeBaseReportAtHoursUtc:
      primaryVoyage?.voyageTimeBaseReportAtHoursUtc,
    voyageTimeBaseMinimumGapHours: primaryVoyage?.voyageTimeBaseMinimumGapHours,
    voyageTimeBaseIntervalHours: primaryVoyage?.voyageTimeBaseIntervalHours,
    voyageTimeBaseRealTime: primaryVoyage?.voyageTimeBaseRealTime,
    weatherProfileTaperTime: primaryVoyage?.weatherProfileTaperTime,
    weatherProfileMaxLeadTime: primaryVoyage?.weatherProfileMaxLeadTime,
    weatherProfileWeatherType: primaryVoyage?.weatherProfileWeatherType,
    weatherConfigFileS3Path: primaryVoyage?.weatherConfigFileS3Path,
    modelConfigFileS3Path: primaryVoyage?.modelConfigFileS3Path,
    etd: etd && etd.length ? etd : null,
    eta: eta && eta.length ? eta : null,
    name,
    defaultRoute,
    routeRequest,
    isDesignatedLaden: loadCondition === LoadConditionEnum.laden,
    charterType: charterType || undefined,
    departurePoint,
    destinationPoint,
    overrideDeparturePortEuEtsStatus,
    overrideDestinationPortEuEtsStatus,
    ...routingControls,
  };
  // if we have a uuid for the port, use that, otherwise only populate the
  // name field
  if (departurePort?.uuid) legDto.departurePortUuid = departurePort.uuid;
  else legDto.departurePortName = departurePort?.displayName;
  if (arrivalPort?.uuid) legDto.destinationPortUuid = arrivalPort.uuid;
  else legDto.destinationPortName = arrivalPort?.displayName;

  return legDto;
};

export const getRouteAndPoints = (
  leg: LegInEditContext,
  getRouteForLeg: (legUuid: string) => Route | undefined,
  isPlanning: boolean = true
) => {
  const { voyageUuid, departurePort, arrivalPort, shouldRequestRoute } = leg;
  let defaultRoute: CreateRouteDto | undefined = undefined;
  let departurePoint = isPlanning
    ? portToGeoJsonPointDto(departurePort)
    : undefined;
  let destinationPoint = portToGeoJsonPointDto(arrivalPort);

  if (shouldRequestRoute) {
    return { defaultRoute, departurePoint, destinationPoint };
  }

  const route = getRouteForLeg(voyageUuid);
  if (isNil(route)) {
    return { defaultRoute, departurePoint, destinationPoint };
  }

  const apiRoute = cloneDeep(route) as RtzJsonDto;
  defaultRoute = {
    uuid: route.extensions?.uuid ?? uuid(),
    voyageUuid: voyageUuid,
    source: RouteSource.Imported,
    rtzRouteObject: apiRoute,
    canCreateChannels: false,
  };
  if (isPlanning) {
    const { waypoints } = route.waypoints;
    const firstWaypoint = waypoints[0]?.position;
    if (!isNil(firstWaypoint)) {
      departurePoint = GeoJsonPointDtoFromJSON({
        coordinates: [firstWaypoint.lon, firstWaypoint.lat],
        type: PointEnum.Point,
      });
    }
    const lastWaypoint = waypoints[waypoints.length - 1]?.position;
    if (!isNil(lastWaypoint)) {
      destinationPoint = GeoJsonPointDtoFromJSON({
        coordinates: [lastWaypoint.lon, lastWaypoint.lat],
        type: PointEnum.Point,
      });
    }
  }

  return { defaultRoute, departurePoint, destinationPoint };
};

export const getRouteRequest = (
  leg: LegInEditContext,
  routeRequestUuid?: string | null
): CreateOrUpdateRouteRequestDto | undefined => {
  const { voyageUuid, departurePort, arrivalPort, shouldRequestRoute } = leg;
  const departurePortUnlocode = departurePort?.unlocode;
  const arrivalPortUnlocode = arrivalPort?.unlocode;
  return shouldRequestRoute &&
    !isNil(departurePortUnlocode) &&
    !isNil(arrivalPortUnlocode)
    ? {
        uuid: routeRequestUuid ?? uuid(),
        voyageUuid,
        departurePortUnlocode,
        arrivalPortUnlocode,
      }
    : undefined;
};

export const createVoyageName = (leg: LegInEditContext) => {
  const { etd, departurePort, loadCondition } = leg;
  const loadLetter = loadCondition === "ballast" ? "B" : "L";
  let dateForName = "";
  if (etd) dateForName = `-${DateTime.fromISO(etd).toFormat("MM-yyyy")}-`;
  return `${departurePort?.displayName}${dateForName}${loadLetter}`;
};
