import { captureException } from "@sentry/react";
import { RouteExportFormat } from "components/modals/RouteExportForm";
import { getStringFromRoute } from "components/routes/Export/get-string-from-route";
import { startFileDownload } from "helpers/FileDownload/start-file-download";
import JSZip from "jszip";
import { Route } from "shared-types/RouteTypes";

export const ROUTE_ZIP_FILE_DIRECTORY_NAME = "Sofar_Wayfinder_Exported_Routes";

export enum EXPORT_FORMAT_EXTENSIONS {
  RTZ_XML = ".rtz",
  JRC_CSV = ".csv",
  Tokimec_CSV = ".csv",
  Furuno_Simple = ".txt",
  Totem_CSV = ".csv",
  Nacos1100_CSV = ".csv",
}

export type ExportedFile = {
  name: string;
  content: string;
  format: RouteExportFormat;
};

type ExportRouteParams = {
  routes: Route[];
  formats: RouteExportFormat[];
  getRoutename?: (route: Route) => string;
  options?: {
    shouldDownload?: boolean;
    includeCalculatedScheduleInRtzExports?: boolean;
    enableLegacyRtzXmlFormatting?: boolean;
  };
};

export const exportRoutes = ({
  routes,
  formats,
  getRoutename,
  options: {
    shouldDownload = true,
    includeCalculatedScheduleInRtzExports = false,
    enableLegacyRtzXmlFormatting = false,
  } = {},
}: ExportRouteParams): {
  files: ExportedFile[];
  errors: string | undefined;
} => {
  const routeSuffix: Record<string, number> = {}; // save a suffix for each duplicate route name
  // export routes here
  try {
    const files: { content: string; name: string }[] = routes.flatMap(
      (route: Route) =>
        formats.map((format) => {
          /*
          Forbidden characters https://stackoverflow.com/a/31976060

          Mac:
          : (colon)

          Linux:
          / (forward slash)

          Windows:
          < (less than)
          > (greater than)
          : (colon - sometimes works, but is actually NTFS Alternate Data Streams)
          " (double quote)
          / (forward slash)
          \ (backslash)
          | (vertical bar or pipe)
          ? (question mark)
          * (asterisk)

          */

          const routeName = getRoutename?.(route) || route.routeInfo.routeName;
          let name = `${routeName.replace(/[\s:/<>"\\|?*,]+/g, "_")}-${format}${
            EXPORT_FORMAT_EXTENSIONS[format]
          }`;
          if (routeSuffix[name]) {
            // if there is already a file in the zip that has the same name, add a suffix to the name
            name = name.replace(/(\.[a-z,0-9]+)$/i, `-${routeSuffix[name]}$1`);
            routeSuffix[name]++;
          } else {
            routeSuffix[name] = 1;
          }
          return {
            name,
            content: getStringFromRoute({
              route,
              format,
              options: {
                includeCalculatedScheduleInRtzExports,
                enableLegacyRtzXmlFormatting,
              },
            }),
          };
        })
    );
    if (shouldDownload) {
      const zip = new JSZip();
      const routesFolder = zip.folder(ROUTE_ZIP_FILE_DIRECTORY_NAME);
      files.forEach((f) => routesFolder?.file(f.name, f.content));

      zip.generateAsync({ type: "blob" }).then(function (content: any) {
        startFileDownload(
          () => content,
          `${ROUTE_ZIP_FILE_DIRECTORY_NAME}.zip`
        );
      });
    }

    return {
      files: files.map((f, index) => ({
        ...f,
        format: formats[index],
      })),
      errors: undefined,
    };
  } catch (e: any) {
    console.warn(e);
    captureException(e, {
      contexts: {
        exportParameters: {
          routeUuids: routes.map((r) => r.extensions?.uuid),
          formats,
        },
      },
      fingerprint: [(e as Error).name, "Export Route"],
    });
    return {
      files: [],
      errors:
        "There was an error exporting one or more of the selected routes or formats",
    };
  }
};
