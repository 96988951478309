import config from "config";

export const retryWayfinderAPIRequestOn500Error = (
  failureCount: number,
  error: Error & { response?: { status?: number } }
) => {
  return (
    failureCount < config.mutationRetries && error?.response?.status === 500
  ); // Retry only for 500 errors. This will be a WayfinderApiError
};
