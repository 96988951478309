import { useEffect } from "react";
import { ROUTE_EXPLORER_ROUTE_COMPARISON_PATH } from "shared-hooks/use-wayfinder-url";
import { useCurrentUrl } from "helpers/navigation";
import { useVoyagePlan } from "shared-hooks/use-voyage-plan";

export function useVoyagePlanRedirect() {
  const currentUrl = useCurrentUrl();
  const { voyagePlan } = useVoyagePlan(currentUrl.getVoyagePlanUuidFromPath());
  useEffect(() => {
    if (voyagePlan) {
      currentUrl
        .withPath(ROUTE_EXPLORER_ROUTE_COMPARISON_PATH)
        .withVesselUuid(voyagePlan.vesselUuid)
        .withVoyageUuid(voyagePlan.voyageUuid)
        .withRoutesToCompare(voyagePlan.routesToCompare)
        .withSimulatedHistoricalTime(voyagePlan.forecastInitTime)
        .withVoyagePlanUuidQueryParam(voyagePlan.uuid)
        .redirect();
    }
  }, [currentUrl, voyagePlan]);
}
