import { isNil } from "lodash";

import {
  UpdateMultiLegVoyageLegDto,
  VoyageDto,
  CreateVoyageDto,
  RoutingControlsType,
  UpdateVoyageDto,
} from "@sofarocean/wayfinder-typescript-client";

import { Route } from "shared-types/RouteTypes";
import {
  areLegsEqual,
  LegInEditContext,
} from "contexts/MultilegVoyageEditContext/useLegInEdit";
import { knotsToMetersPerSecond } from "helpers/units";
import { isPlanning } from "helpers/voyage-status";
import { LoadConditionEnum } from "shared-types/LoadConditionTypes";
import { match } from "ts-pattern";
import { getRouteAndPoints } from "../../voyage-creation/helpers/transformLegToCreateVoyageDto";
import { createVoyageName } from "../../voyage-creation/helpers/transformLegToCreateVoyageDto";
import { constructArrivalWindowDto } from "../../voyage-creation/helpers/arrivalWindowHelpers";
import {
  transformLegToCreateVoyageDto,
  getRouteRequest,
} from "../../voyage-creation/helpers/transformLegToCreateVoyageDto";

const transformLegToUpdateDto = (
  leg: LegInEditContext,
  getRouteForLeg: (legUuid: string) => Route | undefined,
  routeRequestUuid?: string | null
): UpdateMultiLegVoyageLegDto => {
  const {
    eta,
    etd,
    voyageUuid,
    loadCondition,
    departurePort,
    arrivalPort,
    overrideDeparturePortEuEtsStatus,
    overrideDestinationPortEuEtsStatus,
    status,
    charterType,
  } = leg;
  const name = createVoyageName(leg);
  const { defaultRoute, departurePoint, destinationPoint } = getRouteAndPoints(
    leg,
    getRouteForLeg,
    isPlanning(status)
  );

  const routeRequest = getRouteRequest(leg, routeRequestUuid);

  const voyage: UpdateMultiLegVoyageLegDto = {
    uuid: voyageUuid,
    etd,
    eta,
    name,
    isDesignatedLaden: loadCondition === LoadConditionEnum.laden,
    departurePoint,
    destinationPoint,
    overrideDeparturePortEuEtsStatus,
    overrideDestinationPortEuEtsStatus,
    defaultRoute,
    routeRequest,
    charterType: charterType || undefined,
    ...getRoutingControlsForType(leg),
  };
  // if we have a uuid for the port, use that, otherwise only populate the
  // name field
  if (departurePort?.uuid) voyage.departurePortUuid = departurePort.uuid;
  else voyage.departurePortName = departurePort?.displayName;
  if (arrivalPort?.uuid) voyage.destinationPortUuid = arrivalPort.uuid;
  else voyage.destinationPortName = arrivalPort?.displayName;

  return voyage;
};

export const transformLegToUpdateVoyageDto = (
  leg: LegInEditContext,
  preExistingLeg: LegInEditContext | undefined,
  primaryVoyage: VoyageDto | undefined,
  vesselUuid: string,
  getRouteForLeg: (legUuid: string) => Route | undefined,
  routeRequestUuid?: string | null
): CreateVoyageDto | UpdateMultiLegVoyageLegDto | string => {
  if (isNil(preExistingLeg)) {
    return transformLegToCreateVoyageDto(
      leg,
      primaryVoyage,
      vesselUuid,
      getRouteForLeg
    );
  }

  return areLegsEqual(leg, preExistingLeg)
    ? leg.voyageUuid
    : transformLegToUpdateDto(leg, getRouteForLeg, routeRequestUuid);
};

/**
 * Given a set of voyage properties and a rotuing control type
 * return the UpdateVoyageDto properties that match the type
 */
export function getRoutingControlsForType({
  routingControlsType,
  rtaStartTime,
  rtaEndTime,
  intendedRtaStartTime,
  intendedRtaEndTime,
  averageSpeedKts,
  maxDailyFuelRate,
  intendedSpeedKts,
  instructedSpeedKts,
  maxDailyFoRate,
  maxDailyDoGoRate,
}: {
  routingControlsType: RoutingControlsType | null;
  rtaStartTime?: string | undefined;
  rtaEndTime?: string | undefined;
  intendedRtaStartTime?: string | undefined;
  intendedRtaEndTime?: string | undefined;
  averageSpeedKts?: number | null | undefined;
  maxDailyFuelRate?: number | null | undefined;
  intendedSpeedKts?: number | null | undefined;
  instructedSpeedKts?: number | null | undefined;
  maxDailyFoRate?: number | null | undefined;
  maxDailyDoGoRate?: number | null | undefined;
}): Partial<UpdateVoyageDto> {
  const arrivalWindowDto = constructArrivalWindowDto(rtaStartTime, rtaEndTime);
  const intendedArrivalWindowDto = constructArrivalWindowDto(
    intendedRtaStartTime,
    intendedRtaEndTime
  );

  return match(routingControlsType ?? null)
    .with(RoutingControlsType.RoutingControlsConstraints, () => ({
      averageSpeed:
        !arrivalWindowDto.endTimestamp &&
        !arrivalWindowDto.startTimestamp &&
        averageSpeedKts
          ? knotsToMetersPerSecond(averageSpeedKts)
          : null,
      arrivalWindows: [arrivalWindowDto],
      maxDailyFuelRate: maxDailyFuelRate,
    }))
    .with(RoutingControlsType.RoutingControlsIntentions, () => ({
      intendedSpeedMps:
        !intendedArrivalWindowDto.endTimestamp &&
        !intendedArrivalWindowDto.startTimestamp &&
        intendedSpeedKts
          ? knotsToMetersPerSecond(intendedSpeedKts)
          : null,
      intendedArrivalWindows: [intendedArrivalWindowDto],
    }))
    .with(RoutingControlsType.RoutingControlsInstructions, () => ({
      instructedSpeed: instructedSpeedKts
        ? knotsToMetersPerSecond(instructedSpeedKts)
        : null,
      maxDailyFoRate: maxDailyFoRate,
      maxDailyDoGoRate: maxDailyDoGoRate,
    }))
    .with(null, () => ({}))
    .exhaustive();
}
